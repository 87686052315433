import { isEqual, sortBy, uniqBy } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD,
  COURSE_CARD_DESTINATION_TYPE_PROGRAM
} from 'pages/CoursesPage/CourseCard'
import {
  COURSE_FILTER_LIVE,
  COURSE_FILTER_ON_DEMAND
} from 'pages/CoursesPage/CoursesFilters'
import CustomPageTitle from 'pages/CustomPageTitle'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import Loading from 'components/Loading'
import { usePage } from 'components/PageHeader/usePage'
import PremiumBadge from 'components/PremiumBadge'
import Tabs from 'components/Tabs'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import LegacyCourseCard from 'components/cards/Content/LegacyCourseCard'
import DropdownMultiSelect from 'components/dropdowns/DropdownMultiSelect/DropdownMultiSelect'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import {
  COURSES_LIVE_PATH,
  COURSES_MY_COURSES_PATH,
  COURSES_ON_DEMAND_PATH
} from 'constants/courses'

import {
  useCoursesListFiltersQuery,
  useCoursesListQuery,
  useCoursesListUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  trackFilterApplied,
  trackNavigationClicked,
  trackSortSelected
} from 'utils/tracking/analytics'

import {
  COURSE_SORT_TYPE_ALPHA,
  COURSE_SORT_TYPE_DURATION,
  COURSE_SORT_TYPE_START_DATE,
  sortCourses
} from './CoursesListSort'
import MyCoursesTab from './MyCoursesTab'

const INITIAL_DISPLAYED_COURSES = 5
const COURSES_TAB_MY_COURSES = 'my-courses'

type CombinedFiltersType = {
  availability: string[]
  topics: string[]
}

type FilterType = 'topics' | 'availability'

const combinedFilterCount = (combinedFilters: CombinedFiltersType) =>
  Object.keys(combinedFilters).reduce(
    (count, filterType: FilterType) => count + combinedFilters[filterType].length,
    0
  )

interface CoursesProps {
  activeTab: string
  filters: CombinedFiltersType
  sort: string
  pageLocation: string
  filterTypeJustApplied?: FilterType | null
  setFilterTypeJustApplied: Dispatch<SetStateAction<null | FilterType>>
  clearFiltersForType: (filterType: FilterType) => void
}

const Courses = ({
  activeTab,
  filters,
  pageLocation,
  sort,
  filterTypeJustApplied,
  setFilterTypeJustApplied,
  clearFiltersForType
}: CoursesProps) => {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const [displayedCourses, setDisplayedCourses] = useState(INITIAL_DISPLAYED_COURSES)

  const [currentTopics, setCurrentTopics] = useState(filters.topics)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const { data, loading, error } = useCoursesListQuery({
    variables: {
      topicSlugs: filters.topics,
      availability: filters.availability
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      if (isEqual(filters.topics, currentTopics)) {
        return
      }
      if (currentTab !== activeTab) {
        // If the current tab is not the active tab, the filters will get reset (no-op)
        setCurrentTab(activeTab)
        return
      }
      setCurrentTopics(filters.topics)
      // tracking filterApplied here so can track the fetched result counts with this track
      if (filterTypeJustApplied && data) {
        trackFilterApplied({
          filter_location: pageLocation,
          filter_changed: filterTypeJustApplied,
          filters: filters,
          active_filter_count: combinedFilterCount(filters),
          results_count: data?.cclCourses?.length || 0
        })

        setFilterTypeJustApplied(null)
      }
    }
  })

  const {
    data: userData,
    loading: bookmarksLoading,
    error: bookmarksError
  } = useCoursesListUserQuery({ skip: !isLoggedIn })

  if (bookmarksError || error) {
    return <ErrorMessage error={error} />
  }

  if (bookmarksLoading || loading || !data?.cclCourses) {
    return <Loading />
  }

  const cclCourses = data.cclCourses

  const courseBookmarks = userData?.currentUser?.courseBookmarks || []
  const programBookmarks = userData?.currentUser?.programBookmarks || []

  const sortedCourses = sortCourses({
    selectedSortType: sort,
    courses: cclCourses
  })

  const additionalRelatedIdentifiers = {
    is_filtered_reference: combinedFilterCount(filters) > 0,
    is_empty_index_results: false
  }

  const clearFilters = () => {
    clearFiltersForType('availability')
    clearFiltersForType('topics')
  }

  const handleExploreAllClick = () => {
    clearFilters()
  }

  if (sortedCourses.length === 0) {
    return (
      <div className="flex flex-col w-full h-[400px] pt-[100px] items-center bg-rb-gray-50 mt-10 rounded">
        <RfHeader2>We don&apos;t have that... yet</RfHeader2>
        <RfHeader3 className="text-rb-gray-300">
          But we are frequently adding new courses, so stay tuned
        </RfHeader3>
        <Button
          variant="fill"
          color="teal"
          size="large"
          onClick={handleExploreAllClick}
          data-canny-link
        >
          Explore all courses
        </Button>
      </div>
    )
  }

  const onShowMoreClick = () => {
    setDisplayedCourses(sortedCourses.length)
  }

  const sliceLength = isLoggedIn ? sortedCourses.length : displayedCourses

  const destinationType =
    activeTab === COURSE_FILTER_ON_DEMAND && currentUser?.is.paidMember
      ? COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
      : COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS

  return (
    <>
      <div className="mt-6 flex flex-col space-y-4">
        {sortedCourses.slice(0, sliceLength).map((sortedCourse) => {
          if (sortedCourse.__typename === 'MarketingCourse') {
            const bookmark = programBookmarks.find(
              (programBookmark) =>
                programBookmark.cmsProgram?.slug &&
                programBookmark.cmsProgram?.slug === sortedCourse.cmsProgramSlug
            )

            return (
              <LegacyCourseCard
                pageLocation={pageLocation}
                locationType="index"
                cardType="on-demand"
                variant={CardVariants.Horizontal}
                course={sortedCourse}
                user={currentUser}
                additionalRelatedIdentifiers={additionalRelatedIdentifiers}
                key={sortedCourse.id}
                destinationType={COURSE_CARD_DESTINATION_TYPE_PROGRAM}
                hideBookmarkButton={!isLoggedIn}
                bookmark={bookmark}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              />
            )
          } else {
            const bookmark = courseBookmarks.find(
              (courseBookmark) => courseBookmark.cclCourse?.id === sortedCourse.id
            )

            return (
              <CourseCard
                destinationType={destinationType}
                pageLocation={pageLocation}
                locationType="index"
                additionalRelatedIdentifiers={additionalRelatedIdentifiers}
                variant={CardVariants.Horizontal}
                courseSession={sortedCourse.upcomingSessions?.[0]}
                course={sortedCourse}
                key={sortedCourse.id}
                hideBookmarkButton={!isLoggedIn}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                bookmark={bookmark}
              />
            )
          }
        })}

        {!isLoggedIn && displayedCourses < sortedCourses.length && (
          <Button
            variant="outline"
            onClick={onShowMoreClick}
            className="self-center"
            size="small"
          >
            Show all courses
          </Button>
        )}
      </div>
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={userData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!userData?.currentUser?.hasSeenCollectionsPrompt}
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction="bookmark"
      />
    </>
  )
}

function getValidSelectedTopics(
  allCourseTopics: Array<{ slug: string }>,
  selectedTopics: Array<string>
) {
  const validFilterSlugs = allCourseTopics.map((topic) => topic.slug)
  return selectedTopics.filter((slug) => validFilterSlugs.includes(slug))
}

function getAllTopicsFromCoursesListFiltersQueryResult(
  data: ReturnType<typeof useCoursesListFiltersQuery>['data']
) {
  const cclCourseCclTopics = data?.cclCourseCclTopics || []

  const allCourseTopics = sortBy(uniqBy([...cclCourseCclTopics], 'id'), 'title')

  return allCourseTopics
}

export const CoursesList = () => {
  const history = useHistory()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { setPageTitle } = usePage()

  const location = useLocation()
  const locationSearchString = location.search

  const searchParams = useMemo(
    () => new URLSearchParams(locationSearchString),
    [locationSearchString]
  )

  const selectedFilters = useMemo(() => {
    return {
      availability:
        searchParams.get('tab') === 'my-courses' ? null : searchParams.get('tab'),
      topics: searchParams.get('topics')?.split(',').filter(Boolean) || []
    }
  }, [searchParams])

  const tabSearchParam = searchParams.get('tab')

  const initialSort =
    selectedFilters.availability === COURSE_FILTER_ON_DEMAND
      ? COURSE_SORT_TYPE_ALPHA
      : COURSE_SORT_TYPE_START_DATE

  const [selectedSortType, setSelectedSortType] = useState(initialSort)

  const [filterTypeJustApplied, setFilterTypeJustApplied] = useState<null | FilterType>(
    null
  )

  useEffect(() => {
    if (!isLoggedIn || currentUser?.is.freeUser) {
      setPageTitle(
        <CustomPageTitle title="Courses">
          <PremiumBadge />
        </CustomPageTitle>
      )
    }

    return () => {
      setPageTitle(null)
    }
  }, [currentUser, setPageTitle, isLoggedIn])

  function setTopicsQueryParam(topics: string[]) {
    const topicSlugs = topics.join(',')
    if (topicSlugs.length === 0) {
      searchParams.delete('topics')
    } else {
      searchParams.set('topics', topicSlugs)
    }

    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString()
    })

    setFilterTypeJustApplied('topics')
  }

  const { data, loading, error } = useCoursesListFiltersQuery({
    onCompleted: (data) => {
      const allTopicsFilters = getAllTopicsFromCoursesListFiltersQueryResult(data)
      const validSelectedTopics = getValidSelectedTopics(
        allTopicsFilters,
        selectedFilters.topics
      )

      setTopicsQueryParam(validSelectedTopics)
    }
  })

  const allCourseTopics = getAllTopicsFromCoursesListFiltersQueryResult(data)

  useEffect(() => {
    const tabSearchParam = searchParams.get('tab')
    const availabilitySearchParam = searchParams.get('availability')

    if (availabilitySearchParam) {
      searchParams.delete('availability')
      searchParams.set('tab', availabilitySearchParam)

      history.replace({
        pathname: window.location.pathname,
        search: searchParams.toString()
      })
      return
    }

    if (
      !tabSearchParam ||
      (!isLoggedIn &&
        ![COURSE_FILTER_ON_DEMAND, COURSE_FILTER_LIVE].includes(tabSearchParam))
    ) {
      history.replace(COURSES_ON_DEMAND_PATH)
    }

    // We only want this to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const getPageLocationForTracking = () => {
    if (isLoggedIn) {
      if (currentUser?.is?.member) {
        return '/courses (paid)'
      }
      return '/courses (free)'
    }
    return '/courses (anon)'
  }

  const clearFiltersForType = (filterType: FilterType) => {
    searchParams.delete(filterType)

    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString()
    })
  }

  const pageLocation = getPageLocationForTracking()

  function handleTopicSelection(topics: string[]) {
    setTopicsQueryParam(topics)
  }

  const handleSelectSortType = (sortType: string) => {
    if (sortType === selectedSortType) return

    trackSortSelected({
      location: pageLocation,
      sort_by: sortType.toLowerCase()
    })
    setSelectedSortType(sortType)
  }

  const tabs = [
    {
      label: 'On demand',
      onClick: () => {
        history.push(COURSES_ON_DEMAND_PATH)
        trackNavigationClicked({
          text: 'on demand',
          destination: COURSES_ON_DEMAND_PATH,
          type: 'pill',
          location: '/courses'
        })
      },
      isActive: tabSearchParam === COURSE_FILTER_ON_DEMAND
    },
    {
      label: 'Live',
      onClick: () => {
        history.push(COURSES_LIVE_PATH)
        trackNavigationClicked({
          text: 'live',
          destination: COURSES_LIVE_PATH,
          type: 'pill',
          location: '/courses'
        })
      },
      isActive: tabSearchParam === COURSE_FILTER_LIVE
    },
    ...(isLoggedIn
      ? [
          {
            label: 'My courses',
            onClick: () => {
              history.push(COURSES_MY_COURSES_PATH)
              trackNavigationClicked({
                text: 'my courses',
                destination: COURSES_MY_COURSES_PATH,
                type: 'pill',
                location: '/courses'
              })
            },
            isActive: tabSearchParam === COURSES_TAB_MY_COURSES
          }
        ]
      : [])
  ]

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center space-x-4">
        <Tabs tabs={tabs} />
      </div>
      <div className="flex justify-between mt-6">
        {allCourseTopics.length > 0 && tabSearchParam !== COURSES_TAB_MY_COURSES && (
          <div>
            <DropdownMultiSelect
              displayText="Topics"
              data={allCourseTopics.map((topic) => ({
                label: topic.title,
                value: topic.slug
              }))}
              className="h-[40px] min-w-[220px] w-full"
              dropdownClassName="min-w-[220px] w-full"
              containerClassName="w-full"
              selectedItems={selectedFilters.topics}
              onSelection={handleTopicSelection}
            />
          </div>
        )}
        {selectedFilters.availability === COURSE_FILTER_LIVE && (
          <DropdownSelect
            data={[
              { label: COURSE_SORT_TYPE_START_DATE, value: COURSE_SORT_TYPE_START_DATE },
              { label: COURSE_SORT_TYPE_DURATION, value: COURSE_SORT_TYPE_DURATION },
              { label: COURSE_SORT_TYPE_ALPHA, value: COURSE_SORT_TYPE_ALPHA }
            ]}
            value={selectedSortType}
            onChange={handleSelectSortType}
            label={'Sort By'}
            className={'flex w-[300px] flex-row items-center justify-end gap-2'}
          />
        )}
      </div>
      {tabSearchParam === COURSES_TAB_MY_COURSES ? (
        <MyCoursesTab />
      ) : (
        <Courses
          activeTab={tabSearchParam || COURSE_FILTER_ON_DEMAND}
          filters={{
            availability: selectedFilters.availability
              ? [selectedFilters.availability]
              : [],
            topics: selectedFilters.topics
          }}
          sort={selectedSortType}
          pageLocation={pageLocation}
          setFilterTypeJustApplied={setFilterTypeJustApplied}
          filterTypeJustApplied={filterTypeJustApplied}
          clearFiltersForType={clearFiltersForType}
        />
      )}
    </div>
  )
}

export default CoursesList
