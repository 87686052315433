import Lottie from 'lottie-react'
import { useEffect, useRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import Button from 'components/Button'
import { useCalendarDropDown } from 'components/CalendarDropdownMenu'
import { SVGIcon } from 'components/Icon'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import animationData from 'components/animations/rocket-animation.json'
import { CloseIcon } from 'components/icons'
import CopyIcon from 'components/icons/CopyIcon'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useQueryParams } from 'hooks/useQueryParams'

import {
  FULL_DATE_ABBREV_MONTH_FORMAT,
  HOUR_MIN_FORMAT,
  getAbbreviatedTimezone,
  getFormattedStartDate
} from 'utils/date'
import { cn } from 'utils/tailwind'
import { trackCopyLinkClicked, trackModalDisplayed } from 'utils/tracking/analytics'
import { EVENT_PAGE_URL, makeUrl } from 'utils/url'

import { CongratsModalProps } from '../types'
import AddToCalendarCard from './Calendar'

export default function CongratsModal({ event, open, handleClose }: CongratsModalProps) {
  const { deleteQueryParam } = useQueryParams()

  useEffect(() => {
    trackModalDisplayed({
      category: 'app',
      modal_group: 'event registration',
      modal_name: 'event registration success',
      location: 'event_details_page'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const topNav = document.getElementById('top-nav')
    const leftNav = document.getElementById('left-nav')
    const pageDiv = document.getElementById('page')

    topNav?.classList.remove('z-[1010]')
    leftNav?.classList.remove('z-[200]')
    pageDiv?.classList.add('overflow-hidden')
    deleteQueryParam('rsvp')
    return () => {
      pageDiv?.classList.remove('overflow-hidden')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { startsAtUtc, timezone: evenTimezone } = event
  const { currentUser } = useCurrentUser()
  const ref: any = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, handleClose, { stopPropagation: false })

  const { isAddToCalendarOpen, setIsAddToCalendarOpen } = useCalendarDropDown()

  const timezone = currentUser?.timezone || evenTimezone
  const EventDate = getFormattedStartDate(
    startsAtUtc,
    timezone,
    FULL_DATE_ABBREV_MONTH_FORMAT
  )

  const startTime = getFormattedStartDate(startsAtUtc, timezone, HOUR_MIN_FORMAT)
  const tZone = getAbbreviatedTimezone(startsAtUtc, timezone)
  const eventShareUrl = makeUrl(
    `${window.location.origin}${EVENT_PAGE_URL}`,
    {
      eventID: event.id,
      eventSlug: event.slug
    },
    {
      utm_source: 'event_page',
      utm_medium: 'product',
      utm_campaign: 'confirmation_share_modal'
    }
  )

  function handleCopy() {
    trackCopyLinkClicked({
      content_link_type: 'event_share_link',
      content_link_id: event.id,
      content_link_title: event.name,
      related_identifiers: {
        event_id: event.id,
        event_title: event.name,
        event_kind: event.kind,
        is_completed_event: event.endsAtUtc < new Date().toISOString()
      }
    })

    displayToast(<ToastCard type="success" message="Copied" />, {
      ...toastOptions,
      toastId: 'event-copy-toast'
    })
  }

  if (!open) {
    return null
  }

  return (
    <div className="fixed top-0 left-0 !z-[2000] flex h-screen w-screen animate-fadeIn items-center justify-center overflow-y-auto bg-rb-black bg-opacity-35">
      <div
        ref={ref}
        className={cn(
          'relative m-3 flex w-[calc(100vw-24px)] max-w-[700px] animate-slideDown flex-col items-center justify-center rounded-md bg-white p-3 py-6'
        )}
      >
        <div
          onClick={handleClose}
          onKeyDown={handleClose}
          className="absolute top-[13px] right-[21px] flex h-6 w-full cursor-pointer items-center justify-end"
          tabIndex={0}
          role="button"
        >
          <div className="flex h-6 w-6 items-center justify-center rounded-full border border-rb-gray-100">
            <CloseIcon className="h-4 w-5 fill-rb-gray-300" />
          </div>
        </div>
        <div className="h-6"></div>

        <div className="flex h-full w-full flex-col items-center justify-center overflow-hidden md:flex-row md:space-x-4">
          <Lottie
            animationData={animationData}
            loop={true}
            className="chromatic-ignore h-[211px] w-[214px]  md:h-[262px] md:w-[319px]"
          />
          <div
            className={cn('flex h-full w-full flex-col items-start overflow-scroll px-2')}
          >
            <div className="mb-[18px] font-sans text-[1.75rem] font-medium">
              {"Congrats, you're in!"}
            </div>
            <p className="mb-8 font-sans text-base font-normal text-rb-black">
              {"You've successfully RSVP'd for "}
              <span className="text-rb-teal-600">{event.name}</span> on{' '}
              <span className="">
                {EventDate} at {startTime} {tZone}
              </span>
              .
            </p>
            {isAddToCalendarOpen ? (
              // CTA text matches existing Course CTA event, even though the button uses lowercase c
              <AddToCalendarCard
                event={event}
                showHeader
                className="flex w-full -translate-x-0 flex-col items-start rounded border border-rb-black bg-white px-4 py-1 shadow-none"
                sourceCtaText="Add to Calendar"
              />
            ) : (
              <Button
                color="teal"
                className="mb-[18px] h-10 w-full"
                onClick={() => setIsAddToCalendarOpen(true)}
              >
                <SVGIcon width="16" height="16" name="events" fill="white" />
                <span className="ml-2 text-sm font-semibold">Add to calendar</span>
              </Button>
            )}
            <p
              className={cn(
                'mb-[6px]  text-sm font-semibold',
                isAddToCalendarOpen && 'mt-[18px]'
              )}
            >
              Share this event with others
            </p>
            <div className="h-[50px] w-full">
              <CopyToClipboard onCopy={handleCopy} text={eventShareUrl}>
                <div className="flex h-[50px] w-full cursor-pointer items-center truncate rounded-md border border-rb-gray-100 bg-rb-gray-50 px-4">
                  <span className="flex-1 truncate font-sans text-rb-gray-300">
                    {eventShareUrl}
                  </span>

                  <CopyIcon className="ml-6 h-5 w-5" />
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
