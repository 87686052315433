import { Link, useHistory } from 'react-router-dom'

import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { SimilaritySearchBreadcrumb } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const LessonItem = ({
  searchDocument,
  searchQuery,
  userCan,
  onResultClick
}: ContentResultItemProps) => {
  const history = useHistory()
  const { title, highlight, thumbnail, breadCrumbs, url } = searchDocument

  const handleClick = () => {
    if (userCan.linkToSearchResults) {
      onResultClick()
      history.push(url)
    }
  }

  const buttonProps = userCan.linkToSearchResults
    ? {}
    : { 'uk-toggle': 'target: #guest-modal-search' }

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      onClick={handleClick}
      onKeyUp={onEnterKeyPress(handleClick)}
      {...buttonProps}
    >
      <div className="relative flex-1">
        <RfParagraphLarge className="mb-0 font-medium">
          <span dangerouslySetInnerHTML={{ __html: formatTitle(searchQuery, title) }} />
        </RfParagraphLarge>
        <BreadCrumb crumbs={breadCrumbs} />
        <div className="mt-1">
          <span
            dangerouslySetInnerHTML={{
              __html: formatTitle(searchQuery, highlight, 190)
            }}
            className="overflow-wrap break-words line-clamp-4"
          />
        </div>
      </div>
      <div className="relative hidden pl-6 tl:block lgr:hidden xl:block">
        {thumbnail && (
          <img src={thumbnail} className="h-fit max-h-28 object-right" alt="" />
        )}
      </div>
    </div>
  )
}

const titleizeText = (text: string) =>
  text
    .split(' ')
    .map((word) => (word.length ? `${word[0].toUpperCase()}${word.slice(1)}` : ''))
    .join(' ')

const BreadCrumb = ({ crumbs }: { crumbs: Array<SimilaritySearchBreadcrumb> }) => {
  if (crumbs.length === 0) return null

  return (
    <div className="mt-1 text-sm text-rb-teal-200">
      {crumbs.map(({ label, url }, idx) => {
        return (
          <span key={`search-crumb-${idx}`}>
            {idx > 0 && ' > '}
            <Link
              className="text-rb-teal-200 hover:text-rb-teal-300"
              to={url}
              onClick={(e) => e.stopPropagation()}
            >
              {titleizeText(label || '')}
            </Link>
          </span>
        )
      })}
    </div>
  )
}

export default LessonItem
