import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import {
  ComplexPortableTextBlockProp,
  ComplexPortableTextBlockValueProp
} from '../../types'
import ScriptEmbed from '../ScriptEmbed'

export const renderH2 = ({ children, value }: ComplexPortableTextBlockProp) => (
  <RfHeader2 id={value?._key}>{children}</RfHeader2>
)
export const renderH3 = ({ children, value }: ComplexPortableTextBlockProp) => (
  <RfHeader3SemiBold id={value?._key}>{children}</RfHeader3SemiBold>
)
export const renderCodeBlock = ({ children, value }: ComplexPortableTextBlockProp) => (
  <pre id={value?._key} className="bg-gray-100 text-rb-destructive-code">
    <code className="whitespace-normal">{children}</code>
  </pre>
)
export const renderNormal = ({ children, value }: ComplexPortableTextBlockProp) => (
  <RfParagraphMedium className="mb-4" id={value?._key}>
    {children}
  </RfParagraphMedium>
)

export const renderScriptEmbed = ({ value }: ComplexPortableTextBlockValueProp) => (
  <ScriptEmbed script={value.script} />
)
