import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { sortMapping, topicMapping } from 'pages/Artifacts/ArtifactsIndexPage/helper'
import { ArtifactsQueryMetadata } from 'pages/Artifacts/ArtifactsIndexPage/types'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import DropdownMultiSelect from 'components/dropdowns/DropdownMultiSelect/DropdownMultiSelect'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'

import { ARTIFACTS_INDEX_TRACK_LOCATION } from 'constants/artifacts'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { onKeyPress } from 'utils/keyboard'
import {
  trackCtaClicked,
  trackFilterApplied,
  trackFilterRemoved,
  trackSortSelected
} from 'utils/tracking/analytics'

export interface MultiSelectDropdownProps {
  clearAll?: () => void
  data: React.ComponentProps<typeof DropdownMultiSelect>[]
  handleFilterSelection: any
  filterCount: number
  showMobileTopicFilterMenu: boolean
  filteredArtifactsLoading?: boolean
  artifactsQueryMeta: ArtifactsQueryMetadata
  setShowMobileTopicFilterMenu: any
}

const MultiselectDropdown: React.FC<MultiSelectDropdownProps> = ({
  data,
  filterCount,
  clearAll,
  artifactsQueryMeta,
  filteredArtifactsLoading,
  setShowMobileTopicFilterMenu,
  handleFilterSelection,
  showMobileTopicFilterMenu
}) => {
  const { isLoggedIn } = useCurrentUser()

  function handleFilterApplied(selections: any, filterChanged: string) {
    let activeFilterCount = 0
    const newData: any = {}

    for (const [key, value] of Object.entries(selections)) {
      const newKey = topicMapping[key]
      newData[newKey] = value
    }

    for (const [key, val] of Object.entries(newData)) {
      if (key === 'sortBy') continue
      if (Array.isArray(val)) {
        activeFilterCount += val.length
      } else if (val) {
        activeFilterCount += 1
      }
    }

    trackFilterApplied({
      filter_location: ARTIFACTS_INDEX_TRACK_LOCATION,
      filter_changed: topicMapping[filterChanged],
      filters: newData,
      active_filter_count: activeFilterCount,
      logged_in: isLoggedIn
    })
  }
  const UncheckedBox = (
    <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
  )
  const CheckedBox = (
    <SVGIcon name="checkbox-ticked" fill="#1A6C69" width="20" height="20" />
  )
  const [listData, setListData] = useState<
    React.ComponentProps<typeof DropdownMultiSelect>[]
  >([])
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: string[] }>({})
  const [keyMap, setKeyMap] = useState<{ [key: string]: string }>({})
  const location = useLocation() // This hook gives you access to the location object
  const searchParams = new URLSearchParams(location.search)
  const [sortBy, setSortBy] = useState<'default' | 'most-popular'>(
    (searchParams.get('sortBy') as any) || 'default'
  )
  const handleMobileClick = ({
    cb,
    key,
    val,
    checked
  }: {
    cb: any
    key: string
    val: string
    checked: boolean
  }) => {
    let data = selectedItems[key]

    if (Array.isArray(data) && checked) {
      handleItemRemove(key, data.indexOf(val))
    } else if (Array.isArray(data) && !checked) {
      data = [...data, val]

      handleItemApplied(key, data, cb)
    } else {
      data = [val]
      handleItemApplied(key, data, cb)
    }
  }

  const handleItemApplied = (key: string, selection: string[], cbFunc: any) => {
    if (cbFunc) {
      cbFunc(selection)
    }
    setSelectedItems((prevSelections) => {
      let previousVal: string[] = []

      if (Object.keys(prevSelections).length) {
        previousVal = [...prevSelections[key]]
      }

      const selections = {
        ...prevSelections,
        [key]: selection
      }

      if (previousVal.length > selection.length) {
        const set = new Set(selection)
        const missingEl = previousVal.find((element) => !set.has(element))

        trackRemoved(key, String(missingEl))
      } else {
        handleFilterApplied(selections, key)
      }

      return selections
    })
  }

  const handleItemRemove = (key: string, selectionIndex: number) => {
    const props = data.find((d) => d.displayText === key)
    const list = [...selectedItems[key]]
    const selectionRemoved = list[selectionIndex]
    list.splice(selectionIndex, 1)

    const newSelections = {
      ...selectedItems,
      [key]: list
    }

    if (props?.onSelection) {
      props.onSelection(list)
    }

    trackRemoved(key, selectionRemoved)

    setSelectedItems(newSelections)
  }

  const handleClearAll = () => {
    const temp: { [key: string]: string[] } = {}

    for (const obj of data) {
      temp[obj.displayText] = []
    }
    setSelectedItems({ ...temp })
    if (clearAll) {
      clearAll()
    }
    trackRemoved('all', 'all')
  }

  function trackRemoved(key: string, val: string) {
    trackFilterRemoved({
      filter_location: ARTIFACTS_INDEX_TRACK_LOCATION,
      filter_removed: key === 'all' ? 'all' : topicMapping[key],
      filter_value: val,
      logged_in: isLoggedIn
    })
  }

  const handleSortBy = (value: 'default' | 'most-popular') => {
    handleFilterSelection('sortBy', value)
    setSortBy(value)

    trackSortSelected({
      sort_by: sortMapping[value] || undefined,
      location: ARTIFACTS_INDEX_TRACK_LOCATION,
      logged_in: isLoggedIn
    })
  }

  useEffect(() => {
    const temp: { [key: string]: string[] } = {}
    const tempMap: { [key: string]: string } = {}

    for (const obj of data) {
      temp[obj.displayText] = obj.selectedItems || []

      for (const d of obj.data) {
        tempMap[d.value] = d.label
      }
    }

    setSelectedItems(temp)
    setKeyMap(tempMap)
    setListData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShowResults = () => {
    setShowMobileTopicFilterMenu(false)
    trackCtaClicked({
      text: 'show results',
      cta_type: 'button',
      cta_location: ARTIFACTS_INDEX_TRACK_LOCATION,
      logged_in: isLoggedIn
    })
  }

  useEffect(() => {
    if (Array.from(searchParams.keys()).length === 0) {
      setSortBy('default')
      setSelectedItems((previousSelections) => {
        const temp: { [key: string]: string[] } = {}

        for (const [key] of Object.entries(previousSelections)) {
          temp[key] = []
        }

        return temp
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()])

  return (
    <>
      {showMobileTopicFilterMenu && (
        <div className="relative flex flex-col px-4 xl:hidden">
          <button
            className="flex cursor-pointer items-center py-1 font-sans text-sm text-rb-black"
            onClick={handleClearAll}
          >
            <span className="font-sans text-sm font-semibold text-rb-teal-200 underline">
              Clear all
            </span>
          </button>
          <hr className="my-4 w-full" />

          <DropdownSelect
            mobile
            mobileDefaultOpen={true}
            data={[
              { value: 'default', label: 'Most recent' },
              { value: 'most-popular', label: 'Most popular' }
            ]}
            value={searchParams.get('sortBy')}
            label={'Sort By'}
            onChange={handleSortBy}
            className={'flex w-[300px] flex-row items-center gap-2 justify-end'}
          />
          {listData.map((props, index: number) => {
            const defaultOpen = Boolean(selectedItems?.[props?.displayText]?.length)
            return (
              <div key={index}>
                <hr className="my-4 w-full" />
                <Disclosure defaultOpen={defaultOpen}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex h-[45px] w-full cursor-pointer items-center justify-between bg-white text-rb-black">
                        <span className="text-base font-semibold leading-relaxed">
                          {props.displayText}
                        </span>
                        <ChevronDownIcon
                          className={twMerge(
                            'h-[16px] w-[16px] text-rb-black',
                            open && 'rotate-180 transform'
                          )}
                        />
                      </Disclosure.Button>

                      <Disclosure.Panel>
                        {props.data.map((obj) => {
                          const isChecked = selectedItems[props.displayText]?.includes(
                            obj.value
                          )

                          return (
                            <div
                              key={props.displayText + obj.value}
                              className="mb-[15px] flex cursor-pointer items-center"
                              role="checkbox"
                              tabIndex={0}
                              aria-checked={isChecked}
                              onClick={() =>
                                handleMobileClick({
                                  cb: props.onSelection,
                                  key: props.displayText,
                                  val: obj.value,
                                  checked: isChecked
                                })
                              }
                              onKeyDown={onKeyPress('Enter', () => {
                                handleMobileClick({
                                  cb: props.onSelection,
                                  key: props.displayText,
                                  val: obj.value,
                                  checked: isChecked
                                })
                              })}
                            >
                              <span className="mr-2">
                                {isChecked ? CheckedBox : UncheckedBox}
                              </span>
                              <div className="text-sans text-sm font-normal text-rb-gray-400">
                                {obj.label}
                              </div>
                            </div>
                          )
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            )
          })}
          <hr className="my-4 w-full" />

          {!!filterCount && (
            <div className="fixed bottom-[2%] right-0 z-10 flex w-full items-center justify-center overflow-hidden px-4">
              <Button
                className="h-[54px] w-full rounded-full bg-rb-black text-white"
                onClick={handleShowResults}
                isLoadingSpinner={filteredArtifactsLoading}
              >
                {artifactsQueryMeta?.totalArtifactsCount
                  ? `Show ${artifactsQueryMeta?.totalArtifactsCount} result${
                      artifactsQueryMeta?.totalArtifactsCount !== 1 ? 's' : ''
                    }`
                  : 'No matches :('}
              </Button>
            </div>
          )}
        </div>
      )}

      <div className="hidden min-w-[330px] flex-auto flex-col tl:mb-[30px] xl:flex">
        <div className="hidden flex-col xl:flex">
          <div className="flex items-center justify-between gap-3">
            {data.map((props, index) => (
              <DropdownMultiSelect
                key={index}
                {...props}
                selectedItems={selectedItems[props.displayText] || []} // Pass the selected items as a prop
                onSelection={(selections) =>
                  handleItemApplied(props.displayText, selections, props.onSelection)
                }
              />
            ))}
          </div>
          {Object.values(selectedItems).some((selection) => selection.length > 0) && (
            <div className="mt-[30px] flex flex-wrap items-center">
              <span className="font-sans text-sm font-semibold">Active filters:</span>

              {Object.entries(selectedItems).map(([key, selections]) => {
                return selections.map((selection: string, selectionIndex: number) => (
                  <div
                    key={selectionIndex}
                    className="flex cursor-pointer items-center px-3 py-1 font-sans text-xs text-rb-black"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleItemRemove(key, selectionIndex)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        handleItemRemove(key, selectionIndex)
                      }
                    }}
                  >
                    <span className="mr-1">{keyMap[selection]}</span>
                    <XMarkIcon className="h-5 w-5" />
                  </div>
                ))
              })}

              <div
                className="flex cursor-pointer items-center px-3 py-1 font-sans text-sm text-rb-black"
                role="button"
                tabIndex={0}
                onClick={handleClearAll}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    handleClearAll()
                  }
                }}
              >
                <span className="font-sans font-semibold text-rb-teal-200">
                  Clear all
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={twMerge(
          'mb-[13px] hidden w-full items-center xl:flex',
          artifactsQueryMeta?.totalArtifactsCount ? 'justify-between' : 'justify-end'
        )}
      >
        {!!artifactsQueryMeta?.totalArtifactsCount && (
          <span className="font-sans text-base font-semibold text-rb-gray-400">
            {artifactsQueryMeta?.totalArtifactsCount > 1 &&
              `${artifactsQueryMeta?.totalArtifactsCount} results`}

            {artifactsQueryMeta?.totalArtifactsCount === 1 &&
              `${artifactsQueryMeta?.totalArtifactsCount} result`}
          </span>
        )}
        <DropdownSelect
          data={[
            { label: 'default', value: 'Most recent' },
            { label: 'most-popular', value: 'Most popular' }
          ]}
          value={sortBy}
          onChange={handleSortBy}
          label={'Sort By'}
          className={'flex w-[300px] flex-row items-center justify-end gap-2'}
        />
      </div>
    </>
  )
}

export default MultiselectDropdown
