import { useBlock } from '@dopt/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import ExtensionLaunchModal from 'domains/Extension/LaunchModal'

import { useGlobalModal } from 'components/GlobalModal'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'
import { trackModalDismissed, trackModalDisplayed } from 'hooks/useModalTracking'

import { trackCtaClicked } from 'utils/tracking/analytics'

export default function useShowLaunchModal() {
  const { pathname, search } = useLocation()
  const { currentUser } = useCurrentUser()
  const { openGlobalModal, closeGlobalModal, isGlobalModalOpen } = useGlobalModal()
  const mediumOrLargerScreen = useMediaQuery('(min-width: 768px)')
  const [block, transition] = useBlock<['complete']>('extension-launch-modal.modal')

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore')
    if (!hasVisitedBefore) {
      localStorage.setItem('hasVisitedBefore', 'true')
    }
  }, [])

  const showModal = () => {
    if (!localStorage.getItem('hasVisitedBefore')) return
    /* don't show for non-free users who have not yet onboarded */
    if (currentUser && !currentUser.is.freeUser && !currentUser.onboarded) return
    /* don't show for users who are not network-onboarded (haven't selected focus areas for the first time) */
    if (currentUser && !currentUser.networkOnboarded) return
    if (pathname.startsWith('/courses') && pathname.includes('sessions')) return
    if (pathname.startsWith('/events/') && search.includes('rsvp=true')) return
    if (
      pathname.startsWith('/try-reforge') ||
      pathname.startsWith('/subscribe') ||
      pathname.startsWith('/course-payment') ||
      pathname.startsWith('/course-enroll-confirmation')
    ) {
      return
    }
    if (isGlobalModalOpen) return
    if (!mediumOrLargerScreen) return
    if (!block?.state.active) return

    const handleClose = () => {
      trackModalDismissed({
        modal_group: 'extension',
        modal_name: 'launch'
      })
      transition('complete')
      closeGlobalModal()
    }

    const onLinkClick = () => {
      trackCtaClicked({
        cta_location: pathname,
        cta_type: 'button',
        text: 'Learn more',
        destination: '/extension'
      })
      transition('complete')
      closeGlobalModal()
    }

    trackModalDisplayed({
      modal_group: 'extension',
      modal_name: 'launch'
    })

    openGlobalModal(<ExtensionLaunchModal onLinkClick={onLinkClick} />, {
      className: 'max-w-[672px] rounded-3xl bg-aqua-gradient',
      closeOnOutsideClick: false,
      handleClose: handleClose
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(showModal, [
    block?.state.active,
    closeGlobalModal,
    openGlobalModal,
    mediumOrLargerScreen,
    pathname,
    transition,
    currentUser,
    isGlobalModalOpen
  ])
}
