import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import {
  PURCHASE_TYPE_COURSE,
  PurchaseFlowProvider,
  usePurchaseFlowContext
} from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import { Loading } from 'components'
import { Alert } from 'components/Alert'
import BillingAndPaymentForm from 'components/BillingAndPaymentForm'
import { BillingAndPaymentFormExposedMethods } from 'components/BillingAndPaymentForm/BillingAndPaymentForm'
import Button from 'components/Button'
import { useTaxInfo } from 'components/CheckoutModal/useTaxInfo'
import { ProductItemCodes } from 'components/CheckoutModal/utils'
import { CloseIcon } from 'components/icons'

import {
  PlanName,
  useCoursePaymentPageQuery,
  useCreateCourseEnrollmentMutation,
  useCurrentUserMemberAccessQuery,
  useEndSubscriptionTrialEarlyMutation,
  useTrackCourseEnrollmentConfirmedMutation
} from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { COURSE_PAYMENT_METHODS, buildCourseSessionLink } from 'utils/courseUtils'
import { MONTH_ABBREV_DATE_FORMAT, getFormattedStartDate, isAfterDate } from 'utils/date'
import { displayErrorToast } from 'utils/toastService'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as Logo } from 'images/reforge-logo-black.svg'

import EndTrialToEnrollPrompt from '../EndTrialToEnrollPrompt'
import { CourseOrderSummary } from './CourseOrderSummary'

const MARKETING_SITE_COURSES_PAGE = '/courses'
const COURSE_FULL_WAITLIST_PAGE = '/courses-marketplace-waitlist/course-full'
const GENERAL_WAITLIST_PAGE = '/courses-marketplace-waitlist'

export const getCourseEnrollmentSuccessPath = (
  courseSlug: string,
  courseSessionId: number
): string => {
  const destination = buildCourseSessionLink({
    courseSlug: courseSlug,
    sessionId: courseSessionId.toString()
  })

  return `/course-enroll-confirmation?redirectPath=${destination}`
}

const CTA_LOCATION = 'course_payment_page'

const CoursePaymentPage = () => {
  const history = useHistory()
  const { slug, startDate } = useParams<{ slug: string; startDate: string }>()
  const billingAndPaymentFormRef = useRef<BillingAndPaymentFormExposedMethods>(null)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [errorMessage, setErrorMessage] = useState('')
  const { selectedPaymentMethodId } = usePurchaseFlowContext()
  const [createCourseEnrollment, { loading: createCourseEnrollmentLoading }] =
    useCreateCourseEnrollmentMutation({
      notifyOnNetworkStatusChange: true
    })
  const [endSubscriptionTrialEarly] = useEndSubscriptionTrialEarlyMutation()
  const { userIdentified, showCourseEnrollmentPages } = useFeatureFlags()
  const [saveLoading, setSaveLoading] = useState(false)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [canPurchase, setCanPurchase] = useState(false)
  const [endSubscriptionLoading, setEndSubscriptionLoading] = useState(false)
  const [trackCourseEnrollmentConfirmed] = useTrackCourseEnrollmentConfirmedMutation()

  const {
    data: currentUserData,
    loading: currentUserLoading,
    refetch: refetchCurrentUser
  } = useCurrentUserMemberAccessQuery()
  const {
    data,
    loading: courseSessionLoading,
    error
  } = useCoursePaymentPageQuery({
    variables: {
      slug: slug,
      startDate: startDate
    }
  })

  const { taxInfo, refetchTaxInfo, taxIdReverseChargeStatus, taxInfoLoading } =
    useTaxInfo({
      skip: !currentUserData?.currentUser?.stripeCustomer?.id,
      productKeys: [ProductItemCodes.COURSE_ITEM_CODE],
      onTaxIdVerified: useCallback(() => {
        refetchTaxInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    })

  useEffect(() => {
    if (!taxInfo && currentUserData?.currentUser?.stripeCustomer?.id) {
      refetchTaxInfo()
    }
  }, [currentUserData, refetchTaxInfo, taxInfo])

  const currentUser = currentUserData?.currentUser || null

  const paymentMethod = useMemo(() => {
    if ((currentUser?.paymentMethods.length || 0) > 1) {
      const defaultPaymentMethod = currentUser?.paymentMethods?.find(
        (pm) => pm.defaultPaymentMethod
      )

      if (defaultPaymentMethod) {
        return defaultPaymentMethod
      }

      return currentUser?.paymentMethods[0]
    }

    return currentUser?.paymentMethods?.[0] || null
  }, [currentUser?.paymentMethods])

  if (userIdentified && !showCourseEnrollmentPages) {
    history.push('/courses')
    return null
  }

  const handleGoBackClick = () => {
    history.push(`/courses/${slug}/details`)
  }

  const renderHeader = () => {
    return (
      <div className="fixed left-0 right-0 top-0 z-1 flex h-16 w-full items-center justify-between bg-white px-4 shadow-purchase-flow-header md:px-6">
        <Logo className="h-6" />
        <Button variant="text-only" className="p-0" onClick={handleGoBackClick}>
          <CloseIcon className="w-6" />
        </Button>
      </div>
    )
  }

  if (!data || currentUserLoading || courseSessionLoading) {
    return (
      <>
        {renderHeader()}
        <div className="mt-32">
          <Loading />
        </div>
      </>
    )
  }

  const isUnlimitedMember =
    currentUser?.subscriptions?.active?.planName === PlanName.UNLIMITED

  let willSubExpireBeforeSessionStart = true

  if (currentUser?.subscriptions?.active) {
    willSubExpireBeforeSessionStart = isAfterDate(
      currentUser?.subscriptions?.active?.expiresAt,
      data?.courseSession?.startsAt
    )
  }

  if (error) {
    if (!errorMessage) {
      setErrorMessage(error.message)
    }
    setTimeout(() => {
      window.location.href = MARKETING_SITE_COURSES_PAGE
    }, 3000)
  } else if (!data?.courseSession) {
    if (!errorMessage) {
      setErrorMessage('No matching course session for this course and date.')
    }
  }

  if (!error && data?.courseSession?.isEnrollmentFull) {
    return <Redirect to={COURSE_FULL_WAITLIST_PAGE} />
  }

  if (!error && !data?.courseSession?.isDuringEnrollmentPeriod) {
    return <Redirect to={GENERAL_WAITLIST_PAGE} />
  }

  const handleConfirmClick = async () => {
    setErrorMessage('')
    trackCtaClicked({
      cta_location: CTA_LOCATION,
      cta_type: 'button',
      text: 'Confirm'
    })

    const response = await createCourseEnrollment({
      variables: {
        input: {
          courseSlug: slug,
          startDate: startDate
        }
      }
    })

    trackCourseEnrollmentConfirmed({
      variables: {
        input: {
          courseSessionId: data?.courseSession?.id,
          courseSlug: slug,
          ctaLocation: CTA_LOCATION,
          paymentMethod: COURSE_PAYMENT_METHODS.unlimited
        }
      }
    })

    if (
      response.data?.createCourseEnrollment?.errors &&
      response.data.createCourseEnrollment.errors.length > 0
    ) {
      setErrorMessage(response.data.createCourseEnrollment.errors[0])
    } else if (
      response.data?.createCourseEnrollment?.id &&
      response.data?.createCourseEnrollment?.courseSessionId
    ) {
      history.push(
        getCourseEnrollmentSuccessPath(
          slug,
          response.data?.createCourseEnrollment.courseSessionId
        )
      )
    }
  }

  const handleCohortPassClick = async () => {
    setErrorMessage('')
    trackCtaClicked({
      cta_location: CTA_LOCATION,
      cta_type: 'button',
      text: 'Pay with a Live Course Pass'
    })

    trackCourseEnrollmentConfirmed({
      variables: {
        input: {
          courseSessionId: data?.courseSession?.id,
          courseSlug: slug,
          ctaLocation: CTA_LOCATION,
          paymentMethod: COURSE_PAYMENT_METHODS.cohortPass
        }
      }
    })

    const response = await createCourseEnrollment({
      variables: {
        input: {
          courseSlug: slug,
          startDate: startDate,
          useCohortPass: true
        }
      }
    })

    if (
      response.data?.createCourseEnrollment?.errors &&
      response.data.createCourseEnrollment.errors.length > 0
    ) {
      setErrorMessage(response.data.createCourseEnrollment.errors[0])
    } else if (
      response.data?.createCourseEnrollment?.id &&
      response.data?.createCourseEnrollment?.courseSessionId
    ) {
      history.push(
        getCourseEnrollmentSuccessPath(
          slug,
          response.data.createCourseEnrollment.courseSessionId
        )
      )
    }
  }

  const handleSubmitBillingInfoClick = async () => {
    setErrorMessage('')
    const success = await billingAndPaymentFormRef.current?.saveBillingAndPayment()

    if (!success) {
      return
    }

    refetchCurrentUser()
    refetchTaxInfo()
    setCanPurchase(true)

    trackCtaClicked({
      cta_location: CTA_LOCATION,
      cta_type: 'button',
      text: 'submit billing info'
    })
  }

  const handleEndTrialConfirmClick = async () => {
    setEndSubscriptionLoading(true)
    const response = await endSubscriptionTrialEarly()
    const errors = response.data?.endSubscriptionTrialEarly?.errors || []
    if (errors.length > 0) {
      displayErrorToast({
        message:
          "We couldn't process your payment. Please contact hello@reforge.com so we can assist"
      })
      setEndSubscriptionLoading(false)
      return
    }

    if (response.data?.endSubscriptionTrialEarly?.success) {
      await refetchCurrentUser()
    }

    setEndSubscriptionLoading(false)
  }

  const renderBody = () => {
    if (!data?.courseSession) {
      return null
    }

    if (isUnlimitedMember && !willSubExpireBeforeSessionStart) {
      // Confirm CTA to enroll in course for unlimited/AYCE members
      return (
        <section className="flex flex-col items-center lg:mr-12">
          <div className="flex flex-col items-center rounded-sm border-2 py-4 px-6 sm:w-[420px]">
            <h1 className="mb-8 text-center text-3xl">
              {data?.courseSession?.courseTitle}
            </h1>
            <div className="text-lg">
              {getFormattedStartDate(
                data?.courseSession?.startsAt,
                userTimeZone,
                MONTH_ABBREV_DATE_FORMAT
              )}{' '}
              -{' '}
              {getFormattedStartDate(
                data?.courseSession?.endsAt,
                userTimeZone,
                MONTH_ABBREV_DATE_FORMAT
              )}
            </div>

            <Button className="mt-4 w-full" onClick={handleConfirmClick}>
              Confirm Enrollment
            </Button>
          </div>
        </section>
      )
    }

    const last4Digits = paymentMethod?.card?.last4 || null

    if (currentUser?.is.trialing && last4Digits) {
      // Confirm CTA to enroll in course for trial members

      return (
        <EndTrialToEnrollPrompt
          onCancelClick={() => history.push(`/courses/${slug}/details`)}
          onConfirmClick={handleEndTrialConfirmClick}
          paymentMethodLast4Digits={last4Digits}
          loading={endSubscriptionLoading}
        />
      )
    }

    if (currentUser?.hasUseableCohortCredits) {
      // Confirm CTA to enroll in course for members with cohort credits
      return (
        <section className="flex flex-col items-center lg:mr-12">
          <div className="flex flex-col items-center rounded-sm border-2 py-4 px-6 sm:w-[420px]">
            <h1 className="mb-8 text-center text-3xl">
              {data?.courseSession?.courseTitle}
            </h1>
            <div className="text-lg">
              {getFormattedStartDate(
                data?.courseSession?.startsAt,
                userTimeZone,
                MONTH_ABBREV_DATE_FORMAT
              )}{' '}
              -{' '}
              {getFormattedStartDate(
                data?.courseSession?.endsAt,
                userTimeZone,
                MONTH_ABBREV_DATE_FORMAT
              )}
            </div>

            <Button
              className="mt-6 w-full"
              onClick={handleCohortPassClick}
              isLoadingSpinner={createCourseEnrollmentLoading}
            >
              Confirm Enrollment
            </Button>

            <div className="mt-6 text-xs leading-5 text-gray-500">
              By enrolling, you agree to use a Reforge Live Course Pass. All enrollments
              are final. Please contact{' '}
              <a className="text-gray-500 underline" href="mailto:hello@reforge.com">
                hello@reforge.com
              </a>{' '}
              with questions or if you need assistance.
            </div>
          </div>
        </section>
      )
    }

    // Show page with payment form and order summary
    return (
      <>
        <section className="w-full max-w-[500px] lg:mr-12">
          <h1 className="mb-8 text-4xl">{data?.courseSession?.courseTitle}</h1>
          <BillingAndPaymentForm
            ref={billingAndPaymentFormRef}
            onSaveLoadingStateChange={setSaveLoading}
            onSaveEnabledStateChange={setSaveEnabled}
          />
          <Button
            className="mt-6 h-12"
            onClick={handleSubmitBillingInfoClick}
            isLoadingSpinner={saveLoading || taxInfoLoading}
            disabled={!saveEnabled}
          >
            Submit Billing Info
          </Button>
        </section>
        <section className="my-12 lg:my-0 lg:ml-12">
          <CourseOrderSummary
            courseSession={data.courseSession}
            courseSlug={slug}
            startDate={startDate}
            selectedPaymentMethodId={selectedPaymentMethodId}
            setErrorMessage={setErrorMessage}
            canPurchase={canPurchase}
            taxInfo={taxInfo}
            refetchTaxInfo={refetchTaxInfo}
            taxIdReverseChargeStatus={taxIdReverseChargeStatus}
          />
        </section>
      </>
    )
  }

  return (
    <div className="relative h-full w-full">
      {renderHeader()}
      <div className="relative mt-16 h-full w-full rounded-lg bg-white p-6 text-center sm:p-8 md:px-16 lg:h-auto lg:w-auto">
        <div className="flex w-full flex-col items-center justify-center">
          {errorMessage && <Alert className="mb-5 text-xl">{errorMessage}</Alert>}
          <div className="flex w-full flex-col items-center justify-center text-left lg:flex-row lg:items-start">
            {renderBody()}
          </div>
        </div>
      </div>
    </div>
  )
}

export const CoursePaymentPageWrapper = () => {
  return (
    <PurchaseFlowProvider purchaseType={PURCHASE_TYPE_COURSE}>
      <CoursePaymentPage />
    </PurchaseFlowProvider>
  )
}
