import { useEffect } from 'react'

import { Loading } from 'components'

const CareersOpenPositions = ({ pathname }: { pathname: string }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://boards.greenhouse.io/embed/job_board/js?for=reforge&b=https://www.reforge.com/${pathname}`
    script.async = true
    script.onload = () => {
      const w: Window & {
        _grnhse?: {
          load: () => void
        }
      } = window

      w._grnhse?.load()
    }
    const el = document.getElementById('grnhse_app')

    el?.parentNode?.insertBefore(script, el.nextSibling)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="relative min-h-[150px] max-w-[980px]">
      <div id="grnhse_app">
        <Loading />
      </div>
    </div>
  )
}

export default CareersOpenPositions
