import { Fragment, MouseEvent } from 'react'
import { Link } from 'react-router-dom'

import BadgeableAvatar from 'components/BadgeableAvatar'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { SearchDocumentResultFragment, SearchDocumentUserFragment } from 'gql'

import { highlightAndSanitize } from '../utils'

export interface UserSearchItemProps {
  document: Omit<SearchDocumentResultFragment, 'searchable'> & {
    searchable: SearchDocumentUserFragment
  }
  searchQuery: string
  userCan: {
    viewMemberProfile?: boolean | null
  }
  onResultClick: (e: MouseEvent<HTMLAnchorElement>) => void
}

function formatMetaInfo({
  role,
  companyName,
  location,
  searchQuery
}: {
  role: string | null
  companyName: string | null
  location: string | null
  searchQuery: string
}) {
  const roleInfo = (role || companyName) && (
    <span
      className="m-0"
      dangerouslySetInnerHTML={{
        __html: highlightAndSanitize(
          searchQuery,
          [role, companyName].filter(Boolean).join(' @ ')
        )
      }}
    />
  )
  const locationInfo = location && (
    <span
      className="m-0 text-rb-black"
      dangerouslySetInnerHTML={{ __html: highlightAndSanitize(searchQuery, location) }}
    />
  )

  return [roleInfo, locationInfo].filter(Boolean).map((infoFragment, index) => (
    <Fragment key={index}>
      {!!index && <span className="px-1 font-normal text-rb-gray-300">&#183;</span>}
      {infoFragment}
    </Fragment>
  ))
}

const UserSearchItem = ({
  document,
  userCan,
  searchQuery,
  onResultClick
}: UserSearchItemProps) => {
  const { title, searchable } = document
  const { profile } = searchable

  return (
    <>
      <article className="mb-0 flex items-center rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6">
        {userCan.viewMemberProfile ? (
          <Link to={`directory/${profile.slug}`} onClick={onResultClick}>
            <BadgeableAvatar
              avatarUrl={profile?.avatarUrl || ''}
              user={profile}
              width="60"
              height="60"
              className="mr-4"
            />
          </Link>
        ) : (
          <a href="#" uk-toggle="target: #guest-modal-search">
            <BadgeableAvatar
              avatarUrl={profile?.avatarUrl || ''}
              user={profile}
              width="60"
              height="60"
              className="hidden sm:mr-4 sm:block"
            />
          </a>
        )}

        <div>
          <h4 className="m-0">
            {userCan.viewMemberProfile ? (
              <RfParagraphLarge className="font-medium">
                <Link
                  to={`directory/${profile.slug}`}
                  className="text-rb-black hover:text-rb-teal-300 hover:no-underline"
                  onClick={onResultClick}
                >
                  {title}
                </Link>
              </RfParagraphLarge>
            ) : (
              <RfParagraphLarge className="font-medium">
                <a
                  href="#"
                  uk-toggle="target: #guest-modal-search"
                  className="text-rb-black hover:text-rb-teal-300 hover:no-underline"
                >
                  {title}
                </a>
              </RfParagraphLarge>
            )}
          </h4>
          <RfParagraphSmall>
            {formatMetaInfo({
              role: profile.role || '',
              companyName: profile.companyName || '',
              location: profile.location || '',
              searchQuery
            })}
          </RfParagraphSmall>
        </div>
      </article>
    </>
  )
}

export default UserSearchItem
