import { useEffect } from 'react'

import { FREE_TRIAL_DAYS } from 'pages/PurchaseFlow/constants'

import Button from 'components/Button'

import { useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { getAnonymousId } from 'utils/tracking/segment'

import { ReactComponent as DiamondIcon } from 'images/icon--diamond-premium.svg'

const CTA_COPY = `Start ${FREE_TRIAL_DAYS}-day free trial`

const FreeTrialBanner = () => {
  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'trial_signup_cta__banner',
      text: CTA_COPY.toLowerCase()
    })
  }

  const [trackServerEvent] = useTrackServerEventMutation()
  const { currentUser } = useCurrentUser()
  useEffect(() => {
    trackServerEvent({
      variables: {
        input: {
          anonymousId: getAnonymousId(),
          event: 'Conversion Page Viewed - Server',
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            path: window.location.pathname,
            page_type: 'trial_signup_cta__home',
            is_conversion_eligible: currentUser?.is.eligibleForTrial,
            logged_in: !!currentUser,
            url: window.location.href,
            referrer: document.referrer,
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackServerEvent])

  return (
    <div className="flex self-stretch px-8 py-12 bg-rb-blue-50 rounded-xl gap-8 flex-col mb-12">
      <div className="grow shrink basis-0 gap-6 flex flex-col lg:flex-row lg:items-center">
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
          <div className="flex items-center gap-x-2 md:text-[28px] text-[26px] polysans">
            Get membership access for free
            <DiamondIcon className="w-6 h-6 shrink-0" />
          </div>
          <div className="self-stretch text-base font-normal leading-relaxed">
            Get unlimited access to artifacts, guides, on-demand courses and more with our{' '}
            {FREE_TRIAL_DAYS}-day free trial.
          </div>
        </div>
        <Button
          onClick={onCtaClick}
          variant="fill"
          color="premium"
          size="medium"
          className="font-semibold text-rb-white"
          href="/try-reforge"
        >
          {CTA_COPY}
        </Button>
      </div>
    </div>
  )
}

export default FreeTrialBanner
