import { useState } from 'react'

import CreateAccountForm from 'domains/CreateAccountForm/CreateAccountForm'

import Loading from 'components/Loading'
import ProfileCard from 'components/ProfileCard/ProfileCard'

import { useCurrentUser } from 'hooks/useCurrentUser'

const CreateAccountPageContainer = () => {
  const { currentUser } = useCurrentUser()

  const assembledName = `${currentUser?.firstName || ''} ${
    currentUser?.lastName || ''
  }`.trim()
  const isDefaultAvatar =
    currentUser?.profile?.avatarUrl?.includes('default_avatar') || false
  const avatarUrl = isDefaultAvatar ? null : currentUser?.profile?.avatarUrl || null

  const [profileCardData, setProfileCardData] = useState<ProfileCardData>({
    fullName: currentUser?.fullName || assembledName || null,
    avatarUrl: avatarUrl,
    role: null,
    companyName: null,
    isTransitioning: false,
    companyLogoUrl: null,
    jobFunction: null
  })
  if (!currentUser) return <Loading />

  // navigate user to either artifacts or membership home
  const handleNavigation = () => {
    const queryParams = new URLSearchParams(window.location.search)
    let redirect = queryParams.get('referer') || queryParams.get('fwd')
    const teamJoinParam = queryParams.get('joined-team')

    if (redirect) {
      if (teamJoinParam) {
        redirect += redirect.includes('?') ? '&joined-team=true' : '?joined-team=true'
      }
      window.location.assign(`/?redirect=${encodeURIComponent(redirect)}`)
    } else {
      if (
        currentUser.is.banned ||
        currentUser.is.planManager ||
        currentUser.is.noAccess ||
        currentUser.is.copyHold
      ) {
        window.location.assign('/account')
        return
      }

      window.location.assign(`/${teamJoinParam ? '?joined-team=true' : ''}`)
    }
  }

  const userCanAccess = currentUser.is.accountCreated

  if (!userCanAccess) {
    handleNavigation()
    return null
  }

  return (
    <CreateAccountPage
      profileCardData={profileCardData}
      setProfileCardData={setProfileCardData}
    />
  )
}

export interface ProfileCardData {
  fullName: string | null
  avatarUrl: string | null
  role: string | null
  companyLogoUrl: string | null
  companyName: string | null
  isTransitioning: boolean
  jobFunction: string | null
}

interface CreateAccountPageProps {
  profileCardData: ProfileCardData
  setProfileCardData: (profileCardData: ProfileCardData) => void
}

export const CreateAccountPage = ({
  profileCardData,
  setProfileCardData
}: CreateAccountPageProps) => {
  return (
    <div className="grid h-screen lg:grid-cols-2">
      <div className="flex flex-col justify-center justify-self-center px-4 pt-16 pb-10 md:px-16 md:pb-8 lg:max-w-[800px]">
        <h1 data-test="apply-page-heading" className="text-4xl font-semibold">
          Personalize your experience
        </h1>
        <p className="mb-12">
          Let us know who you are so we can tailor our recommendations to what you’re
          working on.
        </p>
        <CreateAccountForm
          profileCardData={profileCardData}
          setProfileCardData={setProfileCardData}
        />
      </div>
      <div className="hidden items-center justify-center bg-rb-orange-25 lg:flex lg:flex-1">
        <ProfileCard {...profileCardData} />
      </div>
    </div>
  )
}
export default CreateAccountPageContainer
