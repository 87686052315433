import DashboardBookmark from 'domains/Bookmarks/Bookmark'
import ProgramCard from 'domains/Program/ProgramCard'

import { CardVariants } from 'components/cards/Content/BaseCard'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import {
  BookmarkType,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment,
  useUserCohortsQuery
} from 'gql'

import ExternalUrlCard from './ExternalUrlCard'
import TextBlockCard from './TextBlockCard'

interface ItemListProps {
  bookmarks: ProgramBookmarkPartsFragment[]
  currentUser: CurrentUserPartsFragment
}

const ItemList = ({ bookmarks, currentUser }: ItemListProps) => {
  const isWideContentCard = (bookmark: ProgramBookmarkPartsFragment) => {
    return (
      bookmark.type === BookmarkType.CONCEPTBOOKMARK ||
      bookmark.type === BookmarkType.PROJECTBOOKMARK ||
      bookmark.type === BookmarkType.LESSONBOOKMARK ||
      bookmark.type === BookmarkType.SECTIONBOOKMARK ||
      bookmark.type === BookmarkType.UNITBOOKMARK ||
      bookmark.type === BookmarkType.RESOURCEBOOKMARK
    )
  }
  const isProgramCard = (bookmark: ProgramBookmarkPartsFragment) => {
    return (
      bookmark.type === BookmarkType.PROGRAMBOOKMARK &&
      !(bookmark.content && isWideContentCard(bookmark))
    )
  }
  const isDashboardCard = (bookmark: ProgramBookmarkPartsFragment) => {
    return (
      bookmark.type !== BookmarkType.PROGRAMBOOKMARK &&
      bookmark.type !== BookmarkType.TEXTBLOCKBOOKMARK &&
      bookmark.type !== BookmarkType.EXTERNALURLBOOKMARK &&
      !(bookmark.content && isWideContentCard(bookmark))
    )
  }
  const { data: userCohortsData } = useUserCohortsQuery()

  return (
    <div className="flex flex-col gap-8">
      {bookmarks.map((bookmark: ProgramBookmarkPartsFragment, idx) => (
        <div key={`collection_preview_${idx}`}>
          {bookmark.content && isWideContentCard(bookmark) && (
            <LegacyContentCard
              variant={CardVariants.Horizontal}
              content={bookmark.content}
              inNewTab
              bookmark={bookmark}
            />
          )}
          {isDashboardCard(bookmark) && (
            <DashboardBookmark
              bookmark={bookmark}
              inNewTab={true}
              onEdit={() => {}}
              restoreBookmark={() => {}}
              showProgramInBreadcrumb={!false} // update this
              reforgeCollection={true}
              hideUpdatedAgo={true}
            />
          )}
          {isProgramCard(bookmark) && bookmark.cmsProgram && (
            <ProgramCard
              currentUser={currentUser}
              userCohorts={userCohortsData?.currentUser?.cohorts}
              program={bookmark.cmsProgram}
              sourceFlow={''}
              inNewTab={true}
              showEnrolledBadge={false}
              bookmark={bookmark}
              reforgeCollection={true}
            />
          )}
          {bookmark.type === BookmarkType.TEXTBLOCKBOOKMARK && (
            <TextBlockCard bookmark={bookmark} clickable={false} />
          )}
          {bookmark.type === BookmarkType.EXTERNALURLBOOKMARK && (
            <ExternalUrlCard bookmark={bookmark} />
          )}
        </div>
      ))}
    </div>
  )
}

export default ItemList
