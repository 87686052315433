import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'

type DropdownMultiSelectProps = {
  className?: string
  dropdownClassName?: string
  containerClassName?: string
  displayText: string
  onSelection?: (value: string[]) => void
  selectedItems?: string[]
  data: {
    label: string
    value: any
  }[]
}

export default function DropdownMultiSelect({
  data,
  displayText,
  containerClassName,
  className,
  dropdownClassName,
  onSelection = () => {},
  selectedItems = []
}: DropdownMultiSelectProps) {
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    setSelected(selectedItems)
  }, [selectedItems])

  function handleSelection(data: string[]) {
    onSelection(data)
    setSelected(data)
  }

  const UncheckedBox = (
    <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
  )
  const CheckedBox = (
    <SVGIcon name="checkbox-ticked" fill="#1A6C69" width="20" height="20" />
  )
  return (
    <Listbox value={selected} onChange={handleSelection} multiple>
      {({ open }) => (
        <div className={twMerge('relative', containerClassName)}>
          <Listbox.Button
            className={twMerge(
              'relative border border-solid border-rb-gray-100 bg-white px-3 py-[10px] text-left font-sans text-sm font-normal',
              className,
              (selected.length > 0 || open) && 'border-rb-gray-200'
            )}
          >
            <span
              className={twMerge(
                'block truncate text-rb-gray-300',
                selected.length > 0 && 'text-rb-black'
              )}
            >
              {selected.length === 0
                ? displayText
                : `${displayText} (${selected.length})`}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              {open ? (
                <ChevronUpIcon className="h-5 w-5 text-rb-black" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-rb-black" aria-hidden="true" />
              )}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={twMerge(
                'absolute z-3 mt-1 h-fit list-none overflow-auto rounded-md bg-white px-[5px] py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm',
                dropdownClassName
              )}
            >
              {data?.length &&
                data.map((obj) => (
                  <Listbox.Option
                    key={obj.label}
                    className="relative mb-[6px] cursor-default select-none py-1 px-[10px]"
                    value={obj.value}
                  >
                    {({ selected }) => (
                      <div
                        className="flex cursor-pointer items-center"
                        role="checkbox"
                        tabIndex={0}
                        aria-checked={selected}
                      >
                        <span className="mr-2">
                          {selected ? CheckedBox : UncheckedBox}
                        </span>
                        <div className="text-rb-gray-400">{obj.label}</div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
