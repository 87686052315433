import { PortableText } from '@portabletext/react'
import { useMemo } from 'react'

import { Video } from '../shared/Video'
import { ComplexPortableTextBlockValueProp } from '../types'
import block from './componentTypes/Blocks'
import list from './componentTypes/Lists'
import marks from './componentTypes/Marks'
import generateTypes from './componentTypes/Types'

interface UnitComplexPortableTextProps {
  content: any
  forcePhotoLoad?: boolean
  trackVideoPlay?: () => void
}

export const UnitComplexPortableText = ({
  content,
  forcePhotoLoad = false,
  trackVideoPlay
}: UnitComplexPortableTextProps) => {
  const components = useMemo(
    () => ({
      block,
      list,
      marks,
      types: {
        ...generateTypes(forcePhotoLoad),
        muxVideo: ({ value }: ComplexPortableTextBlockValueProp) => (
          <Video value={value} onPlay={trackVideoPlay} />
        )
      }
    }),
    [forcePhotoLoad, trackVideoPlay]
  )

  return <PortableText value={content} components={components} />
}
