import { useBlock } from '@dopt/react'
import { useEffect } from 'react'

import AnnouncementBanner from 'components/dopt/AnnouncementBanner'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

const pushDoptComponent = (
  activeDoptComponents: any,
  blockArray: any,
  identifier: string
) => {
  const [block, transition] = blockArray
  if (block.state.active) {
    activeDoptComponents[identifier] = [block, transition]
  }
}

const AnnouncementWrapper = () => {
  const currentUser = useAssertCurrentUser()
  // Add dopt campaigns to activeDoptComponents
  const activeDoptComponents = {}

  const blockArray1 = useBlock<['complete']>('announcement-banner-1.banner')
  pushDoptComponent(activeDoptComponents, blockArray1, 'announcement-banner-1.banner')
  const blockArray1v2 = useBlock<['complete']>('announcement-banner-1.banner-v2')
  pushDoptComponent(
    activeDoptComponents,
    blockArray1v2,
    'announcement-banner-1.banner-v2'
  )

  const blockArray2 = useBlock<['complete']>('announcement-banner-2.banner')
  pushDoptComponent(activeDoptComponents, blockArray2, 'announcement-banner-2.banner')
  const blockArray2v2 = useBlock<['complete']>('announcement-banner-2.banner-v2')
  pushDoptComponent(
    activeDoptComponents,
    blockArray2v2,
    'announcement-banner-2.banner-v2'
  )

  const blockArray3 = useBlock<['complete']>('announcement-banner-3.banner')
  pushDoptComponent(activeDoptComponents, blockArray3, 'announcement-banner-3.banner')
  const blockArray3v2 = useBlock<['complete']>('announcement-banner-3.banner-v2')
  pushDoptComponent(
    activeDoptComponents,
    blockArray3v2,
    'announcement-banner-3.banner-v2'
  )

  const blockArray4 = useBlock<['complete']>('slack-announcement-banner.slack-banner')

  if (!currentUser.is.trialing) {
    pushDoptComponent(
      activeDoptComponents,
      blockArray4,
      'slack-announcement-banner.slack-banner'
    )
  }
  const blockArray4v2 = useBlock<['complete']>(
    'slack-announcement-banner.slack-banner-v2'
  )

  if (!currentUser.is.trialing) {
    pushDoptComponent(
      activeDoptComponents,
      blockArray4v2,
      'slack-announcement-banner.slack-banner-v2'
    )
  }

  useEffect(() => {
    if (currentUser?.slackUserId) {
      const transition = blockArray4[1]
      transition('complete')
    }
  }, [currentUser?.slackUserId, blockArray4])

  return <AnnouncementBanner activeDoptComponents={activeDoptComponents} />
}

export default AnnouncementWrapper
