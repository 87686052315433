import { useHistory } from 'react-router-dom'

import { onEnterKeyPress } from 'utils/keyboard'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const CourseItem = ({
  searchDocument,
  searchQuery,
  onResultClick
}: ContentResultItemProps) => {
  const history = useHistory()
  const { author, highlight, title, thumbnail, url } = searchDocument

  const handleClick = () => {
    onResultClick()
    history.push(url)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex h-56 rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      onClick={handleClick}
      onKeyUp={onEnterKeyPress(handleClick)}
    >
      <div className="flex w-full flex-col gap-y-2 pt-2">
        <h1
          className="mb-0 text-ellipsis text-[20px] font-medium leading-[24px] text-rb-gray-500 line-clamp-2"
          dangerouslySetInnerHTML={{
            __html: formatTitle(searchQuery, title, 200)
          }}
        />

        {author && (
          <p
            className="m-0 text-sm text-rb-gray-400"
            dangerouslySetInnerHTML={{
              __html: formatTitle(searchQuery, `Created by ${author}`, 250)
            }}
          />
        )}

        {highlight && (
          <p
            className="m-0 text-ellipsis p-0 text-[13px] leading-[19.5px] text-rb-gray-300 line-clamp-2 sm:text-[14px] sm:leading-[21px]"
            dangerouslySetInnerHTML={{
              __html: formatTitle(searchQuery, highlight, 250)
            }}
          />
        )}
      </div>

      <div className="relative hidden h-full w-60 flex-none pl-6 sm:block">
        {thumbnail && (
          <img src={thumbnail} className="h-full w-full rounded-xl object-cover" alt="" />
        )}
      </div>
    </div>
  )
}

export default CourseItem
