import {
  MAX_WIDTH_TAILWIND_SM,
  MAX_WIDTH_TAILWIND_TL,
  MIN_WIDTH_TAILWIND_SM
} from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

const useRelatedGuidesCarouselSlidesCount = () => {
  const thirdSlideMobile = useMediaQuery(
    `(min-width: ${MIN_WIDTH_TAILWIND_SM}) and (max-width: ${MAX_WIDTH_TAILWIND_SM})`
  )

  const thirdSlideTablet = useMediaQuery(
    `(min-width: 784px) and (max-width: ${MAX_WIDTH_TAILWIND_TL})`
  )
  const thirdSlideDesktop = useMediaQuery('(min-width: 1136px)')

  return [thirdSlideMobile, thirdSlideTablet, thirdSlideDesktop, true, true].filter(
    (slide) => slide
  ).length
}

export default useRelatedGuidesCarouselSlidesCount
