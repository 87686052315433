import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Loading } from 'components'

const JoinTeamPage = () => {
  const { subscriptionExternalId } = useParams<{ subscriptionExternalId: string }>()

  useEffect(() => {
    window.location.href = `/?subscription_external_id=${subscriptionExternalId}`
  }, [subscriptionExternalId])

  return <Loading />
}

export default JoinTeamPage
