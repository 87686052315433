import { useHistory, useLocation } from 'react-router-dom'

import { ARTIFACTS_INDEX_MODES } from './constants'
import { ArtifactsIndexMode } from './types'

export function getArtifactIndexModeFromLocation(
  location: ReturnType<typeof useLocation>
): ArtifactsIndexMode {
  const pathnameWithSearch = location.pathname + location.search

  switch (true) {
    case pathnameWithSearch.includes('/artifacts?topics='):
      return ARTIFACTS_INDEX_MODES.INDEX_WITH_TOPIC

    case pathnameWithSearch.includes('/artifacts?subtopics='):
      return ARTIFACTS_INDEX_MODES.INDEX_WITH_SUBTOPIC

    case pathnameWithSearch.includes('/artifacts?for-you'):
      return ARTIFACTS_INDEX_MODES.FOR_YOU

    case pathnameWithSearch.includes('/artifacts?recently-viewed'):
      return ARTIFACTS_INDEX_MODES.RECENTLY_VIEWED

    default:
      return ARTIFACTS_INDEX_MODES.INDEX
  }
}

export const sortMapping: Record<string, string> = {
  'default': 'date added',
  'most-popular': 'most popular'
}

export const topicMapping: Record<string, string> = {
  'Topics': 'topics',
  'Functions': 'functions',
  'Company size': 'company_size',
  'Company stage': 'company_stage',
  'Business model': 'business_model'
}

export function removeLastIdParam(history: ReturnType<typeof useHistory>) {
  const searchParams = new URLSearchParams(location.search)

  if (searchParams.has('last-id')) {
    searchParams.delete('last-id')

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    })
  }
}
