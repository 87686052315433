import React from 'react'

import {
  COURSE_FILTER_LIVE,
  COURSE_FILTER_ON_DEMAND
} from 'pages/CoursesPage/CoursesFilters'

import Image from 'domains/Sanity/Image'

import { FacePile } from 'components/FacePile'

import {
  ArtifactAuthor,
  BookmarkFolderPartsFragment,
  CourseSnapshotMarketingCoursesPartsFragment,
  CoursesPageCurrentUserPartsFragment,
  Creator,
  HostLegacyCourseCardPartsFragment,
  MarketingCourseCourseCardPartsFragment,
  MyCoursesCurrentUserPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { prettyStartDateFromMarketingCourse } from 'utils/courseUtils'
import { listify } from 'utils/stringUtils'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export const MARKETPLACE_COURSE_TYPE_LEGACY = 'legacy'
export const MARKETPLACE_COURSE_TYPE_MARKETPLACE = 'marketplace'

export const COURSE_CARD_DESTINATION_TYPE_COURSE = 'courseDetails'
export const COURSE_CARD_DESTINATION_TYPE_PROGRAM = 'programDetails'
export const COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD = 'onDemandDashboard'

export const courseFilterToCourseCardDestinationType = (
  isAvailableOnDemand: boolean,
  courseFilter: typeof COURSE_FILTER_LIVE | typeof COURSE_FILTER_ON_DEMAND
) => {
  if (courseFilter === COURSE_FILTER_LIVE) {
    return COURSE_CARD_DESTINATION_TYPE_COURSE
  }
  return isAvailableOnDemand
    ? COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
    : COURSE_CARD_DESTINATION_TYPE_PROGRAM
}

export interface LegacyCourseCardProps {
  course:
    | CourseSnapshotMarketingCoursesPartsFragment
    | MarketingCourseCourseCardPartsFragment
  variant?: CardVariant
  user?: CoursesPageCurrentUserPartsFragment | MyCoursesCurrentUserPartsFragment | null
  cardType: string
  showStartDate?: boolean
  legacyCourseSessionId?: string | number
  showHostInThumbnail?: boolean
  showHostInByline?: boolean
  courseFooterOverrideText?: string
  progressPercent?: number
  CourseFooterOverride?: React.ComponentType<any>
  customThumbnail?: React.ReactNode
  destinationType:
    | typeof COURSE_CARD_DESTINATION_TYPE_COURSE
    | typeof COURSE_CARD_DESTINATION_TYPE_PROGRAM
    | typeof COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
  inNewTab?: boolean
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  // Bookmark-related props
  bookmark?: ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  locationType?: string
  customHoverMiniCard?: boolean
}

// NOTE: Do NOT use this component without talking to #pod-courses-eng, as we are moving away from relying on the MarketingCourse
// Instead, we will replace the usage of this with Content/CourseCard component
const LegacyCourseCard = ({
  course,
  variant = CardVariants.Vertical,
  user,
  cardType,
  pageLocation = 'courses_index',
  courseFooterOverrideText,
  showHostInThumbnail = false,
  showHostInByline = false,
  destinationType,
  showStartDate,
  legacyCourseSessionId,
  progressPercent,
  CourseFooterOverride,
  customThumbnail,
  inNewTab,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  hideBookmarkButton = false,
  additionalRelatedIdentifiers,
  locationType = 'index',
  customHoverMiniCard = false
}: LegacyCourseCardProps) => {
  const { isLoggedIn } = useCurrentUser()

  if (!course) {
    return null
  }

  const courseId =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY ? course.id : course.course?.id

  const title =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course.title
      : course.course?.title
  const slug =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course.slug
      : course.course?.slug
  const description =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course.subtitle
      : course.course?.shortDescription

  const programSlug = course.cmsProgramSlug

  const destination = () => {
    if (
      destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE &&
      legacyCourseSessionId &&
      isLoggedIn
    ) {
      let destinationUrl = ''
      destinationUrl = `/courses/${slug}/sessions/${legacyCourseSessionId}`

      return destinationUrl
    } else if (destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE) {
      let destinationUrl = ''
      destinationUrl = `/courses/${slug}`

      if (isLoggedIn) {
        destinationUrl += '/details'
      }

      return destinationUrl
    } else if (destinationType === COURSE_CARD_DESTINATION_TYPE_PROGRAM) {
      if (isLoggedIn && !!user?.is?.member) {
        return `/programs/${programSlug}`
      }

      if (isLoggedIn && !user?.is?.member) {
        return `/programs/${programSlug}/preview`
      }

      if (!isLoggedIn || !user?.is?.member) {
        return `/courses/${slug}`
      }
    } else if (destinationType === COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD) {
      if (isLoggedIn && !!user?.is?.member) {
        return `/courses/${slug}/on-demand`
      } else if (isLoggedIn && !user?.is?.member) {
        return `/courses/${slug}/details`
      } else {
        return `/courses/${slug}`
      }
    }

    return '/courses' // Fallback
  }

  const destinationUrl = destination()
  const relatedIdentifiers = {
    course_id: courseId,
    course_title: title,
    // TODO: The following should be updated with the upcoming course page redesign work
    is_featured: false,
    is_filtered_reference: false,
    is_empty_index_results: false,
    course_session_id: null,
    content_mode: cardType,
    ...additionalRelatedIdentifiers
  }

  const handleTracking = () => {
    trackNavigationClicked({
      destination: destinationUrl,
      location: pageLocation,
      location_type: locationType,
      type: `${variant}_card`, // horizontal_card, vertical_card, mini_card, list_card
      related_identifiers: relatedIdentifiers
    })
  }

  const FooterComponent = CourseFooterOverride ? (
    <CourseFooterOverride />
  ) : (
    <CourseFooter
      course={course}
      showStartDate={showStartDate}
      progressPercent={progressPercent}
      overrideText={courseFooterOverrideText}
    />
  )

  return (
    <BaseCard
      contentType={
        course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY ? 'Program' : 'Course'
      }
      variant={variant}
      title={title}
      cmsProgramId={
        course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
          ? course.legacyProgramId
          : null
      }
      courseSlug={course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY ? null : slug}
      byline={
        <CourseByline
          course={course}
          variant={variant}
          showHostInByline={showHostInByline}
        />
      }
      body={description}
      thumbnail={
        customThumbnail || (
          <Thumbnail
            course={course}
            cardType={cardType}
            showHostInThumbnail={showHostInThumbnail}
          />
        )
      }
      horizontalThumbnail={
        <CourseHorizontalThumbnail
          course={course}
          cardType={cardType}
          showHostInThumbnail={showHostInThumbnail}
        />
      }
      verticalThumbnail={
        <CourseVerticalThumbnail
          course={course}
          cardType={cardType}
          showHostInThumbnail={showHostInThumbnail}
        />
      }
      footer={FooterComponent}
      destination={destinationUrl}
      inNewTab={inNewTab}
      trackCardClick={handleTracking}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
      customHoverMiniCard={customHoverMiniCard}
    />
  )
}

const isCreator = (item: Creator | ArtifactAuthor): item is Creator => {
  return (item as Creator).expert !== undefined
}

const CourseByline = ({
  course,
  variant,
  showHostInByline
}: {
  showHostInByline: boolean
  course:
    | MarketingCourseCourseCardPartsFragment
    | CourseSnapshotMarketingCoursesPartsFragment
  variant?: CardVariant
}) => {
  const hosts: HostLegacyCourseCardPartsFragment[] = course.hosts || []
  const creators: Creator[] | ArtifactAuthor[] =
    course.creators || course.course?.creators || []

  const expertNames = showHostInByline
    ? hosts.map((host) => host.hostName || '')
    : creators.map((creator) =>
        isCreator(creator) ? creator.expert?.name || '' : creator?.name || ''
      )

  const expertFacepileUsers = showHostInByline
    ? hosts.map((host) => ({
        id: host.id,
        avatarUrl: host.hostPicture?.imageUrl || ''
      }))
    : creators.map((creator) =>
        isCreator(creator)
          ? {
              id: creator.expert?.id || '',
              avatarUrl: creator.expert?.avatarPhoto?.imageUrl || ''
            }
          : {
              id: creator.id,
              avatarUrl: creator.avatarPhoto?.imageUrl || ''
            }
      )

  if (hosts.length > 0 || creators.length > 0) {
    if (variant === CardVariants.List) {
      return (
        <div className="flex gap-2">
          <div className="flex shrink-0 grow-0">
            <FacePile users={expertFacepileUsers} imageSize="small" />
          </div>
          <div className="text-ellipsis line-clamp-1">{listify(expertNames)}</div>
        </div>
      )
    } else {
      const bylineAction = showHostInByline ? 'Hosted' : 'Created'
      return (
        <span>
          {bylineAction} by {listify(expertNames)}
        </span>
      )
    }
  }

  return null
}

const CourseFooter = ({
  course,
  showStartDate,
  progressPercent,
  overrideText
}: {
  course:
    | MarketingCourseCourseCardPartsFragment
    | CourseSnapshotMarketingCoursesPartsFragment
  showStartDate?: boolean
  progressPercent?: number | string
  overrideText?: string
}) => {
  if (overrideText) {
    return <span className="font-semibold">{overrideText}</span>
  }

  if (progressPercent) {
    return <span className="font-semibold">{progressPercent}% Complete</span>
  }

  const showStartsAt = !!(
    course.courseType === MARKETPLACE_COURSE_TYPE_MARKETPLACE &&
    course.startDate &&
    showStartDate
  )

  const startsDate = showStartsAt && prettyStartDateFromMarketingCourse(course)

  if (!startsDate && !course.duration) return null

  return (
    <div>
      {startsDate && <span className="font-semibold">Starts {startsDate}</span>}
      {startsDate && course.duration && <span className="px-1">·</span>}
      {course.duration && <span>{course.duration}</span>}
    </div>
  )
}

const CourseVerticalThumbnail = ({
  course,
  cardType,
  showHostInThumbnail
}: {
  course:
    | MarketingCourseCourseCardPartsFragment
    | CourseSnapshotMarketingCoursesPartsFragment
  cardType: string
  showHostInThumbnail?: boolean
}) => {
  const hosts = showHostInThumbnail
    ? course?.hosts?.map((host) => ({
        url: host.hostPicture?.imageUrl || '',
        name: host.hostName || ''
      })) || []
    : []

  const creators =
    cardType === COURSE_FILTER_ON_DEMAND && !showHostInThumbnail
      ? course.creators?.map((creator) => {
          return {
            url: creator?.expert?.avatarPhoto?.imageUrl || '',
            name: creator?.expert?.name || ''
          }
        }) || []
      : course.course?.creators?.map((courseCreator) => {
          return {
            url: courseCreator?.avatarPhoto?.imageUrl || '',
            name: courseCreator?.name || ''
          }
        }) || []

  const experts = showHostInThumbnail ? hosts : creators

  if (experts.length === 0) return null

  if (experts.length <= 2) {
    return (
      <div className="flex h-full gap-x-2 px-4 pt-4">
        {experts.map((expert, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={expert.url}
              alt={expert.name}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="h-full px-4 pt-4">
      <div className="relative flex h-full gap-x-2 overflow-hidden">
        {creators.length > 3 && (
          <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
            +{creators.length - 3}
          </div>
        )}
        {creators.slice(0, 3).map((creator, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(33.3%-5.3px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={creator.url}
              alt={creator.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const CourseHorizontalThumbnail = ({
  course,
  cardType,
  showHostInThumbnail
}: {
  course:
    | MarketingCourseCourseCardPartsFragment
    | CourseSnapshotMarketingCoursesPartsFragment
  cardType: string
  showHostInThumbnail?: boolean
}) => {
  const hosts = showHostInThumbnail
    ? course?.hosts?.map((host) => ({
        url: host.hostPicture?.imageUrl || '',
        name: host.hostName || ''
      })) || []
    : []

  const creators =
    cardType === COURSE_FILTER_ON_DEMAND && !showHostInThumbnail
      ? course.creators?.map((creator) => {
          return {
            url: creator?.expert?.avatarPhoto?.imageUrl || '',
            name: creator?.expert?.name || ''
          }
        }) || []
      : course.course?.creators?.map((courseCreator) => {
          return {
            url: courseCreator?.avatarPhoto?.imageUrl || '',
            name: courseCreator?.name || ''
          }
        }) || []

  const experts = showHostInThumbnail ? hosts : creators

  if (experts.length === 0) return null

  if (experts.length === 1) {
    return (
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={experts[0].url}
        alt={experts[0].name}
      />
    )
  }
  return (
    <div className="relative flex h-full gap-x-2">
      {experts.length > 2 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{experts.length - 2}
        </div>
      )}
      {experts.slice(0, 2).map((expert, idx) => (
        <div
          key={`card-thumbnail-${idx}`}
          className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
        >
          <Image
            className="h-full w-full rounded-xl object-cover"
            src={expert.url}
            alt={expert.name}
          />
        </div>
      ))}
    </div>
  )
}

const Thumbnail = ({
  course,
  cardType,
  showHostInThumbnail
}: {
  course:
    | MarketingCourseCourseCardPartsFragment
    | CourseSnapshotMarketingCoursesPartsFragment
  cardType: string
  showHostInThumbnail?: boolean
}) => {
  const hosts = showHostInThumbnail
    ? course?.hosts?.map((host) => ({
        url: host.hostPicture?.imageUrl || '',
        name: host.hostName || ''
      })) || []
    : []

  const creators =
    cardType === COURSE_FILTER_ON_DEMAND && !showHostInThumbnail
      ? course.creators?.map((creator) => {
          return {
            url: creator?.expert?.avatarPhoto?.imageUrl || '',
            name: creator?.expert?.name || ''
          }
        }) || []
      : course.course?.creators?.map((courseCreator) => {
          return {
            url: courseCreator?.avatarPhoto?.imageUrl || '',
            name: courseCreator?.name || ''
          }
        }) || []

  const experts = showHostInThumbnail ? hosts : creators

  if (experts.length === 0) return null

  return (
    <div className="relative">
      {experts.length > 1 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{experts.length - 1}
        </div>
      )}
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={experts[0].url}
        alt={experts[0].name}
      />
    </div>
  )
}

export default LegacyCourseCard
