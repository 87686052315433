type getRefererUrlOptions = {
  purchaseSuccessful?: boolean
}

export const getRefererUrl = (
  referer?: string | null,
  options?: getRefererUrlOptions
) => {
  if (!referer) {
    return null
  }

  if (referer?.includes('course-redirect-')) {
    const courseSlug = referer.replace('course-redirect-', '')
    return options?.purchaseSuccessful
      ? `/courses/${courseSlug}/on-demand`
      : `/courses/${courseSlug}/details`
  }

  if (referer?.includes('trial-redirect')) {
    return options?.purchaseSuccessful ? '/' : '/try-reforge'
  }

  return referer
}
