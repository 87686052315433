import { formatInTimeZone } from 'date-fns-tz'

import Image from 'domains/Sanity/Image'

import { FacePile } from 'components/FacePile'

import { LegacyCohortCardCohortPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { listify } from 'utils/stringUtils'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export interface LegacyCohortCardProps {
  cohort: LegacyCohortCardCohortPartsFragment
  variant?: CardVariant
  inNewTab?: boolean
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  locationType?: string
}

const LegacyCohortCard = ({
  cohort,
  variant = CardVariants.Vertical,
  pageLocation = 'courses_index',
  inNewTab,
  locationType = 'index'
}: LegacyCohortCardProps) => {
  const { currentUser } = useCurrentUser()

  const destinationUrl = `/cohorts/${cohort.slug}`

  const handleTracking = () => {
    trackNavigationClicked({
      destination: destinationUrl,
      location: pageLocation,
      location_type: locationType,
      type: `${variant}_card`, // horizontal_card, vertical_card, mini_card, list_card
      related_identifiers: {
        cohort_id: cohort.id
      }
    })
  }

  return (
    <BaseCard
      contentType="Program"
      variant={variant}
      title={cohort.cmsProgram.name}
      cmsProgramId={cohort.cmsProgram.id}
      byline={<CohortByline cohort={cohort} variant={variant} />}
      body={cohort.cmsProgram.synopsis}
      thumbnail={<Thumbnail cohort={cohort} />}
      horizontalThumbnail={<CohortHorizontalThumbnail cohort={cohort} />}
      verticalThumbnail={<CohortVerticalThumbnail cohort={cohort} />}
      footer={<CohortFooter cohort={cohort} timezone={currentUser?.timezone || ''} />}
      destination={destinationUrl}
      inNewTab={inNewTab}
      trackCardClick={handleTracking}
      hideBookmarkButton
    />
  )
}

const CohortByline = ({
  cohort,
  variant
}: {
  cohort: LegacyCohortCardCohortPartsFragment
  variant?: CardVariant
}) => {
  const hosts = cohort.hosts

  const hostNames = hosts?.map((host) => host?.name || '') || []

  const hostFacepileUsers =
    hosts?.map((host) => ({
      id: host.id,
      avatarUrl: host.thumbnailUrl
    })) || []

  if (hosts && hosts.length > 0) {
    if (variant === CardVariants.List) {
      return (
        <div className="flex gap-2">
          <div className="flex shrink-0 grow-0">
            <FacePile users={hostFacepileUsers} imageSize="small" />
          </div>
          <div className="text-ellipsis line-clamp-1">{listify(hostNames)}</div>
        </div>
      )
    } else {
      return <span>Hosted by {listify(hostNames)}</span>
    }
  }

  hostNames.length > 0 ? '' : null
  return null
}

const CohortFooter = ({
  cohort,
  timezone
}: {
  cohort: LegacyCohortCardCohortPartsFragment
  timezone?: string
}) => {
  const startsAt = cohort.startsAt
  const endsAt = cohort.endsAt

  if (!startsAt && !endsAt) {
    return null
  }

  const formattedStartDate =
    timezone && startsAt && formatInTimeZone(startsAt, timezone, 'MMM d')
  const formattedEndDate =
    timezone && endsAt && formatInTimeZone(endsAt, timezone, 'MMM d, yyyy')

  return (
    <span className="font-semibold">
      {formattedStartDate} - {formattedEndDate}
    </span>
  )
}

const CohortVerticalThumbnail = ({
  cohort
}: {
  cohort: LegacyCohortCardCohortPartsFragment
}) => {
  const hosts = cohort.hosts || []
  if (hosts.length === 0) return null

  if (hosts.length <= 2) {
    return (
      <div className="flex h-full gap-x-2 px-4 pt-4">
        {hosts.map((host, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={host.thumbnailUrl}
              alt={host.name || ''}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="h-full px-4 pt-4">
      <div className="relative flex h-full gap-x-2 overflow-hidden">
        {hosts.length > 3 && (
          <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
            +{hosts.length - 3}
          </div>
        )}
        {hosts.slice(0, 3).map((host, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(33.3%-5.3px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={host.thumbnailUrl}
              alt={host.name || ''}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const CohortHorizontalThumbnail = ({
  cohort
}: {
  cohort: LegacyCohortCardCohortPartsFragment
}) => {
  const hosts = cohort.hosts || []

  if (hosts.length === 0) return null

  if (hosts.length === 1) {
    return (
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={hosts[0].thumbnailUrl}
        alt={hosts[0].name || ''}
      />
    )
  }
  return (
    <div className="relative flex h-full gap-x-2">
      {hosts.length > 2 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{hosts.length - 2}
        </div>
      )}
      {hosts.slice(0, 2).map((host, idx) => (
        <div
          key={`card-thumbnail-${idx}`}
          className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
        >
          <Image
            className="h-full w-full rounded-xl object-cover"
            src={host.thumbnailUrl}
            alt={host.name || ''}
          />
        </div>
      ))}
    </div>
  )
}

const Thumbnail = ({ cohort }: { cohort: LegacyCohortCardCohortPartsFragment }) => {
  const hosts = cohort.hosts || []

  if (hosts.length === 0) return null

  return (
    <div className="relative">
      {hosts.length > 1 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{hosts.length - 1}
        </div>
      )}
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={hosts[0].thumbnailUrl}
        alt={hosts[0].name || ''}
      />
    </div>
  )
}

export default LegacyCohortCard
