import { format } from 'date-fns'
import PropTypes from 'prop-types'
import * as React from 'react'

import { ErrorMessage } from 'components'

import {
  OpenInvoiceFieldsFragment,
  UserPaymentHistoryQuery,
  useUserPaymentHistoryQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

const BillingHistoryContainer = () => {
  const currentUser = useAssertCurrentUser()
  const { loading, error, data } = useUserPaymentHistoryQuery({
    variables: { id: currentUser.id }
  })

  if (loading) return null
  if (error) return <ErrorMessage error={error} />
  if (!data) return <ErrorMessage error="Data failed to load" />

  return <BillingHistory userPaymentHistory={data.user} />
}

const thClassName =
  'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 !text-xs !font-medium !text-rb-gray-300 !tracking-widest'
const tdClassName = 'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5'

const BillingHistory = ({
  userPaymentHistory
}: {
  userPaymentHistory: UserPaymentHistoryQuery['user']
}) => {
  const pastPayments = userPaymentHistory?.pastPayments
  const openInvoices = userPaymentHistory?.openInvoices

  if (!pastPayments?.length && !openInvoices?.length) return null

  const invoices = new Set()

  return (
    <div className="rf-rb-card p-8">
      <div className="flex justify-between">
        <h3 className="text-xl font-medium text-black">Billing History</h3>
      </div>
      <p className="tl:uk-margin-small-bottom -mb-2.5 text-sm text-rb-gray-300 tl:text-base">
        If you need assistance with an invoice please email <ReforgeContactEmail />.
      </p>
      <table className="uk-table uk-table-striped uk-table-small text-[13px] text-rb-gray-500 tl:text-sm">
        <thead>
          <tr>
            <th className={thClassName}>Date</th>
            <th className={thClassName}>Item</th>
            <th className={`${thClassName} uk-visible@s`}>Description</th>
            <th className={`${thClassName} uk-visible@s`}>Status</th>
            <th className={thClassName}>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {openInvoices && <BillingHistoryOpenInvoices openInvoices={openInvoices} />}

          {pastPayments?.map((pastPayment) => {
            if (invoices.has(pastPayment.invoiceId)) return

            invoices.add(pastPayment.invoiceId)

            return (
              <tr key={`pastPayment${pastPayment.id}`}>
                <td className={tdClassName}>
                  {format(
                    new Date(pastPayment.paidAt || pastPayment.createdAt),
                    'yyyy-MM-dd'
                  )}
                </td>
                <td className={tdClassName}>
                  {pastPayment.paymentType === 'Subscription'
                    ? 'Subscription'
                    : 'Program'}
                </td>
                <td className={`${tdClassName} uk-visible@s`}>
                  {pastPayment.description}
                </td>
                <td className={`${tdClassName} uk-visible@s`}>
                  {titleize(pastPayment.status)}
                </td>
                {pastPayment.invoiceId && (
                  <td className={tdClassName}>
                    <a href={`/invoices/${pastPayment.invoiceId}`}>View Invoice</a>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const ReforgeContactEmail = () => {
  const trackAndLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()

    trackCtaClicked({
      cta_location: 'billing history section',
      cta_type: 'hyperlink text',
      text: 'hello@reforge.com'
    })

    return (window.location.href = e?.currentTarget?.href)
  }

  return (
    <a href="mailto:hello@reforge.com?subject=Invoice Query" onClick={trackAndLink}>
      hello@reforge.com
    </a>
  )
}

const BillingHistoryOpenInvoices = ({
  openInvoices: invoices
}: {
  openInvoices: OpenInvoiceFieldsFragment[]
}) => {
  if (!invoices || !invoices.length) return null

  const handlePayNowLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()

    trackCtaClicked({
      cta_location: 'billing history',
      cta_type: 'hyperlink text',
      text: 'pay now'
    })

    return window.open(e.currentTarget.href, '_blank')
  }

  return (
    <>
      {invoices.map(
        ({ id, createdAt, item, description, status, dueDate, hostedInvoiceUrl }) => (
          <tr key={id}>
            <td className={tdClassName}> {format(new Date(createdAt), 'yyyy-MM-dd')}</td>
            <td className={tdClassName}>{item}</td>
            <td className={`${tdClassName} uk-visible@s`}>{description}</td>
            <td className={`${tdClassName} uk-visible@s`}>
              <div>{titleize(status === 'open' ? 'Unpaid' : status || '')}</div>
              <div className="text-xs">
                {dueDate ? `(Due ${format(new Date(dueDate), 'yyyy-MM-dd')})` : ''}
              </div>
            </td>
            <td className={tdClassName}>
              {hostedInvoiceUrl && (
                <a href={hostedInvoiceUrl} onClick={handlePayNowLinkClick}>
                  Pay Now
                </a>
              )}
            </td>
          </tr>
        )
      )}
    </>
  )
}

BillingHistoryOpenInvoices.propTypes = {
  openInvoices: PropTypes.array
}

BillingHistory.propTypes = {
  userPaymentHistory: PropTypes.object
}

export default BillingHistoryContainer
