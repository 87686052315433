import PropTypes from 'prop-types'
import { MouseEvent } from 'react'

import { SearchDocumentResultFragment } from 'gql'

import UserSearchItem from './UserSearchItem'

export interface SearchResultItemProps {
  document?: SearchDocumentResultFragment
  searchQuery: string
  searchPosition: number
  userCan: {
    linkToSearchResults?: boolean | null
    viewMemberProfile?: boolean | null
    viewPost?: boolean | null
  }
  handleResultClick: (
    e: MouseEvent<HTMLAnchorElement>,
    searchPosition: number,
    resultComponent?: string,
    type?: string,
    subtype?: string | null
  ) => void
}

const SearchResultItem = ({
  document,
  searchQuery,
  userCan,
  handleResultClick,
  searchPosition
}: SearchResultItemProps) => {
  if (!document) return null
  const { searchable } = document
  if (!searchable) return null

  const ItemComponent = () => {
    switch (searchable.__typename) {
      case 'User':
        return (
          <UserSearchItem
            document={{ ...document, searchable }}
            searchQuery={searchQuery}
            userCan={userCan}
            onResultClick={(e) =>
              handleResultClick(
                e,
                searchPosition,
                'UserSearchItem',
                searchable.__typename
              )
            }
          />
        )
      default:
        return <></>
    }
  }

  return (
    <li className="list-none">
      <ItemComponent />
    </li>
  )
}

SearchResultItem.propTypes = {
  document: PropTypes.object,
  searchQuery: PropTypes.string,
  searchPosition: PropTypes.number,
  userCan: PropTypes.object,
  handleResultClick: PropTypes.func
}

export default SearchResultItem
