import { useRef, useState } from 'react'

import { OrderRecapSectionWrapper } from 'pages/PurchaseFlow/OrderRecapSection'
import { usePurchaseFlowContext } from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import BillingAndPaymentForm from 'components/BillingAndPaymentForm'
import { BillingAndPaymentFormExposedMethods } from 'components/BillingAndPaymentForm/BillingAndPaymentForm'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { TwoColumnLayout } from './components/TwoColumnLayout'
import { NEW_USER_PURCHASE_FLOW_TRACKING_LOCATION } from './constants'

export const PaymentDetailsPage = () => {
  const billingAndPaymentFormRef = useRef<BillingAndPaymentFormExposedMethods>(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [saveEnabled, setSaveEnabled] = useState(false)

  const { goToNextStep, setError } = usePurchaseFlowContext()

  const onNextButtonClick = async () => {
    setError(null)
    const success = await billingAndPaymentFormRef.current?.saveBillingAndPayment()

    if (!success) {
      return
    }

    goToNextStep()

    trackCtaClicked({
      cta_location: NEW_USER_PURCHASE_FLOW_TRACKING_LOCATION,
      cta_type: 'button',
      text: 'review purchase'
    })
  }

  return (
    <TwoColumnLayout>
      <section className="w-full max-w-[500px] lg:mr-12">
        <BillingAndPaymentForm
          ref={billingAndPaymentFormRef}
          onSaveLoadingStateChange={setSaveLoading}
          onSaveEnabledStateChange={setSaveEnabled}
        />
      </section>
      <section className="my-12 w-full max-w-[420px] lg:my-0 lg:ml-12 lg:w-auto">
        <OrderRecapSectionWrapper
          onNextClick={onNextButtonClick}
          nextButtonText="Review Purchase"
          nextButtonLoading={saveLoading}
          nextButtonDisabled={!saveEnabled || saveLoading}
        />
      </section>
    </TwoColumnLayout>
  )
}
