import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export function useQueryParams() {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const setQueryParams = (params: any) => {
    history.push({ search: params.toString() })
  }

  const setNewParams = (params: { [key: string]: string }) => {
    const newQueryParams = new URLSearchParams()

    Object.keys(params).forEach((key) => {
      newQueryParams.set(key, params[key])
    })

    history.push({ search: newQueryParams.toString() })
  }

  const deleteQueryParam = (paramKey: string) => {
    if (queryParams.has(paramKey)) {
      queryParams.delete(paramKey)
      setQueryParams(queryParams)
    }
  }

  return { queryParams, setNewParams, deleteQueryParam }
}
