import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import LottieInView from 'components/LottieInView'

import { HomepageContent } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import animation from './ai_animation.json'
import aiStaticImage from './ai_fallback.png'
import extensionBanner from './extension_banner.png'

const AiSection = ({ content }: { content: HomepageContent }) => {
  const { showFreeTrial } = useFeatureFlags()

  return (
    <section className="relative overflow-hidden px-4 md:px-[4vw] py-[50px] sm:py-[75px] lg:py-[100px] [&]:[background:radial-gradient(96.35%_128.19%_at_102.3%_91.71%,transparent_0%,rgba(255,255,255,0.70)_100%),rgba(170,243,241,0.50)]">
      <div className="mx-auto flex max-w-[1280px] flex-col items-center text-center">
        <LottieInView
          animationData={animation}
          className="mx-auto mb-[25px] sm:mb-[50px] lg:mb-[100px] w-full sm:max-w-[330px] md:max-w-[410px] lg:max-w-[530px] xl:max-w-[610px] hidden lg:block"
          loop={false}
        />

        <Image
          src={aiStaticImage}
          alt=""
          className="mx-auto mb-[25px] sm:mb-[50px] lg:mb-[100px] w-full sm:max-w-[330px] md:max-w-[410px] lg:max-w-[530px] xl:max-w-[610px] lg:hidden"
        />

        <MarketingH2 className="mb-2.5 max-w-[305px] sm:max-w-[440px] md:max-w-none lg:max-w-[980px]">
          {content.aiTitle}
        </MarketingH2>
        <MarketingH3 className="mb-[25px] sm:mb-[50px] max-w-[440px] lg:max-w-[640px]">
          {content.aiSubtitle}
        </MarketingH3>

        <ButtonMarketingCTA
          href={content.aiCta.ctaLink}
          location="marketing_homepage__ai_section"
          className="mb-[75px] sm:mb-[125px] lg:mb-[175px] 2xl:mb-[200px]"
        >
          {showFreeTrial ? 'Try with a 14-day free trial' : content.aiCta.ctaText}
        </ButtonMarketingCTA>

        <div className="flex flex-col sm:flex-row items-center gap-12 sm:gap-[30px] lg:gap-[50px] 2xl:gap-[128px]">
          <div className="flex w-full flex-col items-center">
            <h2 className="mb-2.5 text-2xl lg:text-4xl !leading-[1] tracking-[-0.48px] lg:tracking-[-0.72px] max-w-[280px] sm:max-w-[290px] lg:max-w-[440px]">
              {content.extensionTitle}
            </h2>
            <MarketingH3 className="mb-6 md:mb-12 max-w-[340px] lg:max-w-[440px]">
              {content.extensionSubtitle}
            </MarketingH3>

            <ButtonMarketingCTA
              href={content.aiExtensionCta.ctaLink}
              location="marketing_homepage__ai_section"
              color="teal"
            >
              {content.aiExtensionCta.ctaText}
            </ButtonMarketingCTA>
          </div>

          <div className="flex flex-col items-center gap-9 lg:gap-16">
            <Image
              src={extensionBanner}
              className="h-[200px] w-auto sm:max-w-[275px] sm:h-auto sm:w-full md:max-w-[300px] lg:max-w-[445px]"
            />
            <div className="flex items-center gap-6 w-max">
              <span className="text-sm !leading-[1.35] lg:text-lg">Works with</span>
              <div className="flex items-center gap-[21px] md:gap-[22.5px] lg:gap-[30px]">
                {content.extensionApps.map((app) => (
                  <Image
                    key={app.id}
                    src={app.appLogo.imageUrl || ''}
                    alt={`${app.appName} logo`}
                    className="h-[28px] w-[28px] lg:w-10 lg:h-10"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default AiSection
