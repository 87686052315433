import { useEffect } from 'react'
import SlackImage from 'slack.png'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import Tooltip from 'components/Tooltip/Tooltip'

import { MAX_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import { UserAccountDetailsFieldsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { formatInTimezone } from 'utils/date'
import { track } from 'utils/tracking/segment'

import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol-black.svg'

interface IJoinSlack {
  user: UserAccountDetailsFieldsFragment
  joinSlackHref: string
}

const JoinSlack = ({ user, joinSlackHref }: IJoinSlack) => {
  useEffect(() => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'join_slack_cta_displayed',
      location: 'account_page'
    })
  }, [])

  const handleClick = () => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'join_slack_cta_clicked',
      location: 'account_page'
    })

    window.open(
      joinSlackHref ||
        'https://join.slack.com/t/reforge-members/shared_invite/zt-1hcoz4s76-vvIfW2QIvHDa5R~wV64kFA'
    )
  }
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`)

  return (
    <div data-test="account-join-slack" className="rf-rb-card mb-5 bg-white p-10">
      <h3 className="text-xl font-medium text-black">Connect with the Reforge Network</h3>
      <div className="mb-5 text-rb-gray-400">
        <span>
          We’ve integrated Slack to facilitate connections between members &#38;
          seamlessly provide updates on events, content, & more. Join now to receive your
          channel recommendations.
        </span>
      </div>
      <div className="mb-5 flex flex-col items-center justify-between border border-rb-gray-100 py-4 px-5 xs:flex-row">
        <div className="flex items-center pb-5 xs:pb-0">
          <ReforgeSymbol width="46" className="fill-current text-rb-black" />
          <SVGIcon
            className="mx-2"
            name="double-arrow"
            height="11"
            width="29"
            fill="none"
          />
          <img src={SlackImage} height="45" width="45" />
        </div>
        <div className="flex items-center justify-center pb-5 text-sm xs:pb-0">
          {Boolean(user.slackUserId) && (
            <div className="flex flex-col">
              <div className="flex">
                <SVGIcon name="dot" width="8" height="8" fill="#00C361" />
                <div className="ml-2">Connected</div>
              </div>
              <div className="text-rb-gray-300">
                {formatInTimezone(
                  user.contact?.slackAccount?.createdAt,
                  user.profile?.timezone,
                  'MMM d, yyyy'
                )}
              </div>
            </div>
          )}
          {!user.slackUserId && (
            <span className="ml-3 font-medium">Join Reforge Members on Slack</span>
          )}
        </div>
        <div className="flex items-end">
          {Boolean(user.slackUserId) && (
            <Button
              onClick={() =>
                window.open(
                  'https://reforge-members.slack.com/app_redirect?app=A023CN145ND',
                  '_blank'
                )
              }
              shape="rounded-full"
              size={isMobile ? 'small' : 'x-small'}
              variant="outline"
            >
              Open Slack
            </Button>
          )}
          {!user.slackUserId && (
            <div className="flex flex-col items-end">
              <Tooltip
                tooltipBody="Access with paid subscription"
                disable={!user.is.trialing}
              >
                <Button
                  onClick={handleClick}
                  shape="rounded-full"
                  size={isMobile ? 'small' : 'x-small'}
                  variant="outline"
                  disabled={user.is.trialing}
                >
                  Accept Invite
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default JoinSlack
