import DiscoverableTeams from 'domains/Team/TeamCard/DiscoverableTeams'

import Card from 'components/Card'

export function MyTeamCard() {
  return (
    <Card title="My Team">
      <DiscoverableTeams />
    </Card>
  )
}

export default MyTeamCard
