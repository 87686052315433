import { MouseEvent, useEffect } from 'react'

import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { ModalContent } from 'components/Modal'

import { PlanName } from 'gql'

import { cn } from 'utils/tailwind'
import { trackCtaClicked, trackModalDisplayed } from 'utils/tracking/analytics'

import { ReactComponent as DiamondIcon } from 'images/icon--diamond-premium.svg'
import { ReactComponent as Checkmark } from 'images/p-checkmark-bold.svg'

// makes the modal full screen until md breakpoint
export const OnboardingUpsellModalClassNames =
  'max-w-[960px] w-full md:rounded-md !max-h-none md:max-h-[calc(100%-80px)] !h-auto lg:!h-[820px] my-0 md:my-auto'

type Benefit = {
  name: string
  info?: string
  basic: boolean
  membership: boolean
}

const benefits: Benefit[] = [
  {
    name: 'Artifacts',
    basic: true,
    membership: true
  },
  {
    name: 'Expert-led events',
    basic: true,
    membership: true
  },
  {
    name: '1 Live course pass',
    info: 'reg. $1,495',
    basic: false,
    membership: true
  },
  {
    name: 'On-demand courses',
    basic: false,
    membership: true
  },
  {
    name: 'Step-by-step guides',
    info: 'Unlimited',
    basic: false,
    membership: true
  },
  {
    name: 'AI-powered expert advice',
    info: 'Unlimited',
    basic: false,
    membership: true
  },
  {
    name: 'Slack community',
    basic: false,
    membership: true
  }
]

export const OnboardingUpsellModal = () => {
  const { closeGlobalModal } = useGlobalModal()
  const referer = encodeURIComponent(window.location.pathname)
  const allOptionsCta = `/subscribe/choose-plan?referer=${referer}`
  const purchaseMembershipCta = `/subscribe/choose-plan?plan=${PlanName.INDIVIDUAL}&referer=${referer}`

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    closeGlobalModal()

    trackCtaClicked({
      cta_location: 'onboarding_upsell_modal',
      cta_type: 'button',
      destination: (e.target as HTMLAnchorElement).href || window.location.pathname,
      text: (e.target as HTMLAnchorElement).innerText.toLowerCase()
    })
  }

  useEffect(() => {
    trackModalDisplayed({
      category: 'app',
      modal_group: 'onboarding',
      modal_name: 'onboarding upsell',
      location: 'free_user_home'
    })
  }, [])

  return (
    <ModalContent className="px-4 md:px-4">
      <p className="text-center mb-8 text-xl leading-[1.4] md:text-[28px] md:leading-[1.2] font-semibold max-w-[370px] md:max-w-[510px] mx-auto">
        Become a member for exclusive access to Reforge
      </p>

      <div className="md:py-2 w-full md:max-w-[710px] mx-auto rounded-2xl lg:h-[484px] md:bg-rb-blue/30 mb-2 md:mb-0 relative md:pl-8 md:pr-5 md:pb-8">
        <div className="py-4 md:pt-8 flex items-end relative z-1">
          <div />
          <div className="w-[118px] md:w-48 text-center flex flex-col justify-end items-center mr-1 ml-auto shrink-0 gap-2">
            <div className="text-sm leading-[1.5]">Basic access</div>
            <div className="text-base leading-[1.6] font-semibold">Free</div>
          </div>
          <div className="w-32 md:w-48 text-center flex flex-col justify-end items-center shrink-0 gap-2">
            <div className="text-sm leading-[1.5]">Member access</div>
            <div className="text-base leading-[1.6] font-semibold">$1,995 USD/yr</div>
          </div>
        </div>
        {benefits.map((benefit, index) => (
          <div
            className={`flex items-center border-rb-blue/20 ${index === 0 ? 'pb-4' : 'py-4 border-t-2'}`}
            key={index}
          >
            <div className="text-sm font-semibold leading-[1.5] flex flex-wrap gap-1 md:gap-2">
              {benefit.name}
              {benefit.info && <span className="font-normal">{benefit.info}</span>}
              {index === benefits.length - 1 && (
                <div className="h-6 sm:h-4 md:h-2 block w-full">&nbsp;</div>
              )}
            </div>
            <div className="w-[118px] md:w-48 flex justify-center items-center mr-1 ml-auto shrink-0">
              {benefit.basic && <Checkmark className="w-4 h-4" />}
            </div>
            <div className={cn('w-32 md:w-48 flex justify-center items-center shrink-0')}>
              {benefit.membership && <Checkmark className="w-4 h-4" />}
            </div>
          </div>
        ))}

        <div className="bg-rb-blue/20 rounded-2xl absolute right-0 md:right-5 top-0 md:top-2 h-full md:h-[calc(100%-1rem)] w-32 md:w-48 ">
          <DiamondIcon className="w-8 h-8 mx-auto -mt-4 md:-mt-1" />
        </div>
      </div>

      <div className="sticky bottom-0 flex flex-col items-center py-8 md:pb-10 bg-[linear-gradient(180deg,transparent_4.48%,#FFF_43.13%)]">
        <div className="flex flex-col-reverse md:flex-row items-center justify-center mb-4 gap-4">
          <Button className="w-[242px]" variant="outline" size="small" onClick={onClick}>
            Continue with Basic access
          </Button>

          <Button
            className="w-[242px]"
            size="small"
            color="premium"
            href={purchaseMembershipCta}
            onClick={onClick}
          >
            Purchase membership
          </Button>
        </div>

        <Button
          variant="text-only"
          size="small"
          className="text-xs font-semibold w-[242px] text-rb-gray-300"
          href={allOptionsCta}
          onClick={onClick}
        >
          Explore all membership options
        </Button>
      </div>
    </ModalContent>
  )
}

export default OnboardingUpsellModal
