import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import ArtifactPage from 'pages/Artifacts/ArtifactPage'
import { UnitLandingPage } from 'pages/UnitLandingPage'

import CourseOnDemandPageDashboardHeader from 'domains/CourseOnDemand/CourseOnDemandPageDashboardHeader'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'

import { COURSES_ON_DEMAND_PATH } from 'constants/courses'

import { useCourseOnDemandDetailQuery } from 'gql'

import { CourseTrackingIdentifiersContextProvider } from 'hooks/useCourseTrackingIdentifiersContext'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { CourseIdentifiersType } from 'utils/courseUtils'

import CourseOnDemandPageDashboard from './CourseOnDemandPageDashboard'

export const COURSE_DASHBOARD_HEADER_ID = 'course-dashboard-header'

export type CoursePageParams = {
  courseSlug: string
  courseSessionId: string
  guideSlug?: string
  artifactSlug?: string
  sanityEventId?: string
}

export const CourseOnDemandPage = () => {
  const { currentUser } = useCurrentUser()
  const { courseSlug, guideSlug, artifactSlug, sanityEventId } =
    useParams<CoursePageParams>()
  const { showOnDemandMarketplaceCourses, userIdentified } = useFeatureFlags()

  const { isLoggedIn } = useCurrentUser()

  const { loading, data } = useCourseOnDemandDetailQuery({
    variables: { slug: courseSlug }
  })

  if (!userIdentified || loading) {
    return <Loading />
  }

  if (currentUser?.is.freeUser) {
    return <Redirect to={`/courses/${courseSlug}/details`} />
  }

  if (!courseSlug || !showOnDemandMarketplaceCourses) {
    return <Redirect to={COURSES_ON_DEMAND_PATH} />
  }

  if (!data?.course) {
    return <Redirect to={COURSES_ON_DEMAND_PATH} />
  }

  if (!data?.course.isAvailableOnDemand) {
    return <Redirect to={`/courses/${courseSlug}/details`} />
  }

  const { course } = data

  // Future TODO: replace courseIdentifiers with using CourseTrackingIdentifiersContextProvider
  // Will likely need to update everywhere ArtifactsPage uses courseIdentifiers
  const courseIdentifiers: CourseIdentifiersType = {
    course: data?.course
  }

  return (
    <CourseTrackingIdentifiersContextProvider value={{ cclCourse: data.cclCourse }}>
      <div className="relative flex h-full flex-col" id="course-page">
        <Switch>
          <Route
            exact
            path={[`/courses/${courseSlug}/on-demand`]}
            render={() =>
              isLoggedIn && (
                <BaseBreadcrumbs
                  oneLevel
                  className="mb-5"
                  breadcrumbPages={[
                    { title: 'all courses', path: COURSES_ON_DEMAND_PATH }
                  ]}
                  backIcon={
                    <ChevronLeftIcon
                      width={16}
                      height={16}
                      className="mr-4 fill-rb-black"
                    />
                  }
                />
              )
            }
          />
        </Switch>
        {!guideSlug && !artifactSlug && course.creators && (
          <CourseOnDemandPageDashboardHeader
            showBackButton={!!sanityEventId}
            creators={course.creators}
            course={course}
            courseBlocks={course.courseBlocks}
            id={COURSE_DASHBOARD_HEADER_ID}
            cmsProgram={data.course?.cmsProgram}
          />
        )}
        <Switch>
          <Route
            exact
            path={[`/courses/${courseSlug}/on-demand`]}
            render={() => <CourseOnDemandPageDashboard slug={courseSlug} />}
          />
          <Route
            exact
            key={guideSlug}
            path={[`/courses/${courseSlug}/on-demand/guides/${guideSlug}`]}
            render={() => (
              <UnitLandingPage
                unitSlug={guideSlug}
                course={course}
                backToCourseLabel="Course Home"
                showCourseBreadcrumb
                backToCourseLink={`/courses/${courseSlug}/on-demand`}
              />
            )}
          />
          <Route
            exact
            path={`/courses/${courseSlug}/on-demand/artifacts/${artifactSlug}`}
            render={() => (
              <ArtifactPage
                artifactSlug={artifactSlug}
                courseIdentifiers={courseIdentifiers}
              />
            )}
          />
        </Switch>
      </div>
    </CourseTrackingIdentifiersContextProvider>
  )
}
