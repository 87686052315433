import { ApolloError } from '@apollo/client'
import { format } from 'date-fns'
import pluralize from 'pluralize'
import { ReactNode } from 'react'

import { DISCOUNTED_COHORT_PASS_PRICE } from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import Expired from 'domains/Subscription/SubscriptionCard/Expired'

import { ErrorMessage } from 'components'
import { LearnMoreAboutPricingAndPackagingLink } from 'components/LearnMoreAboutPricingAndPackagingLink'

import { PlanName, UserSubscriptionWithTeamQuery } from 'gql'
import { UserSubscriptionWithTeamSubscriptionFragment } from 'gql/index'

import { FULL_DATE_FORMAT } from 'utils/date'
import { formatMoney } from 'utils/moneyUtils'
import { titleize } from 'utils/stringUtils'

import { CohortPassesCard } from './CohortPassesCard'
import { CurrentPlanCard } from './CurrentPlanCard'
import { LegacyPlanCard } from './LegacyPlanCard'
import { NewPlansAvailableCard } from './NewPlansAvailableCard'
import { SubscriptionManagementCards } from './SubscriptionManagementCards'
import { UpcomingPlanCard } from './UpcomingPlanCard'

interface PlanManagementSubscriptionCardContainerProps {
  subscriptionData: UserSubscriptionWithTeamQuery | undefined
  subscriptionLoading: boolean
  subscriptionError: ApolloError | undefined
}

const PlanManagementSubscriptionCardContainer = ({
  subscriptionData,
  subscriptionLoading,
  subscriptionError
}: PlanManagementSubscriptionCardContainerProps) => {
  if (subscriptionLoading || !subscriptionData) return null
  if (subscriptionError) return <ErrorMessage error={subscriptionError} />

  const user = subscriptionData.user
  if (!user) return null

  const { accessPolicyKind, subscriptions, paymentMethods } = user
  const hasPaymentMethod = !!paymentMethods?.length
  const activeSubscription = subscriptions?.active

  if (accessPolicyKind === 'expired') {
    return (
      <div className="rf-rb-card mb-5 bg-white p-6">
        <div className="mb-2 text-xl font-medium">Subscription</div>
        <Expired
          hasPaymentMethod={hasPaymentMethod}
          wasTeam={!!subscriptions?.lastExpired?.isTeam}
        />
      </div>
    )
  }
  if (!activeSubscription) return null

  return (
    <PlanManagementSubscriptionCard
      hasPaymentMethod={hasPaymentMethod}
      subscription={activeSubscription}
      user={user}
    />
  )
}

interface PlanManagementSubscriptionCardProps {
  hasPaymentMethod: boolean
  subscription: UserSubscriptionWithTeamSubscriptionFragment
  user: UserSubscriptionWithTeamQuery['user']
}

export const PlanManagementSubscriptionCard = ({
  hasPaymentMethod,
  subscription,
  user
}: PlanManagementSubscriptionCardProps) => {
  const prettyExpirationDate = format(new Date(subscription.expiresAt), FULL_DATE_FORMAT)
  const prettyTrialEndDate = subscription.trialEndDate
    ? format(new Date(subscription.trialEndDate), FULL_DATE_FORMAT)
    : null
  const today = new Date().getTime()
  const expiresAtDate = new Date(subscription.expiresAt).getTime()
  const daysUntilExpiration = subscription.dunningDaysLeft
  const isExpired = expiresAtDate < today

  const isLegacySubscription = subscription.planName === PlanName.UNLIMITED
  const isCanceling = !!subscription.isCanceled
  const isInDunning = subscription.isDunning && !isCanceling
  const hasSelectedFlatTierPlan =
    !!subscription.stripeUpcomingInvoice?.planName &&
    subscription.stripeUpcomingInvoice?.planName !== PlanName.UNLIMITED

  const isIndividual = subscription.numSeats === 1
  const numUpcomingCohortPasses = subscription.stripeUpcomingInvoice?.numCohortPasses || 0
  let numCohortPasses = subscription.cohortCredits.unexpired.length
  let unusedPasses = subscription.cohortCredits.useable.length

  if (user?.is.trialing) {
    numCohortPasses += 1
    unusedPasses += 1
  }

  const numTeamCohortPassesAvailable = subscription.cohortCredits.assignable.length || 0
  const numUsedCohortPasses =
    subscription.planName === PlanName.INDIVIDUAL
      ? numCohortPasses - unusedPasses
      : numCohortPasses - numTeamCohortPassesAvailable

  const isDunning = subscription.isDunning

  let dunningReason = hasPaymentMethod ? 'Failed payment' : 'No payment method'

  if (isDunning) {
    dunningReason = 'Invoice past due'
  }
  const contactUsCta = (
    <a
      href="mailto:hello@reforge.com?subject=My new Reforge plan"
      target="_blank"
      rel="noreferrer"
      className="rb-teal-200"
    >
      Contact us
    </a>
  )
  const getInTouchCta = (
    <a
      href="mailto:hello@reforge.com?subject=My new Reforge plan"
      target="_blank"
      rel="noreferrer"
      className="rb-teal-200"
    >
      Get in touch
    </a>
  )
  const helloAtReforgeCta = (
    <a
      href="mailto:hello@reforge.com?"
      target="_blank"
      rel="noreferrer"
      className="rb-teal-200"
    >
      hello@reforge.com
    </a>
  )

  const renewalPriceCopy = () => {
    if (isCanceling) {
      return 'Canceled'
    } else if (isLegacySubscription) {
      return subscription.numSeats > 1 ? 'Legacy Team' : 'Legacy Individual'
    } else if (!subscription.stripeUpcomingInvoice) {
      return ''
    } else {
      let price = (subscription.stripeUpcomingInvoice?.subtotal || 0) / 100
      if (user?.is.trialing && subscription.planName === PlanName.INDIVIDUAL) {
        price += DISCOUNTED_COHORT_PASS_PRICE
      }

      return `${formatMoney(price)}/year`
    }
  }

  const getPlanName = (planName: PlanName | undefined | string | null) =>
    planName ? `the ${titleize(planName)} Plan` : 'a plan'

  const getRenewMessage = (
    planName: PlanName | string | null,
    expirationDate: string,
    ownerMessage = ''
  ) => (
    <p>
      Your subscription will renew into {getPlanName(planName)} on {expirationDate}.
      {ownerMessage}
    </p>
  )

  const getExpireMessage = (
    expirationDate: string,
    isTrialing: boolean | undefined,
    reactivateMessage = '',
    helpMessage: ReactNode | string = ''
  ) => (
    <p>
      Your {isTrialing ? 'trial' : 'subscription'} is set to expire on {expirationDate}.{' '}
      {reactivateMessage} {helpMessage}
    </p>
  )

  const getTrialMessage = (
    isTrialing: boolean | undefined,
    trialEndDate: string | null,
    expirationDate: string
  ) => {
    if (isTrialing) {
      return (
        <p>
          You are on a free trial of the Individual plan. Your free trial ends on{' '}
          {trialEndDate}.
        </p>
      )
    }
    return <p>You are on the Individual Plan which will renew on {expirationDate}.</p>
  }

  const messages = [
    {
      condition:
        isLegacySubscription &&
        !isCanceling &&
        (isIndividual || subscription.userIsOwner),
      message: (
        <p>
          Your subscription will renew into{' '}
          {getPlanName(subscription.stripeUpcomingInvoice?.planName)}
          {numUpcomingCohortPasses > 0 && (
            <>
              {''} with {numUpcomingCohortPasses} Live Course{' '}
              {pluralize('Pass', numUpcomingCohortPasses)}{' '}
            </>
          )}
          on {prettyExpirationDate}. <LearnMoreAboutPricingAndPackagingLink /> Questions
          about your new plan? {contactUsCta}.
        </p>
      )
    },
    {
      condition: isIndividual && isCanceling && !isLegacySubscription,
      message: getExpireMessage(
        prettyExpirationDate,
        user?.is.trialing,
        'Reactivate your subscription to maintain access to Reforge.'
      )
    },
    {
      condition: isLegacySubscription && isCanceling && subscription.userIsOwner,
      message: getExpireMessage(
        prettyExpirationDate,
        user?.is.trialing,
        'Select a new plan to maintain access to Reforge. Need help choosing the right plan?',
        getInTouchCta
      )
    },
    {
      condition:
        isLegacySubscription && !isIndividual && !subscription.userIsOwner && isCanceling,
      message: getExpireMessage(
        prettyExpirationDate,
        user?.is.trialing,
        'Be sure to tell your subscription owner to select a plan before it expires.'
      )
    },
    {
      condition:
        isLegacySubscription &&
        !isIndividual &&
        !subscription.userIsOwner &&
        !isCanceling,
      message: getRenewMessage(
        subscription.stripeUpcomingInvoice?.planName || 'Membership',
        prettyExpirationDate,
        'To make changes to your plan, contact your subscription owner.'
      )
    },
    {
      condition: !isLegacySubscription && isIndividual && !isCanceling && !isInDunning,
      message: getTrialMessage(
        user?.is.trialing,
        prettyTrialEndDate,
        prettyExpirationDate
      )
    },
    {
      condition: !isLegacySubscription && isIndividual && isInDunning,
      message: (
        <p>
          We couldn’t process your payment. Update your payment method to maintain access
          to Reforge. Your subscription will expire in {daysUntilExpiration} days.
        </p>
      )
    },
    {
      condition:
        !isLegacySubscription &&
        !isIndividual &&
        !subscription.userIsOwner &&
        isInDunning,
      message: (
        <p>
          We couldn’t process your team’s payment. Ask your subscription owner to update
          your payment method to maintain access to Reforge. Your subscription will expire
          in {daysUntilExpiration} days.
        </p>
      )
    },
    {
      condition: !isLegacySubscription && isIndividual && isExpired,
      message: (
        <p>
          Your subscription has expired and you have automatically been downgraded to the
          free tier. Reactivate your membership today to regain access to Reforge content.
        </p>
      )
    },
    {
      condition:
        !isLegacySubscription &&
        !isIndividual &&
        subscription.userIsOwner &&
        !isCanceling &&
        !isInDunning,
      message: getRenewMessage(subscription.planName, prettyExpirationDate)
    },
    {
      condition:
        !isLegacySubscription && !isIndividual && subscription.userIsOwner && isCanceling,
      message: getExpireMessage(
        prettyExpirationDate,
        user?.is.trialing,
        'Reactivate your subscription to maintain access to Reforge for you and your team.'
      )
    },
    {
      condition:
        !isLegacySubscription &&
        !isIndividual &&
        subscription.userIsOwner &&
        isInDunning &&
        hasPaymentMethod,
      message: (
        <p>
          We couldn’t process your payment. Update your payment method to maintain access
          to Reforge. Your subscription will expire in {daysUntilExpiration} days.
        </p>
      )
    },
    {
      condition:
        !isLegacySubscription &&
        !isIndividual &&
        subscription.userIsOwner &&
        isInDunning &&
        !hasPaymentMethod,
      message: (
        <p>
          Your invoice is past due. Complete your payment now from the link in Billing
          history or contact {helloAtReforgeCta} to maintain access to Reforge.
        </p>
      )
    },
    {
      condition:
        !isLegacySubscription &&
        !isIndividual &&
        !subscription.userIsOwner &&
        !isCanceling,
      message: getRenewMessage(
        null,
        prettyExpirationDate,
        'To make changes to your plan, contact your subscription owner.'
      )
    },
    {
      condition:
        !isLegacySubscription &&
        !isIndividual &&
        !subscription.userIsOwner &&
        isCanceling,
      message: getExpireMessage(
        prettyExpirationDate,
        user?.is.trialing,
        'Be sure to tell your subscription owner to select a plan before it expires.'
      )
    }
  ]

  const copy = messages.find((msg) => msg.condition)?.message || null

  return (
    <div data-test="account-subscription" className="rf-rb-card mb-5 bg-white p-10">
      <div className="mb-2 text-xl font-medium">Subscription</div>
      <span data-testid="account-subscription-copy">{copy}</span>
      <SubscriptionManagementCards>
        {isLegacySubscription ? (
          <>
            <LegacyPlanCard
              numTotalSeats={subscription.numSeats}
              renewalPriceCopy={renewalPriceCopy()}
              isSubscriptionOwner={subscription.userIsOwner}
              expiresAt={subscription.expiresAt}
              isCanceled={isCanceling}
            />
            {hasSelectedFlatTierPlan ? (
              <UpcomingPlanCard
                numTotalSeats={
                  subscription.stripeUpcomingInvoice?.numSeats || subscription.numSeats
                }
                amountDue={subscription.stripeUpcomingInvoice?.amountDue || 0}
                isSubscriptionOwner={subscription.userIsOwner}
                expiresAt={subscription.expiresAt}
                planName={
                  subscription.stripeUpcomingInvoice?.planName || subscription.planName
                }
                numCohortPasses={subscription.stripeUpcomingInvoice?.numCohortPasses || 0}
              />
            ) : (
              <NewPlansAvailableCard
                isSubscriptionOwner={subscription.userIsOwner}
                expiresAt={subscription.expiresAt}
              />
            )}
          </>
        ) : (
          // Subscription Cards for users already on flat tier plan
          <>
            <CurrentPlanCard
              subscription={subscription}
              user={user}
              numTotalSeats={subscription.numSeats}
              numUsedSeats={subscription.numSeats - subscription.numSeatsAvailable}
              planName={subscription.planName}
              renewalPriceCopy={renewalPriceCopy()}
              isSubscriptionOwner={subscription.userIsOwner}
              expiresAt={subscription.expiresAt}
              isCanceled={isCanceling}
              isDunning={isInDunning}
              dunningReason={dunningReason}
            />
            <CohortPassesCard
              isIndividual={isIndividual}
              numTotalCohortPasses={numCohortPasses}
              numUsedCohortPasses={numUsedCohortPasses}
              isSubscriptionOwner={subscription.userIsOwner}
              isTrialing={user?.is.trialing}
            />
          </>
        )}
      </SubscriptionManagementCards>
    </div>
  )
}

export default PlanManagementSubscriptionCardContainer
