import { ReactNode, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import ArtifactSeoCarouselSection from 'domains/ArtifactSeo/ArtifactSeoCarouselSection'
import ArtifactSeoTopicLinkSection from 'domains/ArtifactSeo/ArtifactSeoTopicLinkSection'
import { MostPopularArtifacts } from 'domains/ArtifactSeo/MostPopularArtifacts'
import {
  ComplexPortableText,
  defaultComponents
} from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import RelatedBlogPostsCarouselSection from 'domains/Seo/RelatedBlogPostsCarouselSection'
import RelatedGuidesCarouselSection from 'domains/Seo/RelatedGuidesCarouselSection'
import SeoPageSectionSubtitle from 'domains/Seo/Section/SeoPageSectionSubtitle'
import SeoPageSectionTitle from 'domains/Seo/Section/SeoPageSectionTitle'
import { SeoTopicSection } from 'domains/Seo/Section/SeoTopicSection'
import SeoPageCtaSection from 'domains/Seo/SeoPageCtaSection'
import SeoPageHero from 'domains/Seo/SeoPageHero'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  ArtifactSubtopic,
  ArtifactTopic,
  ArtifactsForSubtopic,
  PageSeo,
  PageTypeEnum,
  useArtifactSubtopicQuery,
  useArtifactSubtopicsQuery,
  useArtifactsForSubtopicQuery,
  useTopicsQuery
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import ShareGraphic from 'images/ReforgeHomeSocialShare.jpg'
import BackgroundImageMobile from 'images/artifact-topic-subtopic-banner-mobile.png'
import BackgroundImage from 'images/artifact-topic-subtopic-banner.png'

export const ArtifactSubtopicPage = () => {
  const { topic: topicParam, subtopic: subtopicParam } = useParams<{
    topic: string
    subtopic: string
  }>()

  const { data: subtopicData, loading: subtopicDataLoading } = useArtifactSubtopicQuery({
    variables: {
      slug: subtopicParam
    }
  })

  const { data: artifactsData, loading: artifactDataLoading } =
    useArtifactsForSubtopicQuery({
      variables: {
        slug: subtopicParam
      }
    })

  const { data: subtopicsData } = useArtifactSubtopicsQuery({
    variables: {
      subtopicSlug: subtopicParam,
      topicSlug: topicParam
    }
  })

  const { data: topicsData } = useTopicsQuery({
    variables: {
      slug: topicParam
    }
  })

  const subtopic = subtopicData?.subtopic
  const artifactsForSubtopic = artifactsData?.artifactsForSubtopic
  const subtopics = subtopicsData?.subtopics
  const topics = topicsData?.topics

  const handleRender = (content: ReactNode) => {
    if (!subtopic) {
      return <Content404 />
    }

    if (subtopic.isDeprecated) {
      return <Redirect to={subtopic.redirectTo!} />
    }

    if (subtopic.topic.slug !== topicParam) {
      return <Content404 />
    }

    if (!subtopic.topic.isPublic) {
      return <Content404 />
    }

    if (subtopic.topic.isDeprecated) {
      return <Redirect to={subtopic.topic.redirectTo!} />
    }

    return content
  }

  const page: GenericPageType = useMemo(() => {
    if (subtopic) {
      const title = `${subtopic.title} Templates and Examples - Reforge`
      const description = `Explore ${
        artifactsForSubtopic?.totalCount || 'several'
      } real examples of ${
        subtopic.titlePlural
      } from top companies. Go beyond templates and see real ${
        subtopic.titlePlural
      } from experts who have been there before.`

      return {
        title,
        seo: {
          metaTitle: title,
          metaDesc: description,
          shareTitle: title,
          shareDesc: description,
          shareGraphic: { asset: ShareGraphic },
          shareGraphicAlt: title,
          type: 'website' as PageTypeEnum
        } as PageSeo
      }
    }

    return {}
  }, [artifactsForSubtopic?.totalCount, subtopic])

  return (
    <MarketingLayout page={page}>
      {subtopicDataLoading ? (
        <Loading />
      ) : (
        handleRender(
          <>
            <ArtifactSubtopicPageContent
              subtopic={subtopic!}
              artifactsForSubtopic={artifactsForSubtopic}
              artifactDataLoading={artifactDataLoading}
              subtopics={subtopics}
              topics={topics}
            />
          </>
        )
      )}
    </MarketingLayout>
  )
}

interface ArtifactSubtopicPageContentProps {
  subtopic: ArtifactSubtopic
  artifactsForSubtopic?: ArtifactsForSubtopic | null
  artifactDataLoading: boolean
  subtopics?: ArtifactSubtopic[] | null
  topics?: ArtifactTopic[]
}

export const ArtifactSubtopicPageContent = ({
  subtopic,
  artifactsForSubtopic,
  artifactDataLoading,
  subtopics,
  topics
}: ArtifactSubtopicPageContentProps) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const title = `Explore real examples of ${subtopic.titlePlural}`
  const subtitle = `Learn how the best operators in tech think about ${
    subtopic.titlePlural
  }. Explore ${
    artifactsForSubtopic?.totalCount ? `${artifactsForSubtopic?.totalCount} ` : ''
  }examples of ${subtopic.titlePlural} so you never have to start from scratch.`
  const ctaText = `Explore ${subtopic.titlePlural}`
  const ctaHref = `/artifacts?subtopics=${subtopic.slug}`
  const artifactsToDisplayCount = artifactsForSubtopic?.artifacts.length || 0
  const MIN_ARTIFACTS_FOR_CAROUSEL = 8
  const MIN_MOST_POPULAR_ARTIFACTS = 10

  const mostPopularArtifacts =
    artifactsToDisplayCount < MIN_MOST_POPULAR_ARTIFACTS
      ? artifactsForSubtopic?.artifacts || []
      : artifactsForSubtopic?.artifacts.slice(0, 10) || []
  const carouselArtifacts =
    artifactsToDisplayCount >= MIN_ARTIFACTS_FOR_CAROUSEL
      ? artifactsForSubtopic?.artifacts.slice(5) || []
      : null

  const tracking: SEOTrackingRelatedIdentifiers = {
    sanityType: 'subtopic',
    sanityId: subtopic.id || '',
    sanityName: subtopic.title
  }
  const showSubtitle = artifactsForSubtopic?.totalCount !== undefined
  return (
    <>
      <div>
        <BaseBreadcrumbs
          className="pb-14 pl-4 md:pl-[4vw] md:pb-8"
          breadcrumbPages={[
            { title: 'Artifacts', path: '/artifacts' },
            {
              title: subtopic?.topic.title ?? '',
              path: `/artifacts/c/${subtopic?.topic.slug}`
            },
            {
              title: subtopic?.title ?? '',
              path: `/artifacts/c/${subtopic?.topic.slug}/${subtopic?.slug}`
            }
          ]}
          backIcon={
            <ChevronLeftIcon width={15} height={16} className="mr-4 fill-rb-black" />
          }
        />
      </div>
      <SeoPageHero
        title={title}
        subtitle={showSubtitle && subtitle}
        ctaText={ctaText}
        ctaHref={ctaHref}
        updatedAt={subtopic.lastUpdate}
        bgImage={BackgroundImage}
        bgImageMobile={BackgroundImageMobile}
        tracking={tracking}
      />

      <section className="px-4 py-8 md:px-[4vw]">
        <div className="mx-auto max-w-[1024px]">
          {subtopic.termDefinition && (
            <div className="mb-8">
              <ComplexPortableText
                content={subtopic.termDefinition}
                components={{
                  ...defaultComponents,
                  block: {
                    ...defaultComponents.block,
                    h2: SeoPageSectionTitle,
                    h3: SeoPageSectionSubtitle
                  }
                }}
              />
            </div>
          )}

          <MostPopularArtifacts
            subtopic={subtopic}
            artifacts={mostPopularArtifacts}
            totalCount={artifactsForSubtopic?.totalCount || 0}
            loading={artifactDataLoading}
            ctaHref={ctaHref}
          />

          {carouselArtifacts && (
            <ArtifactSeoCarouselSection
              title={`Other popular ${subtopic.titlePlural}`}
              subtitle={`Explore the full catalog of ${subtopic.title} resources to get inspired`}
              viewAllText={`View all ${
                artifactsForSubtopic?.totalCount
                  ? `${artifactsForSubtopic?.totalCount} `
                  : ''
              }
              examples of ${subtopic.titlePlural}`}
              ctaText={
                isMobile ? 'View all artifacts' : `View all ${subtopic.title} artifacts`
              }
              ctaHref={ctaHref}
              artifacts={carouselArtifacts}
              tracking={tracking}
            />
          )}

          <SeoPageCtaSection
            tracking={tracking}
            ctaText="Explore membership"
            ctaHref="/membership"
            ctaLocation="SEO subtopic page"
          />

          {topics && topics.length > 0 && (
            <ArtifactSeoTopicLinkSection
              topics={topics}
              title="Explore artifacts of all kinds"
              subtitle="Browse other popular categories to get ideas for your own work"
              tracking={tracking}
            />
          )}

          {subtopic.relatedBlogPosts && subtopic.relatedBlogPosts.length > 0 && (
            <RelatedBlogPostsCarouselSection blogPosts={subtopic.relatedBlogPosts} />
          )}

          {subtopic.relatedGuides && subtopic.relatedGuides.length > 0 && (
            <RelatedGuidesCarouselSection
              guides={subtopic.relatedGuides}
              title="Go deeper with step by step guides"
              ctaText="See all guides"
              ctaHref="/guides"
              navigationLocation="SEO related guides carousel"
            />
          )}

          {subtopics && subtopics.length > 0 && (
            <SeoTopicSection
              type="subtopics"
              title={`Topics related to ${subtopic.title}`}
              subtopics={subtopics}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default ArtifactSubtopicPage
