import { formatInTimeZone } from 'date-fns-tz'
import React from 'react'
import { useLocation } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import { FacePile } from 'components/FacePile'
import RfParagraphMiniSemiBold from 'components/typography/RfParagraph/RfParagraphMiniSemiBold'

import {
  BookmarkFolderPartsFragment,
  CclCourseCourseCardPartsFragment,
  CclCourseSessionCourseCardPartsFragment,
  CclExpertCourseCardPartsFragment,
  CourseBookmarkPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getCurrentTimezone, isBeforeDate } from 'utils/date'
import notifyError from 'utils/errorNotifier'
import { listify } from 'utils/stringUtils'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export const COURSE_CARD_TYPE_LIVE = 'live'
export const COURSE_CARD_TYPE_ON_DEMAND = 'on-demand'
export const COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS = 'courseDetails'
export const COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD = 'courseDashboard'
export const COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD = 'onDemandDashboard'

export interface CourseCardProps {
  course: Omit<CclCourseCourseCardPartsFragment, 'staticId' | '__typename'>
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  legacyCourseSessionId?: number | string
  variant?: CardVariant
  showSessionHostInThumbnail?: boolean
  showSessionHostInByline?: boolean
  inNewTab?: boolean
  pageLocation?: string
  locationType?: string
  customThumbnail?: React.ReactNode
  CourseFooterOverride?: React.ComponentType<any>
  destinationType?:
    | typeof COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD
    | typeof COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
    | typeof COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
  additionalRelatedIdentifiers?: {}
  // Bookmark-related props
  bookmark?: CourseBookmarkPartsFragment | ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (
    bookmark: CourseBookmarkPartsFragment | ProgramBookmarkPartsFragment
  ) => void
  restoreBookmark?: (
    bookmark: CourseBookmarkPartsFragment | ProgramBookmarkPartsFragment
  ) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  showFreeWithCoursePass?: boolean
  customHoverMiniCard?: boolean
  impressionTrackingProperties?: { [key: string]: any }
  cardVariant?: CardVariant
}

const CourseCard = ({
  course,
  courseSession,
  legacyCourseSessionId,
  showSessionHostInThumbnail = false,
  showSessionHostInByline = false,
  variant = CardVariants.Vertical,
  inNewTab,
  destinationType = COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  hideBookmarkButton = false,
  showFreeWithCoursePass = false,
  pageLocation,
  locationType,
  additionalRelatedIdentifiers,
  CourseFooterOverride,
  customThumbnail,
  customHoverMiniCard = false,
  impressionTrackingProperties
}: CourseCardProps) => {
  const { pathname } = useLocation()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const showPremiumIcon = !isLoggedIn || !!currentUser?.is?.premember
  const courseId = course.id

  const title = course.title
  const slug = course.slug
  const description = course.shortDescription

  const destination = () => {
    const legacyCourseSlug = course.cmsProgram?.slug

    if (legacyCourseSlug && currentUser?.preferredCourseView === 'legacy') {
      return `/programs/${legacyCourseSlug}`
    }

    let destinationUrl = `/courses/${slug}`

    if (destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS) {
      destinationUrl += '/details'
    }

    if (!isLoggedIn) {
      return destinationUrl
    }

    if (destinationType === COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD) {
      destinationUrl += '/on-demand'
    }

    if (
      destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD &&
      legacyCourseSessionId
    ) {
      destinationUrl += `/sessions/${legacyCourseSessionId}`
    }

    return destinationUrl
  }

  const destinationUrl = destination()
  const relatedIdentifiers = {
    course_id: courseId,
    course_title: title,
    course_session_id: courseSession?.id,
    is_featured: false,
    is_filtered_reference: false,
    is_empty_index_results: false,
    ...additionalRelatedIdentifiers // use this to override any of the identifiers above
  }

  const handleTracking = () => {
    trackNavigationClicked({
      destination: destinationUrl,
      location: pageLocation,
      location_type: locationType,
      logged_in: isLoggedIn,
      path: pathname,
      type: `${variant}_card`, // horizontal_card, vertical_card, mini_card, list_card
      related_identifiers: relatedIdentifiers
    })
  }

  const thumbnailExperts =
    showSessionHostInThumbnail && courseSession?.experts?.length
      ? courseSession.experts
      : course.creators

  const FooterComponent = CourseFooterOverride ? (
    <CourseFooterOverride />
  ) : (
    <CourseFooter
      courseSession={courseSession}
      timezone={currentUser?.timezone}
      showFreeWithCoursePass={showFreeWithCoursePass}
    />
  )

  return (
    <BaseCard
      contentType="Course"
      variant={variant}
      title={title}
      courseSlug={slug}
      byline={
        <CourseByline
          course={course}
          courseSession={courseSession}
          showSessionHostInByline={showSessionHostInByline}
          variant={variant}
        />
      }
      body={description}
      thumbnail={customThumbnail || <Thumbnail experts={thumbnailExperts} />}
      horizontalThumbnail={<CourseHorizontalThumbnail experts={thumbnailExperts} />}
      verticalThumbnail={<CourseVerticalThumbnail experts={thumbnailExperts} />}
      footer={FooterComponent}
      destination={destinationUrl}
      inNewTab={inNewTab}
      trackCardClick={handleTracking}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
      customHoverMiniCard={customHoverMiniCard}
      impressionTrackingProperties={impressionTrackingProperties}
      showPremiumIcon={showPremiumIcon}
    />
  )
}

const CourseByline = ({
  course,
  courseSession,
  showSessionHostInByline,
  variant
}: {
  course: Omit<CclCourseCourseCardPartsFragment, 'staticId' | '__typename'>
  showSessionHostInByline: Boolean
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  variant?: CardVariant
}) => {
  const experts: CclExpertCourseCardPartsFragment[] =
    (showSessionHostInByline ? courseSession?.experts : course.creators) || []

  const expertNames = experts.map((expert) => expert.name)

  const expertFacepileUsers = experts.map((expert) => {
    return {
      id: expert.id,
      avatarUrl: expert.avatarUrl || ''
    }
  })

  if (experts.length > 0) {
    if (variant === CardVariants.List) {
      return (
        <div className="flex gap-2">
          <div className="flex shrink-0 grow-0">
            <FacePile users={expertFacepileUsers} imageSize="small" />
          </div>
          <div className="text-ellipsis line-clamp-1">{listify(expertNames)}</div>
        </div>
      )
    } else {
      return (
        <span>
          {`${showSessionHostInByline ? 'Hosted' : 'Created'} by ${listify(expertNames)}`}
        </span>
      )
    }
  }

  expertNames.length > 0 ? '' : null
  return null
}

const CourseFooter = ({
  courseSession,
  timezone,
  showFreeWithCoursePass = false
}: {
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  timezone?: string | null
  showFreeWithCoursePass?: boolean
}) => {
  if (!courseSession) {
    return null
  }

  const { startsAt, endsAt, id } = courseSession

  if (!startsAt) {
    return null
  }

  try {
    const formattedStartDate = formatInTimeZone(
      startsAt,
      getCurrentTimezone(timezone),
      'MMM d'
    )

    if (isBeforeDate(courseSession.startsAt)) {
      const durationText = courseSession.duration

      if (!durationText && !formattedStartDate) return null

      return (
        <div className="flex flex-row justify-between sm:mr-8">
          <div>
            <span className="font-semibold">Starts {formattedStartDate}</span>
            {durationText && (
              <>
                <span className="px-1">·</span>
                <span>{durationText}</span>
              </>
            )}
          </div>
          {showFreeWithCoursePass && (
            <RfParagraphMiniSemiBold className="text-green-600">
              Free with your Course Pass
            </RfParagraphMiniSemiBold>
          )}
        </div>
      )
    }
    if (!endsAt) {
      return null
    }
    const formattedEndDate = formatInTimeZone(
      endsAt,
      getCurrentTimezone(timezone),
      'MMM d, yyyy'
    )
    return (
      <div className="flex flex-row justify-between sm:mr-8">
        <span className="font-semibold">
          {formattedStartDate} - {formattedEndDate}
        </span>
        {showFreeWithCoursePass && (
          <RfParagraphMiniSemiBold className="text-green-600">
            Free with your Course Pass
          </RfParagraphMiniSemiBold>
        )}
      </div>
    )
  } catch (e) {
    notifyError(
      `ccl_course_session id: ${id} - starts_at: ${startsAt}, ends_at: ${endsAt}: ${e}`
    )
    return null
  }
}

const CourseVerticalThumbnail = ({
  experts
}: {
  experts?: CclExpertCourseCardPartsFragment[] | null
}) => {
  const expertImageData = expertsToImageData(experts)
  if (expertImageData.length === 0) return null

  if (expertImageData.length <= 2) {
    return (
      <div className="flex h-full gap-x-2 px-4 pt-4">
        {expertImageData.map((expert, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={expert.url}
              alt={expert.name}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="h-full px-4 pt-4">
      <div className="relative flex h-full gap-x-2 overflow-hidden">
        {expertImageData.length > 3 && (
          <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
            +{expertImageData.length - 3}
          </div>
        )}
        {expertImageData.slice(0, 3).map((expert, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(33.3%-5.3px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={expert.url}
              alt={expert.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const expertsToImageData = (experts?: CclExpertCourseCardPartsFragment[] | null) => {
  return (
    experts?.map((expert) => {
      return {
        url: expert.avatarUrl || '',
        name: expert.name
      }
    }) || []
  )
}

const CourseHorizontalThumbnail = ({
  experts
}: {
  experts?: CclExpertCourseCardPartsFragment[] | null
}) => {
  if (!experts) {
    return null
  }
  const expertsImageData = expertsToImageData(experts)
  if (expertsImageData.length === 0) return null

  if (expertsImageData.length === 1) {
    return (
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={expertsImageData[0].url || ''}
        alt={expertsImageData[0].name}
      />
    )
  }
  return (
    <div className="relative flex h-full gap-x-2">
      {expertsImageData.length > 2 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{expertsImageData.length - 2}
        </div>
      )}
      {expertsImageData.slice(0, 2).map((expertData, idx) => (
        <div
          key={`card-thumbnail-${idx}`}
          className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
        >
          <Image
            className="h-full w-full rounded-xl object-cover"
            src={expertData.url || ''}
            alt={expertData.name}
          />
        </div>
      ))}
    </div>
  )
}

const Thumbnail = ({
  experts
}: {
  experts?: CclExpertCourseCardPartsFragment[] | null
}) => {
  const expertsImageData = expertsToImageData(experts)
  if (expertsImageData.length === 0) return null

  return (
    <div className="relative">
      {expertsImageData.length > 1 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{expertsImageData.length - 1}
        </div>
      )}
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={expertsImageData[0].url}
        alt={expertsImageData[0].name}
      />
    </div>
  )
}

export default CourseCard
