import { gql, useQuery } from '@apollo/client'
import FsLightbox from 'fslightbox-react'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useSnapshot } from 'valtio'

import RelatedArtifactsCarousel from 'pages/Artifacts/RelatedArtifactsCarousel'
import { BackToParams } from 'pages/UnitLandingPage/UnitLandingPage'

import { SuggestedPromptsWidget } from 'domains/Ai/SuggestedPrompts/SuggestedPromptsWidget'
import { ArtifactContext } from 'domains/Artifact/contexts/ArtifactContext'
import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import HotspotMarker from 'domains/Sanity/PortableText/ArtifactsPortableText/components/HotspotMarker'

import { Loading } from 'components'
import { Breadcrumb } from 'components/Breadcrumb'
import ContentGate from 'components/ContentGate'
import PremiumBadge from 'components/PremiumBadge'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  BookmarkFolderPartsFragment,
  InferableAction,
  ProgramBookmarkPartsFragment,
  useBookmarkFoldersQuery,
  useCreateUserCmsActivityMutation,
  useGenerateAiInferenceMutation,
  useMarkArtifactAsViewedMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import {
  trackContentStarted,
  trackCopiedText,
  trackCtaClicked,
  trackNavigationClicked
} from 'utils/tracking/analytics'

import { artifactState } from 'stores'

import { ReactComponent as DiamondIcon } from 'images/icon--diamond-premium.svg'

import ArtifactButtons from './ArtifactButtons'
import ArtifactViewerHero from './ArtifactViewerHero'
import ArtifactViewerSidebar from './ArtifactViewerSidebar'
import BlockContent from './BlockContent'
import NoteAndCommentButtons from './NoteAndCommentButtons'
import { useArtifactViewerSideDrawerStateMachine } from './hooks/useArtifactViewerSideDrawerStateMachine'

const ArtifactViewerData = ({
  artifact,
  authors,
  backTo,
  contributors
}: {
  artifact: Record<string, any>
  authors: any
  backTo?: BackToParams
  contributors: any
}) => {
  const { currentUser: user, isLoggedIn } = useCurrentUser()

  const { data: artifactUserData, loading: loadingArtifactData } =
    useQuery<ArtifactUserDataResponse>(ARTIFACT_USER_DATA_QUERY, {
      skip: !isLoggedIn || !artifact,
      variables: {
        artifactId: artifact?.id,
        userId: user?.id
      }
    })

  const { data: bookmarkFolderData, loading: bookmarkFolderDataLoading } =
    useBookmarkFoldersQuery({
      skip: !isLoggedIn
    })

  if (loadingArtifactData || bookmarkFolderDataLoading) return <Loading />

  const bookmarkFolders = bookmarkFolderData?.currentUser?.bookmarkFolders || []

  return (
    <ArtifactViewer
      artifactUserData={artifactUserData}
      artifact={artifact}
      authors={authors}
      backTo={backTo}
      contributors={contributors}
      bookmarkFolders={bookmarkFolders}
    />
  )
}

const ArtifactViewer = ({
  artifactUserData,
  artifact,
  authors,
  backTo,
  contributors,
  bookmarkFolders
}: {
  artifactUserData: ArtifactUserDataResponse | undefined
  artifact: Record<string, any>
  authors: any
  backTo?: BackToParams
  contributors: any
  bookmarkFolders: BookmarkFolderPartsFragment[]
}) => {
  const { currentUser: user, isLoggedIn } = useCurrentUser()
  const history = useHistory()
  const urlParams = useParams<{ slug: string }>()

  const [bookmark, setBookmark] = useState<ProgramBookmarkPartsFragment | undefined>(
    undefined
  )
  const fromCourse = backTo && backTo.length > 0

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const { welcomeMode } = useSnapshot(artifactState)

  // TODO: (stu) do we need this?
  const openSideDrawer = () => {
    // artifactState.sideDrawerOpen = true
  }

  const closeSideDrawer = () => {
    // artifactState.sideDrawerOpen = false

    // dispatch a resize event so that RoughNotation highlights resize
    window.dispatchEvent(new Event('resize'))
  }

  const [sideDrawerStateAndActions, sendSideDrawerStateEvent] =
    useArtifactViewerSideDrawerStateMachine({
      processAction: (action) => {
        const currentPath = `/artifacts/${urlParams.slug}`

        switch (action) {
          case 'openSideDrawer':
            openSideDrawer()
            return
          case 'closeSideDrawer':
            closeSideDrawer()
            return
          case 'clearActiveAnnotation':
            if (activeAnnotation) {
              setActiveAnnotation(null)
              history.replace(currentPath, null)
            }
        }
      }
    })

  const [sideDrawerState] = sideDrawerStateAndActions
  const [activeAnnotation, setActiveAnnotation] = useState<string | null>(null)

  const sideDrawerOpen =
    sideDrawerState === 'sideDrawerGteLgOpened' ||
    sideDrawerState === 'sideDrawerLtLgOpened' ||
    sideDrawerState === 'sideDrawerLtLgOpening'

  const [savedArtifactIds, setSavedArtifactIds] = useState<string[]>([])
  const [toggler, setToggler] = useState(false)
  const [sideDrawerTab, setSideDrawerTab] = useState('Notes')

  const [markArtifactAsViewed] = useMarkArtifactAsViewedMutation({
    variables: {
      input: { artifactId: artifact?.id }
    }
  })

  const [createUserCmsActivity] = useCreateUserCmsActivityMutation()

  // TODO: (Stu) need to define primary author in a different way because they are all coming from "contributors" now
  const authorFromArtifact = authors?.[0]
  const location = useLocation()
  const { showFreeTrial } = useFeatureFlags()

  const [generateAiInference] = useGenerateAiInferenceMutation({
    variables: {
      input: {
        actionType: InferableAction.CONTENT_VIEW,
        path: location.pathname
      }
    }
  })

  useEffect(() => {
    if (!user || !location.pathname) return
    generateAiInference()
  }, [user, location, generateAiInference])

  useEffect(() => {
    trackContentStarted({
      content_id: artifact?.id,
      content_name: artifact?.title,
      content_type: 'artifact',
      path: window.location.pathname,
      logged_in: isLoggedIn
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    artifactState.isSavedInternal = artifact?.isSaved

    return () => {
      artifactState.scrollingMenuActive = false
    }
  }, [artifact.isSaved])

  useEffect(() => {
    if (!artifactUserData) return

    const savedArtifactIds = artifactUserData.savedArtifactsForUser
      .map((savedArtifact) => savedArtifact.sanityId)
      .filter((id) => id)

    if (savedArtifactIds) {
      if (savedArtifactIds.includes(artifact?.id)) {
        setBookmark(
          artifactUserData.savedArtifactsForUser.find(
            (ab) => ab.sanityId === artifact?.id
          )
        )
      }
      setSavedArtifactIds(savedArtifactIds as string[])
    }

    const savedCountPerArtifact = artifactUserData.savedCountPerArtifact
    // @TODO: define type!!
    const savedCountForAllArtifactIds: any = {}
    savedCountPerArtifact?.map((countObject) => {
      savedCountForAllArtifactIds[countObject.artifactId] = countObject.count
    })

    if (savedCountForAllArtifactIds) {
      if (savedCountForAllArtifactIds[artifact?.id] !== undefined) {
        artifactState.savedCount = savedCountForAllArtifactIds[artifact?.id]
      } else {
        artifactState.savedCount = 0
      }
    }
  }, [artifactUserData, artifact?.id])

  // Fire the markViewed mutation when the user views the artifact
  useEffect(() => {
    if (user?.id && artifact?.id) {
      markArtifactAsViewed({
        variables: { input: { artifactId: artifact?.id } }
      })
      createUserCmsActivity({
        variables: {
          input: {
            sanityId: artifact.id,
            action: 'viewed'
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Fire an event if the user copies text from the artifact
  useEffect(() => {
    const source = document.querySelector('body')
    const handleCopy = () => {
      const selectionLength = window.getSelection()?.toString().length || 0
      trackCopiedText({
        content_sanity_id: artifact?.id,
        content_name: artifact?.title,
        length: selectionLength
      })
    }
    source?.addEventListener('copy', handleCopy)
    return () => {
      source?.removeEventListener('copy', handleCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backToTop = () => {
    const element = document.getElementById('artifact-viewer')
    element?.scrollIntoView({ behavior: 'smooth' })

    trackNavigationClicked({
      location: 'artifact_detail_page',
      text: 'back to top',
      type: 'button',
      related_identifiers: {
        content_sanity_id: artifact?.id,
        content_title: artifact?.title,
        content_type: 'artifact'
      }
    })
  }

  const notesAndCommentsBtnsContainer = document.getElementById('rf-fixed-bottom-portal')
  const mobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const ARTIFACT_GATE_BUTTON_TEXT =
    showFreeTrial && (user?.is.eligibleForTrial || !isLoggedIn)
      ? 'Start 14-day free trial'
      : 'Become a member'

  return (
    <ArtifactContext.Provider value={artifact}>
      <section
        className={cn('artifact flex justify-center', {
          'px-4 md:px-[4vw]': !isLoggedIn
        })}
        role="contentinfo"
        aria-label="Artifact Viewer"
      >
        <NoteAndCommentButtons
          artifact={artifact}
          className={'hidden'}
          sideDrawerTab={sideDrawerTab}
          sideDrawerOpen={sideDrawerOpen}
          onNoteButtonClick={() => {
            setSideDrawerTab('Notes')
            sendSideDrawerStateEvent({ type: 'note button clicked' })
          }}
          onCommentButtonClick={() => {
            setSideDrawerTab('Comments')
            sendSideDrawerStateEvent({ type: 'comment button clicked' })
          }}
        />

        <div className="relative flex w-full max-w-full 2xl:max-w-[1536px]">
          <div
            id="artifact-container"
            className={twMerge(
              'flex max-w-full flex-1 flex-col lg:h-[calc(100vh_-_72px)] lg:overflow-y-scroll',
              !isLoggedIn && 'lg:ml-0'
            )}
          >
            <div className="artifact--body flex w-full flex-col items-center lg:pr-[20px]">
              <div id="artifact-viewer" className="flex w-full flex-col">
                {fromCourse && (
                  <div className="w-full">
                    <Breadcrumb
                      id="artifact-page-header"
                      title={artifact.title}
                      backTo={backTo}
                    />
                  </div>
                )}

                <div
                  className={twMerge(
                    'm-auto w-full rounded',
                    ['RealWork', 'NotesInArticle'].includes(welcomeMode.step || '') &&
                      'z-[1003]'
                  )}
                >
                  <div className="mb-[40px] rounded bg-rb-white">
                    <div className="artifact--desc-body mb-12 relative rounded border border-rb-gray px-4 pb-8 pt-4 shadow-md md:px-8 md:pt-8">
                      <div className="w-full">
                        <div className="mb-4 flex items-center justify-between gap-4">
                          <div className="flex items-center gap-6">
                            <div className="h-10 w-10 shrink-0">
                              {artifact.companyLogoUrl && (
                                <img
                                  alt={artifact.companyName}
                                  src={artifact.companyLogoUrl}
                                  style={{ width: '40px', height: '40px' }}
                                />
                              )}
                            </div>

                            {artifact.showContentGate && !mobileView && <PremiumBadge />}
                          </div>

                          <div className="gap-15 ml-15 flex items-center justify-end">
                            <ArtifactButtons
                              artifact={artifact}
                              savedCount={artifactState.savedCount}
                              bookmark={bookmark}
                              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                            />
                          </div>
                        </div>

                        {artifact.showContentGate && mobileView && <PremiumBadge />}
                      </div>

                      <ArtifactViewerHero
                        artifactTitle={artifact.title}
                        authors={authors}
                        companyMaturity={artifact.companyMaturity}
                        companyName={artifact.companyName}
                        companyType={artifact.companyType}
                        companyEmployeeCount={artifact.companyNumEmployees}
                        contributors={contributors}
                        summary={artifact.summary}
                      />

                      {artifact.descriptionBody && (
                        <div className="pt-[22px]">
                          <div
                            className={cn(
                              artifact.showContentGate &&
                                'relative h-[400px] overflow-hidden after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-b after:from-transparent after:to-[white_88%]'
                            )}
                          >
                            <BlockContent blocks={artifact.descriptionBody} />
                          </div>
                          {/* when we have time we should try and export the hotspot props from the hotspot marker component as a type */}
                          {artifact.featured?.hotspots?.map((hotspot: any) => (
                            <HotspotMarker key={hotspot._key} hotspot={hotspot} />
                          ))}

                          {artifact.showContentGate && (
                            <ContentGate
                              title={
                                <div className="flex items-center">
                                  Access this artifact with a membership
                                  <DiamondIcon className="w-6 h-6 -mt-3 ml-0.5" />
                                </div>
                              }
                              description={`Get unlimited access to artifacts, guides, on-demand courses and more with ${showFreeTrial && (user?.is.eligibleForTrial || !isLoggedIn) ? 'our 14-day free trial' : 'one of our membership options.'}`}
                              ctaText={ARTIFACT_GATE_BUTTON_TEXT}
                              ctaHref={
                                user
                                  ? user.is.eligibleForTrial
                                    ? `/try-reforge?referer=${window.location.pathname}`
                                    : `/subscribe/choose-plan?referer=${window.location.pathname}`
                                  : `/login?reforgeLogin=true&referer=${encodeURIComponent(
                                      `/subscribe/choose-plan?referer=${window.location.pathname}`
                                    )}`
                              }
                              onClick={() => {
                                trackCtaClicked({
                                  cta_location: 'artifact_paywall',
                                  cta_type: 'button',
                                  text: ARTIFACT_GATE_BUTTON_TEXT,
                                  logged_in: !!user,
                                  related_identifiers: {
                                    content_id: artifact?.id,
                                    content_title: artifact?.title
                                  }
                                })
                              }}
                              descriptionClassName={isLoggedIn ? 'px-12' : ''}
                              divClassName={`bg-rb-blue-50 rounded-2xl border-none ${isLoggedIn ? '!px-5' : ''}`}
                              buttonClassName="mt-4 !font-bold"
                              buttonColor="premium"
                            />
                          )}
                        </div>
                      )}

                      {!!artifact.featured && (
                        <>
                          <div
                            className="relative h-full w-full"
                            onClick={() => setToggler(!toggler)}
                            onKeyDown={() => setToggler(!toggler)}
                            tabIndex={0}
                            role="button"
                          >
                            <img
                              src={artifact.featured.url}
                              alt={artifact.featured.caption}
                              className="featured-image h-full w-full cursor-pointer rounded-sm border border-rb-gray-100 object-cover"
                            />
                            {artifact.featured.hotspots?.map((hotspot: any) => (
                              <HotspotMarker key={hotspot._key} hotspot={hotspot} />
                            ))}
                          </div>
                          <FsLightbox
                            toggler={toggler}
                            sources={[artifact.featured.url]}
                            type="image"
                          />
                        </>
                      )}

                      {user && artifact.isFree && (
                        <div className="relative mt-9 flex flex-row justify-between">
                          <button
                            className="rounded border border-rb-gray-300 bg-rb-white px-4 py-3 text-sm font-semibold tracking-wider text-rb-black hover:border-rb-teal-200 hover:bg-rb-teal-50 hover:text-rb-teal-300"
                            onClick={backToTop}
                          >
                            Back to top
                          </button>
                        </div>
                      )}
                    </div>
                    {isLoggedIn && (
                      <SuggestedPromptsWidget
                        premiumUser={!!user && !user?.is?.premember}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-10 w-full">
              {!fromCourse && artifact.relatedArtifacts?.length && (
                <RelatedArtifactsCarousel
                  title="Related Artifacts"
                  artifacts={artifact.relatedArtifacts.map((a: any) => ({
                    ...a,
                    isSaved: savedArtifactIds.includes(a.id)
                  }))}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                />
              )}
            </div>
          </div>

          <ArtifactViewerSidebar
            artifact={artifact}
            authorFromArtifact={authorFromArtifact}
            setSideDrawerTab={setSideDrawerTab}
            sideDrawerTab={sideDrawerTab}
            sideDrawerState={sideDrawerState}
            sendSideDrawerStateEvent={sendSideDrawerStateEvent}
            activeAnnotation={activeAnnotation}
            setActiveAnnotation={setActiveAnnotation}
            sideDrawerOpen={sideDrawerOpen}
          />
        </div>
      </section>

      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!bookmarkFolders}
      />

      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />

      {notesAndCommentsBtnsContainer &&
        createPortal(
          <div className="mb-4 mr-4 flex justify-end">
            <NoteAndCommentButtons
              artifact={artifact}
              className={'lg:hidden'}
              sideDrawerTab={sideDrawerTab}
              sideDrawerOpen={sideDrawerOpen}
              onNoteButtonClick={() => {
                setSideDrawerTab('Notes')
                sendSideDrawerStateEvent({ type: 'note button clicked' })
              }}
              onCommentButtonClick={() => {
                setSideDrawerTab('Comments')
                sendSideDrawerStateEvent({ type: 'comment button clicked' })
              }}
            />
          </div>,
          notesAndCommentsBtnsContainer
        )}
    </ArtifactContext.Provider>
  )
}

const ARTIFACT_USER_DATA_QUERY = gql`
  query Query($userId: ID!) {
    savedArtifactsForUser(userId: $userId) {
      id
      sanityId
    }
    viewedArtifactsForUser(userId: $userId) {
      artifactId
    }
    savedCountPerArtifact {
      artifactId
      count
    }
  }
`

type ArtifactId = {
  artifactId: string
}

type SavedCount = {
  artifactId: string
  count: number
}

type ArtifactUserDataResponse = {
  savedArtifactsForUser: [ProgramBookmarkPartsFragment]
  savedCountPerArtifact: [SavedCount]
  viewedArtifactsForUser: [ArtifactId]
}

export default ArtifactViewerData
