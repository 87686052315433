import { ErrorMessage, Loading } from 'components'

import { AvailableTeamSubscriptionFragment, useCurrentUserAvailableTeamsQuery } from 'gql'

import {
  AddEmailLink,
  InfoBox,
  JoinableTeam,
  JoinedTeam,
  PendingTeam,
  RejectedTeam
} from './components'
import { sortAvailableSubscriptionsByRequestStatus } from './utils'

export interface DiscoverableTeamsProps {
  activeSubscriptionMembershipCreatedAt?: string
  availableTeamSubscriptions?: AvailableTeamSubscriptionFragment[]
  activeSubscription?: AvailableTeamSubscriptionFragment | null
}

export function DiscoverableTeams({
  activeSubscriptionMembershipCreatedAt,
  availableTeamSubscriptions,
  activeSubscription
}: DiscoverableTeamsProps) {
  const teamsByRequestStatus = sortAvailableSubscriptionsByRequestStatus({
    availableTeamSubscriptions: availableTeamSubscriptions || []
  })

  const { draftTeams, joinableTeams, rejectedTeams, requestedTeams } =
    teamsByRequestStatus
  const shouldShowNotSeeingYourTeamPrompt =
    requestedTeams.length === 0 && joinableTeams.length > 0
  const shouldShowContactTeamManagerPrompt =
    requestedTeams.length === 0 && rejectedTeams.length > 0
  const shouldShowNoTeamsTryADifferentEmailPrompt =
    !activeSubscription && availableTeamSubscriptions?.length === 0
  return (
    <div data-test="account-my-team-individual">
      {shouldShowNoTeamsTryADifferentEmailPrompt && (
        <InfoBox>
          You don’t have a team associated with this email.{' '}
          <AddEmailLink>Try adding and verifying a different email.</AddEmailLink>
        </InfoBox>
      )}
      {activeSubscription && (
        <JoinedTeam
          joinedAt={activeSubscriptionMembershipCreatedAt}
          team={activeSubscription}
        />
      )}
      {requestedTeams.map((team) => (
        <PendingTeam key={team.id} team={team} />
      ))}
      {draftTeams.map((team) => (
        <JoinableTeam key={team.id} team={team} />
      ))}
      {joinableTeams.map((team) => (
        <JoinableTeam key={team.id} team={team} />
      ))}
      {shouldShowNotSeeingYourTeamPrompt && (
        <InfoBox>
          Not seeing your team? Make sure your work email is verified or{' '}
          <AddEmailLink>add a different email</AddEmailLink>
        </InfoBox>
      )}

      {rejectedTeams.map((team) => (
        <RejectedTeam key={team.id} team={team} />
      ))}
      {shouldShowContactTeamManagerPrompt && (
        <InfoBox>
          Contact the team manager at your company to get added to the team plan.
        </InfoBox>
      )}
    </div>
  )
}

export function DiscoverableTeamsWrapper() {
  const { loading, data, error } = useCurrentUserAvailableTeamsQuery()

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data || !data.currentUser) {
    return <ErrorMessage error={'Data retrieval for Team Card failed'} />
  }

  const { currentUser } = data
  const { activeSubscriptionMembership, subscriptions, availableTeamSubscriptions } =
    currentUser

  return (
    <DiscoverableTeams
      activeSubscriptionMembershipCreatedAt={activeSubscriptionMembership?.createdAt}
      activeSubscription={subscriptions?.active}
      availableTeamSubscriptions={availableTeamSubscriptions}
    />
  )
}

export default DiscoverableTeamsWrapper
