import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import UpdateBookmarkFolderModal from 'domains/Collections/UpdateBookmarkFolderModal'

import { CollectionActivityFacepile } from 'components/ActivityFacepile/CollectionActivityFacepile'
import { getContentTypeLabel } from 'components/ContentCard/utils/getContentTypeLabel'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { BookmarkFolderPartsFragment, useDeleteBookmarkFolderMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import notifyError from 'utils/errorNotifier'
import { trackCollectionAction } from 'utils/tracking/analytics'

interface BookmarkFolderCardProps {
  bookmarkFolder: BookmarkFolderPartsFragment
  deleteCallback?: (id: string) => void
  refreshFolders?(): Promise<unknown>
  hasControls?: boolean
}

const BookmarkFolderCard = ({
  bookmarkFolder,
  deleteCallback,
  refreshFolders,
  hasControls
}: BookmarkFolderCardProps) => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { currentUser } = useCurrentUser()

  const bookmarkFolderIsShared = Boolean(bookmarkFolder?.isShared)

  const [deleteBookmarkFolder] = useDeleteBookmarkFolderMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkFolderId = mutationResult?.data?.deleteBookmarkFolder?.id
      if (!deletedBookmarkFolderId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkFolderId,
        __typename: 'BookmarkFolder'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const handleClick = () => {
    trackCollectionAction({
      action: 'collection_clicked',
      location: window.location.pathname,
      collection_id: bookmarkFolder.id,
      collection_name: bookmarkFolder.name,
      is_reforge_collection:
        bookmarkFolder.reforgeCollection || !!bookmarkFolder.forkedFromId
    })
    history.push(`/saved/${bookmarkFolder.id}`)
  }

  const handleUpdate = async () => {
    await setIsModalOpen(true)
  }

  const handleDelete = async () => {
    const { errors } = await deleteBookmarkFolder({
      variables: { input: { bookmarkFolderId: bookmarkFolder.id } }
    })
    if (errors) {
      notifyError(errors)
    } else {
      deleteCallback?.(bookmarkFolder.id)
      refreshFolders?.()
    }
  }

  const deleteFolder = (
    <DropdownContextMenu.DropdownItem
      text="Delete Collection"
      onClick={() => handleDelete()}
      className="w-[154px]"
    />
  )

  const updateFolder = (
    <DropdownContextMenu.DropdownItem
      text="Edit Collection"
      onClick={() => handleUpdate()}
      className="w-[154px]"
    />
  )

  return (
    <div>
      {hasControls && (
        <UpdateBookmarkFolderModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          bookmarkFolder={bookmarkFolder}
        />
      )}
      <div
        onClick={handleClick}
        className="rf-rb-card-interactive justify-top relative flex h-44 flex-col px-2"
        onKeyUp={handleClick}
        tabIndex={0}
        role="button"
      >
        {hasControls && (
          <div
            className="absolute right-4 top-2 flex h-1/3"
            role="button"
            tabIndex={0}
            onKeyUp={(e) => {
              e.stopPropagation()
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {bookmarkFolder.user.id === currentUser?.id && (
              <DropdownContextMenu
                positions={['bottom']}
                reposition={true}
                boundaryInset={54}
                dismissOnClick
              >
                {updateFolder}
                {deleteFolder}
              </DropdownContextMenu>
            )}
          </div>
        )}
        <div className="flex h-full w-full flex-col px-2 pt-4">
          <RfParagraphMini className="mb-4 flex items-center">
            <span className="flex items-center font-medium text-rb-gray-500">
              {getContentTypeLabel(
                null,
                null,
                'Collection',
                null,
                bookmarkFolderIsShared
              )}
            </span>
          </RfParagraphMini>
          <h1 className="mb-4 overflow-hidden text-[20px] font-medium leading-[20px] tracking-normal line-clamp-2">
            {bookmarkFolder.name}
          </h1>
          <div className="mt-2 mb-2 flex grow flex-row items-end justify-between gap-4">
            <CollectionActivityFacepile collectionId={bookmarkFolder.id} inCard={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookmarkFolderCard
