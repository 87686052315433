import { formatInTimeZone } from 'date-fns-tz'
import dayjs from 'dayjs'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import CourseDetailEnrollmentBadges from 'domains/CourseDetail/CourseDetailEnrollmentBadges'

import Button, { ButtonColors } from 'components/Button'
import { FacePile } from 'components/FacePile'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'

import {
  CclCourseSessionPartsFragment,
  CclSessionEvent,
  CourseDetailPartsFragment,
  useTrackCourseEnrollmentStartedMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  MONTH_ABBREV_DATE_FORMAT,
  getFormattedStartDate,
  getTimezoneAbbreviation,
  isBeforeDate
} from 'utils/date'
import { trackCtaClicked } from 'utils/tracking/generated/events'

import { ReactComponent as PeopleAvatar } from 'images/p-people.svg'

interface CourseDetailUpcomingSessionProps {
  session: CclCourseSessionPartsFragment
  courseDetails: CourseDetailPartsFragment
  showEvents?: Boolean
  buttonColor?: ButtonColors
  pillPosition?: 'top' | 'inline'
}

const CourseDetailUpcomingSession = ({
  session,
  courseDetails,
  showEvents = true,
  buttonColor = 'default',
  pillPosition = 'inline'
}: CourseDetailUpcomingSessionProps) => {
  const { currentUser } = useCurrentUser()
  const history = useHistory()

  const userTimeZone =
    currentUser?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  const [trackCourseEnrollmentStarted] = useTrackCourseEnrollmentStartedMutation()

  const formattedDateRange = `${getFormattedStartDate(
    session?.startsAt,
    userTimeZone,
    MONTH_ABBREV_DATE_FORMAT
  )} - ${getFormattedStartDate(session?.endsAt, userTimeZone, MONTH_ABBREV_DATE_FORMAT)}`

  const onEnrollClick = () => {
    if (isSessionEnrollable && session.isEnrollmentFull) {
      history.push('/courses-marketplace-waitlist/course-full')
      return
    }
    if (!courseDetails?.slug) return

    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'course_details_page',
      text: 'Enroll',
      related_identifiers: {
        ccl_course_id: courseDetails.id,
        course_name: courseDetails.title,
        course_sanity_id: courseDetails.sourceId,
        ccl_course_session_id: session?.id,
        course_session_sanity_id: session?.sourceId
      }
    })

    trackCourseEnrollmentStarted({
      variables: {
        input: {
          ctaLocation: 'course_details_page',
          courseSlug: courseDetails.slug,
          cclCourseSessionId: session.id
        }
      }
    })

    const formattedStartDate = dayjs(session?.startsAt).format('YYYY-MM-DD')
    window.location.href = `/course-payment/${courseDetails.slug}/${formattedStartDate}`
  }

  const getFormattedEventDate = (event: CclSessionEvent) => {
    const timezoneAbbreviation = getTimezoneAbbreviation(userTimeZone, event.startsAt)

    return `${formatInTimeZone(
      event?.startsAt || '',
      userTimeZone!,
      'EEEE, MMM d h:mm a'
    )} 
    - ${formatInTimeZone(
      event?.endsAt || '',
      userTimeZone!,
      'h:mm a'
    )} ${timezoneAbbreviation}`
  }

  const isSessionEnrollable = session?.isDuringEnrollmentPeriod || false

  let orderedSessionEvents: any[] = []
  if (session.events) {
    orderedSessionEvents = [...session.events].sort((a: any, b: any) => {
      return isBeforeDate(b.startsAt, a.startsAt) ? -1 : 1
    })
  }
  return (
    <div
      className={twMerge(
        'mr-6 flex w-full max-w-[360px] flex-col gap-6 rounded-xl border p-6 bg-rb-white',
        isSessionEnrollable ? 'border-rb-gray-300' : 'border-rb-gray-100'
      )}
    >
      {/* The pill text wraps when placed inside the right-hand enroll CTA due to width
          Move the pill above the dates to keep the pill shape.
       */}
      {pillPosition === 'top' && (
        <div>
          <CourseDetailEnrollmentBadges
            courseSession={session}
            isSessionEnrollable={isSessionEnrollable}
          />
        </div>
      )}
      <div className="flex items-center justify-between gap-2">
        <RfHeader3SemiBold className="!mb-0">{formattedDateRange}</RfHeader3SemiBold>
        {pillPosition === 'inline' && (
          <CourseDetailEnrollmentBadges
            courseSession={session}
            isSessionEnrollable={isSessionEnrollable}
          />
        )}
      </div>

      {/* Start Events */}

      {showEvents &&
        (orderedSessionEvents.length > 0 ? (
          <div className="space-y-2">
            <RfParagraphSmallSemiBold>Events</RfParagraphSmallSemiBold>
            {orderedSessionEvents.map((event) => (
              <RfParagraphSmall key={event.id}>
                {getFormattedEventDate(event)}
              </RfParagraphSmall>
            ))}
          </div>
        ) : (
          <div className="space-y-2">
            <RfParagraphSmallSemiBold>
              <span className="text-rb-gray-300">Events</span>
            </RfParagraphSmallSemiBold>
            <RfParagraphSmall>
              <span className="text-rb-gray-300">Event schedule coming soon</span>
            </RfParagraphSmall>
          </div>
        ))}

      {/* End Events */}

      {!!session.experts && session.experts.length > 0 ? (
        <div className="space-y-2">
          <RfParagraphSmallSemiBold>
            Host{session.experts.length > 1 ? 's' : ''}
          </RfParagraphSmallSemiBold>
          <div className="flex items-center">
            <FacePile
              users={session.experts.map((expert) => ({
                id: expert.id,
                avatarUrl: expert.avatarUrl || ''
              }))}
              imageSize="small"
              className="mr-1"
            />
            {session.experts.map((expert, index, array) => (
              <React.Fragment key={expert.id}>
                <Link to={`/profiles/${expert.slug}`} rel="noreferrer">
                  <RfParagraphSmall>{expert.name}</RfParagraphSmall>
                </Link>
                {index < array.length - 1 && <span className="mr-1">,</span>}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          <RfParagraphSmallSemiBold>
            <span className="text-rb-gray-300">Hosts</span>
          </RfParagraphSmallSemiBold>
          <div className="flex items-center gap-1">
            <div className="flex items-center justify-center rounded-full bg-rb-gray-100 p-[5px]">
              <PeopleAvatar className="h-2.5 w-2.5 shrink-0" />
            </div>

            <RfParagraphSmall>
              <span className="text-rb-gray-300">To be announced</span>
            </RfParagraphSmall>
          </div>
        </div>
      )}

      <Button
        className="!mt-auto normal-case"
        variant={!session.isEnrollmentFull ? 'fill' : 'text-only'}
        color={buttonColor}
        fullWidth
        size="small"
        onClick={onEnrollClick}
        disabled={!isSessionEnrollable}
      >
        {isSessionEnrollable
          ? session.isEnrollmentFull
            ? 'Join Waitlist'
            : 'Enroll'
          : 'Enrollment opening soon'}
      </Button>
    </div>
  )
}

export default CourseDetailUpcomingSession
