import React, { useEffect, useState } from 'react'

import AvatarStack from 'components/AvatarStack'
import Button from 'components/Button'
import { Modal, ModalContent, ModalFooter } from 'components/Modal'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  AvailableTeamSubscriptionFragment,
  CurrentUserAvailableTeamsDocument,
  useCreateSubscriptionJoinRequestMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { FULL_DATE_FORMAT, formatInTimezone, getCurrentTimezone } from 'utils/date'
import {
  trackCtaClicked,
  trackModalDismissed,
  trackModalDisplayed
} from 'utils/tracking/analytics'

import AddEmailModal from './AddEmailModal'
import FallbackTeamLogo from './FallbackTeamLogo'
import { getSubscriptionMembers } from './utils'

export function JoinedTeam({
  team,
  joinedAt
}: {
  team: AvailableTeamSubscriptionFragment
  joinedAt?: string
}) {
  return (
    <TeamRow>
      <TeamLogo logoUrl={team.logoUrl} />
      <TeamInfoColumn>
        <TeamInfo team={team} showDetails />
        <AcceptedRequestStatus joinedAt={joinedAt} />
      </TeamInfoColumn>
    </TeamRow>
  )
}

export function PendingTeam({ team }: { team: AvailableTeamSubscriptionFragment }) {
  return (
    <TeamRow>
      <TeamLogo logoUrl={team.logoUrl} />
      <TeamInfoColumn>
        <TeamInfo team={team} showDetails />
        <RequestedToJoinButton />
      </TeamInfoColumn>
    </TeamRow>
  )
}

export function JoinableTeam({ team }: { team: AvailableTeamSubscriptionFragment }) {
  return (
    <TeamRow>
      <TeamLogo logoUrl={team.logoUrl} />
      <TeamInfoColumn>
        <TeamInfo team={team} showDetails />
        <RequestToJoinButton teamId={team.id} teamName={team.teamName} />
      </TeamInfoColumn>
    </TeamRow>
  )
}

export function RejectedTeam({ team }: { team: AvailableTeamSubscriptionFragment }) {
  return (
    <div className="opacity-50">
      <TeamRow>
        <RejectedTeamLogo />
        <TeamInfoColumn>
          <TeamInfo team={team} showDetails={false} />
          <DeniedRequestStatus />
        </TeamInfoColumn>
      </TeamRow>
    </div>
  )
}

export function InfoBox({ children }: { children: React.ReactNode | string }) {
  return <div className="my-2 rounded bg-neutral-100 p-3">{children}</div>
}

function StatusDot({ color }: { color: string }) {
  return <div className={`h-2 w-2  rounded-full bg-${color} mr-1 inline-block`} />
}

export function TeamRow({ children }: { children: React.ReactNode | string }) {
  return <div className="my-5 flex flex-row">{children}</div>
}

function TeamMembershipStatus({ children }: { children: React.ReactNode | string }) {
  return <div className="items-start justify-self-end">{children}</div>
}

export function TeamInfoColumn({ children }: { children: React.ReactNode | string }) {
  return (
    <div className="ml-3 flex w-full flex-col sm:flex-row sm:items-center sm:justify-between">
      {children}
    </div>
  )
}

export function TeamLogo({ logoUrl }: { logoUrl?: string | null }) {
  return (
    <div className="flex grow-0">
      <div className="flex max-h-20 w-20 flex-none items-center justify-center rounded">
        {logoUrl ? <img src={logoUrl} alt="" className="w-full" /> : <FallbackTeamLogo />}
      </div>
    </div>
  )
}

function RejectedTeamLogo() {
  return (
    <div className="flex grow-0">
      <div className="h-20 w-20 rounded bg-rb-gray-100" />
    </div>
  )
}

export function TeamInfo({
  team,
  showDetails
}: {
  team: Pick<AvailableTeamSubscriptionFragment, 'user' | 'teamName' | 'seats'>
  showDetails: boolean
}) {
  const { teamName, user, seats } = team
  const subscriptionMembers = seats ? getSubscriptionMembers(seats) : []
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  return (
    <div className="flex grow flex-col items-start justify-center">
      <div className="my-0.5 text-lg font-semibold leading-5">{teamName}</div>
      {user?.fullName ? (
        <div className="mb-2 text-sm font-normal leading-5">
          Team Manager: {user.fullName}
        </div>
      ) : (
        ''
      )}

      {subscriptionMembers && (
        <div className="flex text-xs">
          <AvatarStack
            members={subscriptionMembers.map((member) => ({
              ...member?.user,
              hasBadge: false,
              avatarUrl: showDetails ? member.user.avatarUrl : '',
              fullName: member.user.fullName
            }))}
            avatarSize={isMobile ? 24 : 30}
            maximumAvatars={4}
            totalCount={subscriptionMembers.length}
          />
          <span className="pl-2 text-sm font-medium leading-8 text-rb-gray-400">
            {subscriptionMembers.length} members
          </span>
        </div>
      )}
    </div>
  )
}

export function TeamInfoSlim({
  teamManagerName,
  teamName,
  teamMemberCount
}: {
  teamManagerName?: string | null
  teamName?: string | null
  teamMemberCount?: number
}) {
  return (
    <div className="flex grow flex-col items-start justify-center gap-y-0.5 leading-5 ">
      <div className="text-lg font-semibold">{teamName}</div>
      {teamManagerName && (
        <div className="truncate text-sm font-normal">
          Team Manager: {teamManagerName}
        </div>
      )}
      <span className="text-dl-input-text text-sm font-normal">
        {teamMemberCount} members
      </span>
    </div>
  )
}

function RequestedToJoinButton() {
  return (
    <TeamMembershipStatus>
      <Button
        shape={'rounded-full'}
        size="small"
        type="button"
        disabled
        className={'h-7 px-2 sm:h-11 sm:w-40 sm:self-end md:mr-3'}
      >
        Requested To Join
      </Button>
    </TeamMembershipStatus>
  )
}

function RequestToJoinButton({
  teamId,
  teamName
}: {
  teamId: string
  teamName?: string | null
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const getTrackingLocationString = () => {
    const pathname = window.location.pathname
    if (pathname.includes('/account')) {
      return 'account_page'
    } else if (pathname === '/') {
      return 'main_dashboard'
    } else {
      return ''
    }
  }

  const openRequestToJoinTeamModal = () => {
    setModalIsOpen(true)
    trackCtaClicked({
      cta_location: `${getTrackingLocationString()}_team_card`,
      cta_type: 'button',
      related_identifiers: {
        team_id: teamId
      },
      text: 'request to join'
    })
  }

  return (
    <TeamMembershipStatus>
      <Button
        shape="rounded-full"
        size="small"
        type="button"
        onClick={openRequestToJoinTeamModal}
        className="h-7 px-4 sm:h-11 sm:w-40 sm:self-end md:mr-3"
      >
        Request To Join
      </Button>
      <RequestToJoinTeamModal
        teamId={teamId}
        teamName={teamName}
        isModalOpen={modalIsOpen}
        handleCloseModal={closeModal}
      />
    </TeamMembershipStatus>
  )
}

function RequestToJoinTeamModal({
  teamId,
  teamName,
  isModalOpen,
  handleCloseModal
}: {
  teamId: string
  teamName?: string | null
  isModalOpen: boolean
  handleCloseModal: () => void
}) {
  const [createSubscriptionJoinRequest] = useCreateSubscriptionJoinRequestMutation()

  const onRequestToJoinClick = (teamId: string) => {
    createSubscriptionJoinRequest({
      variables: {
        input: {
          subscriptionId: teamId
        }
      },
      refetchQueries: [CurrentUserAvailableTeamsDocument]
    }).then(() => {
      handleCloseModal()
    })
    trackCtaClicked({
      cta_location: 'discover_teams_send_request_modal',
      cta_type: 'button',
      related_identifiers: {
        team_id: teamId
      },
      text: 'request to join'
    })
  }

  useEffect(() => {
    if (isModalOpen) {
      trackModalDisplayed({
        category: 'app',
        modal_group: 'discover teams',
        modal_name: 'send request to join'
      })
    } else {
      trackModalDismissed({
        category: 'app',
        modal_group: 'discover teams',
        modal_name: 'send request to join'
      })
    }
  }, [isModalOpen])

  return (
    <Modal isOpen={isModalOpen} handleClose={handleCloseModal}>
      <ModalContent>
        <p className="text-center text-2xl text-black">
          Request to join team: {teamName}
        </p>
      </ModalContent>
      <ModalFooter className="justify-center">
        <div className="flex justify-center">
          <Button variant="text-only" className="rounded-sm" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="rounded-sm"
            onClick={() => onRequestToJoinClick(teamId)}
          >
            Send Request
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

function DeniedRequestStatus() {
  return (
    <TeamMembershipStatus>
      <StatusDot color="rb-gray-100" />
      Declined
    </TeamMembershipStatus>
  )
}

function AcceptedRequestStatus({ joinedAt }: { joinedAt?: string }) {
  return (
    <TeamMembershipStatus>
      <div>
        <StatusDot color="rb-success-100" />
        Joined
      </div>
      {joinedAt && (
        <div className="text-rb-gray-300">
          {formatInTimezone(joinedAt, getCurrentTimezone(), FULL_DATE_FORMAT)}
        </div>
      )}
    </TeamMembershipStatus>
  )
}

export function AddEmailLink({ children }: { children: string }) {
  const [isOpenAddEmailModal, setIsOpenAddEmailModal] = useState(false)

  const handleOpenModal = () => {
    setIsOpenAddEmailModal(true)
    trackCtaClicked({
      cta_location: 'account_page_team_card',
      cta_type: 'hyperlink text',
      text: 'Try using a different email'
    })

    trackModalDisplayed({
      category: 'app',
      modal_group: 'add email address',
      modal_name: 'add email address'
    })
  }

  return (
    <>
      <button
        data-test={'open-add-email-modal-btn'}
        className="font-normal text-rb-teal-200"
        onClick={handleOpenModal}
      >
        {children}
      </button>
      <AddEmailModal isOpen={isOpenAddEmailModal} setIsOpen={setIsOpenAddEmailModal} />
    </>
  )
}
