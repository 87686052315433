import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

import { formatTimeAsMinutesSeconds } from 'utils/date'
import { isIOS } from 'utils/device.utils'

import { ReactComponent as CloseIcon } from 'images/icon--close.svg'
import { ReactComponent as Megaphone } from 'images/icon--megaphone-white.svg'
import { ReactComponent as PauseIcon } from 'images/icon--pause-outlined.svg'
import { ReactComponent as PlayIcon } from 'images/icon--play-outlined.svg'

import { useAudioPlayerRef } from './useAudioPlayerRef'
import { AudioPlayerTrackingMetadata, useTrackAudioPlayer } from './useTrackAudioPlayer'
import { calculateProgressPercent } from './utils'

interface CurrentTimeOverride {
  currentTime: number
  setCurrentTime?: Dispatch<SetStateAction<number>>
}

const useCurrentTime = (currentTimeOverride?: CurrentTimeOverride) => {
  const [currentTime, setCurrentTime] = useState(currentTimeOverride?.currentTime || 0)

  return {
    currentTime: currentTimeOverride?.currentTime || currentTime,
    setCurrentTime: currentTimeOverride?.setCurrentTime || setCurrentTime
  }
}

interface ProgressProps {
  currentTimeOverride?: CurrentTimeOverride
  audioRef: MutableRefObject<HTMLAudioElement>
}

const Progress = ({ audioRef, currentTimeOverride }: ProgressProps) => {
  const { currentTime, setCurrentTime } = useCurrentTime(currentTimeOverride)

  const [timeUpdatedInternally, setTimeUpdatedInternally] = useState(false)

  useEffect(() => {
    // if setCurrentTime is set outside of ontimeupdate (via currentTimeOverride.setCurrentTime), update the currentTime on the audioRef to match
    //
    // Because of the slightly milliseconds-long delay from the audioRef.current.currentTime being updated to the
    // currentTime being set in ontimeupdate, currentTime and audioRef.current.currentTime never match eachother to the millisecond.
    // To only detect external updates of currentTime (via currentTimeOverride.setCurrentTime),
    // only update audioRef.current.currentTime in the cases where currentTime didn't get updated internally
    if (currentTime !== audioRef.current.currentTime && !timeUpdatedInternally) {
      audioRef.current.currentTime = currentTime
    }
    setTimeUpdatedInternally(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  const [duration, setDuration] = useState(0)

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events

    audioRef.current.ondurationchange = () => {
      // set duration to display in audio player once duration has changed
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/durationchange_event
      setDuration(audioRef.current.duration)
    }

    audioRef.current.ontimeupdate = () => {
      setTimeUpdatedInternally(true)
      setCurrentTime(audioRef.current.currentTime)
    }

    // make copy in case audioRef is unavailable on dismount handling
    const audioRefClone = { ...audioRef }

    return () => {
      // cleanup event handlers on dismount
      audioRefClone.current.ontimeupdate = null
      audioRefClone.current.ondurationchange = null
    }
  })

  const onScrub = (value: string) => {
    audioRef.current.currentTime = Number(value)
  }

  const progressPercent = calculateProgressPercent(audioRef.current)

  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="relative h-1 w-full sm:w-[150px] bg-rb-gray-300 rounded">
        <input
          type="range"
          aria-label="Scrub"
          data-testid="progress-control"
          className="absolute top-0 left-0 z-2 h-full w-full cursor-pointer opacity-0"
          step="1"
          min="0"
          max={duration}
          onChange={(e) => onScrub(e.target.value)}
        />
        <div
          style={{
            width: `${progressPercent}%`
          }}
          className="absolute inset-0 h-full bg-rb-white rounded"
        />
      </div>
      <div className="h-4 flex flex-row w-full sm:w-[150px] justify-between text-white font-normal text-xs">
        <div>{formatTimeAsMinutesSeconds(currentTime)}</div>
        <div>-{formatTimeAsMinutesSeconds(duration - currentTime)}</div>
      </div>
    </div>
  )
}

interface PlayerControlsProps {
  audioRef: MutableRefObject<HTMLAudioElement>
  trackingMetadata: AudioPlayerTrackingMetadata
}

const PlayerControls = ({ audioRef, trackingMetadata }: PlayerControlsProps) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [showVolumeControl, setShowVolumeControl] = useState(false)

  const { trackCompleted, trackPaused, trackPlayed } = useTrackAudioPlayer({
    trackingMetadata
  })

  const handleVolumeChange = (volume: number) => {
    audioRef.current.volume = volume
  }

  const toggleShowVolumeControl = () => {
    setShowVolumeControl(!showVolumeControl)
  }

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events

    audioRef.current.onended = () => {
      // when get to end of audio, show play button again
      setIsPlaying(false)

      // reload the media but do not autoplay again
      // this cleanly resets progress
      audioRef.current.load()
      audioRef.current.autoplay = false

      trackCompleted(audioRef)
    }

    audioRef.current.onpause = () => {
      setIsPlaying(false)
      if (!audioRef.current.ended) {
        // pause seems to be triggered on track end, so do not track it in this case
        trackPaused(audioRef)
      }
    }

    audioRef.current.onplay = () => {
      setIsPlaying(true)
      trackPlayed(audioRef)
    }

    // make copy in case audioRef is unavailable on dismount handling
    const audioRefClone = { ...audioRef }

    return () => {
      // cleanup event handlers on dismount
      audioRefClone.current.onended = null
      audioRefClone.current.onpause = null
      audioRefClone.current.onplay = null
    }
  })

  const handleTogglePlay = () => {
    isPlaying ? audioRef.current.pause() : audioRef.current.play()
    setIsPlaying(!isPlaying)
  }

  return (
    <div className="flex flex-row w-full">
      {!isIOS() && ( // volume control is prevented on iOS, so hide button to control volume
        <Button
          title="Volume"
          data-testid="volume-button"
          variant="text-only"
          className="text-rb-white w-6 bg-transparent hover:bg-transparent active:bg-transparent p-0"
          onClick={toggleShowVolumeControl}
        >
          <Megaphone className="w-full h-full" data-testid="volume-icon" />
        </Button>
      )}
      <div className="w-full sm:w-[150px] h-8 text-center">
        {showVolumeControl && (
          <div className="bg-rb-gray-400 mt-0.5 px-2 pb-2 ml-4 rounded-3xl">
            <input
              aria-label="Volume"
              data-testid="volume-control"
              type="range"
              style={{ accentColor: 'white', height: '2px' }}
              className="h-0.5 w-full size-2 cursor-pointer"
              id="volume"
              name="volume"
              min="0"
              step=".1"
              max="1"
              onChange={(e) => handleVolumeChange(Number(e.target.value))}
            />
          </div>
        )}
        {!showVolumeControl && (
          <Button
            title={isPlaying ? 'Pause' : 'Play'}
            variant="text-only"
            data-testid="play-pause-button"
            className="h-8 p-0 mx-auto text-rb-white bg-transparent hover:bg-transparent active:bg-transparent"
            onClick={handleTogglePlay}
            aria-label={isPlaying ? 'Pause' : 'Play'}
          >
            {isPlaying ? (
              <PauseIcon width={32} height={32} data-testid="pause-icon" />
            ) : (
              <PlayIcon width={32} height={32} data-testid="play-icon" />
            )}
          </Button>
        )}
      </div>
    </div>
  )
}

interface AudioPlayerProps {
  src: string
  currentTimeOverride?: CurrentTimeOverride
  trackingMetadata: AudioPlayerTrackingMetadata
  autoplay?: boolean
  showCloseButton?: boolean
  onClose?: (audioRef?: MutableRefObject<HTMLAudioElement>) => void
}

export const AudioPlayer = ({
  src,
  trackingMetadata,
  currentTimeOverride,
  autoplay = false,
  showCloseButton = false,
  onClose = () => {}
}: AudioPlayerProps) => {
  const audioRef = useAudioPlayerRef(src)
  audioRef.current.autoplay = autoplay

  useEffect(() => {
    // on mount
    if (currentTimeOverride) {
      audioRef.current.currentTime = currentTimeOverride.currentTime
    }

    // on dismount
    return () => handleAudioPlayerDismount()

    // pass in empty array as this useEffect should just be in effect on mount and dismount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAudioPlayerDismount = () => {
    // make copy in case audioRef is unavailable on dismount handling
    const audioRefClone = { ...audioRef }

    if (!audioRefClone.current.paused) {
      // always pause audio on dismount if audio not already paused
      audioRefClone.current.pause()
      // need to manually trigger the onpause event here because it does not seem to fire
      // in this dismount handler on its own.
      audioRefClone.current.onpause && audioRefClone.current.onpause(new Event('pause'))
    }

    // call onClose first before inactivating the media source
    onClose(audioRefClone)

    // this inactivates the media source for the AudioPlayer
    // so that it is removed from the mobile lock screen
    audioRefClone.current.src = ''
  }

  // TODO: styling improvements for this component https://reforge.atlassian.net/browse/REF-15738
  return (
    <div
      data-testid="audio-player"
      className={
        showCloseButton
          ? 'bg-rb-black pl-6 pb-6 pt-3 px-3 rounded-2xl flex flex-col w-full sm:w-fit'
          : ''
      }
    >
      {showCloseButton && (
        <div className="w-full">
          <Button
            title="Close"
            data-testid="close-button"
            variant="text-only"
            className="p-0 ml-auto float-right text-rb-white bg-transparent hover:bg-transparent active:bg-transparent"
            onClick={() => onClose(audioRef)}
          >
            <CloseIcon data-testid="close-icon" fill="white" width={16} />
          </Button>
        </div>
      )}
      <div
        className={twMerge(
          'bg-rb-black sm:flex-row sm:space-x-20 space-y-6 sm:space-y-0 rounded-2xl flex flex-col w:full sm:w-fit p-4',
          showCloseButton ? 'pb-0 pt-4 sm:pt-0 px-2 sm:pl-3 sm:pr-12' : ''
        )}
      >
        <Progress currentTimeOverride={currentTimeOverride} audioRef={audioRef} />
        <PlayerControls trackingMetadata={trackingMetadata} audioRef={audioRef} />
      </div>
    </div>
  )
}

export default AudioPlayer
