import { useHistory } from 'react-router-dom'

import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { onEnterKeyPress } from 'utils/keyboard'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const ArtifactItem = ({
  searchDocument,
  searchQuery,
  onResultClick
}: ContentResultItemProps) => {
  const history = useHistory()
  const { author, companyName, tags, title, thumbnail, url } = searchDocument

  const handleClick = () => {
    onResultClick()
    history.push(url)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      onClick={handleClick}
      onKeyUp={onEnterKeyPress(handleClick)}
    >
      <div className="relative flex-1">
        <RfParagraphLarge className="mb-0 font-medium">
          <span dangerouslySetInnerHTML={{ __html: formatTitle(searchQuery, title) }} />
        </RfParagraphLarge>
        <div className="mt-1">
          <div>
            <span className="font-medium">{author}</span>
            {!!companyName && <span className="hidden lg:inline"> @ {companyName}</span>}
          </div>
          <div className="mt-4 flex items-center justify-center space-x-2 sm:items-start sm:justify-start">
            {tags.map((tag, idx) => {
              return (
                <div
                  key={`tag-${idx}`}
                  className="text-ellipsis rounded-full bg-rb-gray-100 py-1 px-3 text-sm line-clamp-1 sm:text-xs"
                >
                  {tag}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="relative hidden pl-6 sm:block">
        {thumbnail && (
          <img
            src={thumbnail}
            className="h-fit max-h-28 w-28 object-right lg:w-44"
            alt=""
          />
        )}
      </div>
    </div>
  )
}

export default ArtifactItem
