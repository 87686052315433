import { SearchSuggestionsBox } from 'domains/Search/SearchSuggestions'
import { SearchLinkType } from 'domains/Search/useSearchResultsFilters'

import { SkeletonSearchResultItem } from 'components/skeletons/cards/SkeletonSearchResultItem'
import SkeletonRfHeader from 'components/skeletons/typography/SkeletonRfHeader'

import { SimilarityContentFragment } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import EmptySearchItem from './EmptySearchItem'
import { ResultHighlights } from './ResultHighlights'
import SimilaritySearchResultSection, {
  UserCanProps
} from './SimilaritySearchResultSection'

interface SimilaritySearchTopResultsListProps {
  searchQuery: string
  userCan: UserCanProps
  handleResultClick: (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent?: string,
    type?: string,
    subtype?: string | null,
    doc?: object | null
  ) => void
  itemsPerSection?: number
  hideHeader?: boolean
  loading: boolean
  searchResults?: SimilarityContentFragment[]
  showViewMoreLink?: boolean
  linkType: SearchLinkType
  suggestions?: string[]
}

const SimilaritySearchTopResultsList = ({
  searchQuery,
  handleResultClick,
  userCan,
  loading,
  searchResults,
  itemsPerSection = 3,
  suggestions = []
}: SimilaritySearchTopResultsListProps) => {
  const { showAiSidekick } = useFeatureFlags()

  if (loading) {
    return (
      <div>
        <SkeletonRfHeader className="mb-2 mt-4 h-8 w-1/4" />
        <div className="mt-6">
          {Array.from({ length: itemsPerSection }).map((_, i) => (
            <SkeletonSearchResultItem className="mb-4" key={i} />
          ))}
        </div>
      </div>
    )
  }

  if (!searchResults || searchResults.length === 0) {
    return <EmptySearchItem message={'No matching results'} searchQuery={searchQuery} />
  }

  const [topResult, ...remainingResults] = searchResults

  const groupedResults = [
    {
      label: 'Courses',
      linkType: SearchLinkType.COURSE,
      results: remainingResults.filter((r) => r.contentType === 'Course')
    },
    {
      label: 'Artifacts',
      linkType: SearchLinkType.ARTIFACT,
      results: remainingResults.filter((r) => r.contentType === 'Artifact')
    },
    {
      label: 'Guides',
      linkType: SearchLinkType.GUIDE,
      results: remainingResults.filter((r) => r.contentType === 'Guide')
    }
  ]
  if (userCan.viewSearchTabs) {
    groupedResults.push({
      label: 'Program Content',
      linkType: SearchLinkType.CMS_SECTION,
      results: remainingResults.filter((r) => r.contentType === 'Lesson')
    })
  }

  const handleTopResultClick = handleResultClick.bind(null, true)
  const handleOtherResultClick = handleResultClick.bind(null, false)

  return (
    <>
      <SimilaritySearchResultSection
        sectionHeader="Top Result"
        sectionResults={[topResult]}
        sectionCount="more"
        showViewMoreLink={false}
        userCan={userCan}
        linkType={SearchLinkType.TOP_RESULTS}
        handleResultClick={handleTopResultClick}
        searchQuery={searchQuery}
        itemsDisplayed={1}
      />
      {showAiSidekick && (
        <div className="lgr:hidden">
          <ResultHighlights key={searchQuery} />
        </div>
      )}
      <SearchSuggestionsBox suggestions={suggestions} />
      {groupedResults.map(({ label, linkType, results }, idx) => (
        <SimilaritySearchResultSection
          key={`sim-top-results-${label}`}
          sectionHeader={label}
          sectionResults={results}
          sectionCount="more"
          userCan={userCan}
          linkType={linkType}
          handleResultClick={handleOtherResultClick}
          searchQuery={searchQuery}
          itemsDisplayed={itemsPerSection}
          searchPositionOffset={1 + idx * itemsPerSection}
        />
      ))}
    </>
  )
}

export default SimilaritySearchTopResultsList
