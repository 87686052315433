import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'

import { ChoosePlanPageWrapper } from 'pages/PurchaseFlow/ChoosePlanPage'
import { PaymentDetailsPage } from 'pages/PurchaseFlow/PaymentDetailsPage'
import { SelectAddOnsPage } from 'pages/PurchaseFlow/SelectAddOnsPage'

import { PlanName, useCohortPassPriceTiersQuery, usePlansForSaleQuery } from 'gql'
import { CohortPassPriceTier, PlanForSale } from 'gql/index'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { track } from 'utils/tracking/analytics'
import { PURCHASE_FLOW_URL } from 'utils/url'

import { PurchaseCompleted } from '../PurchaseCompletedPage'
import { PurchaseConfirmationPageWrapper } from '../PurchaseConfirmationPage'
import { getRefererUrl } from '../helpers'

export const PURCHASE_TYPE_MEMBERSHIP: string = 'membership'
export const PURCHASE_TYPE_COURSE: string = 'course'

export const steps = [
  { path: 'choose-plan', component: <ChoosePlanPageWrapper /> },
  { path: 'select-add-ons', component: <SelectAddOnsPage /> },
  { path: 'payment-details', component: <PaymentDetailsPage /> },
  { path: 'confirm', component: <PurchaseConfirmationPageWrapper /> },
  { path: 'success', component: <PurchaseCompleted /> }
]
export const PURCHASE_FLOW_BASEPATH = PURCHASE_FLOW_URL
export const DISCOUNTED_COHORT_PASS_PRICE = 995
export const DISCOUNTED_COHORT_PASS_SAVINGS = 500
const DISCOUNTED_COHORT_PASS_QUANTITY = 1
const DISCOUNTED_COHORT_PASS_PLAN_NAME = PlanName.INDIVIDUAL
interface PurchaseFlowContextProps {
  cohortPassPriceTiers: CohortPassPriceTier[]
  countryCode: string | null
  discountedCohortPassPrice: number
  discountedCohortPassQuantity: number
  planName: PlanName | null
  planPrice: number
  planSeatCount: number
  plansForSale: PlanForSale[]
  cohortPassQuantity: number
  cohortPassPrice: number
  currentStep: number
  error: string | null
  selectedPaymentMethodId: string | null
  subTotal: number
  orderTotal: number
  selectPlan: (plan: PlanName | null) => void
  goToNextStep: () => void
  skipOneStep: () => void
  setCountryCode: (countryCode: string) => void
  setDiscountedCohortPassPrice: (discountedCohortPassPrice: number) => void
  setDiscountedCohortPassQuantity: (discountedCohortPassQuantity: number) => void
  setPlanName: (planName: PlanName | null) => void
  setPlanSeatCount: (planSeatCount: number) => void
  setPlanPrice: (planPrice: number) => void
  setCohortPassQuantity: (cohortPassQuantity: number) => void
  setCohortPassPrice: (cohortPassPrice: number) => void
  setCurrentStep: (currentStep: number) => void
  setError: (error: string | null) => void
  setSelectedPaymentMethodId: (paymentMethodId: string) => void
  setSubTotal: (subTotal: number) => void
  setOrderTotal: (orderTotal: number) => void
  setTaxAmount: (taxAmount: number) => void
  loading: boolean
  isReactivating: boolean
  referer: string | null
  isFreeTrialPurchase: boolean
}

export const PurchaseFlowContext = createContext<PurchaseFlowContextProps>({
  cohortPassPriceTiers: [],
  countryCode: '',
  discountedCohortPassPrice: 0,
  discountedCohortPassQuantity: 0,
  planName: null,
  planPrice: 0,
  planSeatCount: 0,
  plansForSale: [],
  cohortPassQuantity: 0,
  cohortPassPrice: 0,
  currentStep: 1,
  error: null,
  selectedPaymentMethodId: null,
  subTotal: 0,
  orderTotal: 0,
  selectPlan: () => {},
  goToNextStep: () => {},
  skipOneStep: () => {},
  setCountryCode: () => {},
  setDiscountedCohortPassPrice: () => {},
  setDiscountedCohortPassQuantity: () => {},
  setPlanName: () => {},
  setPlanSeatCount: () => {},
  setPlanPrice: () => {},
  setCohortPassQuantity: () => {},
  setCohortPassPrice: () => {},
  setCurrentStep: () => {},
  setError: () => {},
  setSelectedPaymentMethodId: () => {},
  setSubTotal: () => {},
  setOrderTotal: () => {},
  setTaxAmount: () => {},
  loading: false,
  isReactivating: false,
  referer: null,
  isFreeTrialPurchase: false
})

export function PurchaseFlowProvider({
  children,
  purchaseType
}: {
  children: ReactNode
  purchaseType: string
}) {
  const {
    can: { viewPurchaseFlow },
    is: { eligibleForTrial }
  } = useAssertCurrentUser()
  const [planName, setPlanName] = useState<PlanName | null>(null)
  const [planPrice, setPlanPrice] = useState<number>(0)
  const [planSeatCount, setPlanSeatCount] = useState<number>(0)
  const [discountedCohortPassQuantity, setDiscountedCohortPassQuantity] =
    useState<number>(0)
  const [discountedCohortPassPrice, setDiscountedCohortPassPrice] = useState<number>(0)
  const [cohortPassQuantity, setCohortPassQuantity] = useState<number>(0)
  const [cohortPassPrice, setCohortPassPrice] = useState<number>(0)
  const [countryCode, setCountryCode] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(
    null
  )
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [orderTotal, setOrderTotal] = useState<number>(0)
  const [isReactivating, setIsReactivating] = useState<boolean>(false)
  const [taxAmount, setTaxAmount] = useState<number>(0)
  const [referer, setReferer] = useState<string | null>(null)

  const goToNextStep = () => setCurrentStep(currentStep + 1)
  const skipOneStep = () => setCurrentStep(currentStep + 2)

  const { premiumUpdatePaymentFlow, showFreeTrial } = useFeatureFlags()

  const selectPlan = useCallback(
    (plan: PlanName | null) => {
      setPlanName(plan)
      if (plan === PlanName.INDIVIDUAL && premiumUpdatePaymentFlow) {
        skipOneStep()
        setCohortPassQuantity(0)
      } else {
        goToNextStep()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [premiumUpdatePaymentFlow]
  )

  const { data: plansData, loading: plansDataLoading } = usePlansForSaleQuery()
  const plansForSale = plansData?.plansForSale

  const { data: cohortPassPriceTiersData, loading: cohortPassDataLoading } =
    useCohortPassPriceTiersQuery()
  const cohortPassPriceTiers = cohortPassPriceTiersData?.cohortPassPriceTiers || []

  useEffect(() => {
    const recentlyCompletedStep = currentStep - 1
    if (recentlyCompletedStep >= 0) {
      track('Checkout Step Completed', {
        step_number: recentlyCompletedStep,
        step_name: steps[recentlyCompletedStep].path,
        selected_plan_name: planName,
        includes_cohort_purchase: cohortPassQuantity > 0,
        includes_subscription_purchase: !!planName,
        includes_sales_tax: taxAmount > 0,
        is_trial_signup: isFreeTrialPurchase,
        country_code: countryCode
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const history = useHistory()

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search)
    const isReactivating = queryParams.get('reactivate')
    const referer = queryParams.get('referer')
    const plan = queryParams.get('plan')

    if (isReactivating === 'true') {
      setIsReactivating(true)
    }

    if (referer) {
      setReferer(referer)
    }

    if (plan) {
      selectPlan(plan as PlanName)
    }
  }, [history.location.search, selectPlan])

  useEffect(() => {
    if (purchaseType === PURCHASE_TYPE_MEMBERSHIP) {
      const searchParams = new URLSearchParams(history.location.search)
      if (!viewPurchaseFlow) {
        const referer = searchParams.get('referer')
        const refererUrl = getRefererUrl(referer, { purchaseSuccessful: false })
        if (refererUrl) {
          return history.replace(refererUrl)
        }
        return history.replace('/account')
      }

      const plan = searchParams.get('plan')
      const nextStepUrl = `${PURCHASE_FLOW_BASEPATH}/${steps[currentStep].path}`
      const currentUrlPath = history.location.pathname

      if (currentUrlPath !== nextStepUrl) {
        // if we're hitting the /subscribe route directly or we have a "plan" query param, replace the url instead of pushing
        // this prevents the user from being able to navigate back to the /subscribe route which would cause an error to be displayed
        // or prevents the user from being able to navigate back to the choose plan screen after the plans is selected automatically
        if (new RegExp(`^${PURCHASE_FLOW_BASEPATH}/?$`).test(currentUrlPath) || plan) {
          history.replace(nextStepUrl, history.location.state)
        } else {
          history.push(nextStepUrl, history.location.state)
        }
      }
    }
  }, [purchaseType, currentStep, history, viewPurchaseFlow])

  useEffect(() => {
    if (purchaseType === PURCHASE_TYPE_MEMBERSHIP) {
      // if currentStep is greater than the url we are on, update currentStep to match url
      const currentUrlPath = history.location.pathname.split('/').pop()
      if (steps[currentStep].path !== currentUrlPath) {
        const newStep = steps.findIndex((step) => step.path === currentUrlPath)
        setCurrentStep(newStep)
      }
    }
  }, [purchaseType, currentStep, history.location.pathname])

  useEffect(() => {
    if (!plansForSale || !planName) {
      return
    }

    const selectedPlanDetails = plansForSale.find((plan) => plan.name === planName)
    setPlanPrice(selectedPlanDetails?.pricePerYear || 0)
    setPlanSeatCount(selectedPlanDetails?.maxSeatCount || 0)
    if (planName === DISCOUNTED_COHORT_PASS_PLAN_NAME) {
      setDiscountedCohortPassQuantity(DISCOUNTED_COHORT_PASS_QUANTITY)
      setDiscountedCohortPassPrice(DISCOUNTED_COHORT_PASS_PRICE)
    } else {
      setDiscountedCohortPassQuantity(0)
      setDiscountedCohortPassPrice(0)
    }
  }, [plansForSale, planName])

  useEffect(() => {
    setSubTotal(
      planPrice +
        discountedCohortPassQuantity * discountedCohortPassPrice +
        cohortPassQuantity * cohortPassPrice
    )
  }, [
    planPrice,
    cohortPassQuantity,
    cohortPassPrice,
    discountedCohortPassQuantity,
    discountedCohortPassPrice
  ])

  const isFreeTrialPurchase =
    showFreeTrial && eligibleForTrial && planName === PlanName.INDIVIDUAL

  const value = {
    cohortPassPriceTiers,
    countryCode,
    discountedCohortPassPrice,
    discountedCohortPassQuantity,
    planName,
    planPrice,
    planSeatCount,
    plansForSale: plansForSale || [],
    cohortPassQuantity,
    cohortPassPrice,
    currentStep,
    error,
    selectedPaymentMethodId,
    subTotal,
    orderTotal,
    selectPlan,
    goToNextStep,
    skipOneStep,
    setCountryCode,
    setDiscountedCohortPassPrice,
    setDiscountedCohortPassQuantity,
    setPlanName,
    setPlanSeatCount,
    setPlanPrice,
    setCohortPassQuantity,
    setCohortPassPrice,
    setCurrentStep,
    setError,
    setSelectedPaymentMethodId,
    setSubTotal,
    setOrderTotal,
    setTaxAmount,
    loading: plansDataLoading || cohortPassDataLoading,
    isReactivating,
    referer,
    isFreeTrialPurchase
  }

  return (
    <PurchaseFlowContext.Provider value={value}>{children}</PurchaseFlowContext.Provider>
  )
}

export function usePurchaseFlowContext() {
  return useContext(PurchaseFlowContext)
}
