import type History from 'history'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { isMobileSafari, useWindowSize } from 'domains/Sanity/lib/helpers'

import Footer from 'components/MarketingSite/Footer'
import { OptInMonsterGlobalCampaign } from 'components/OptInMonsterGlobalCampaign'

import { CookieConsent, SiteSettings, useMarketingSiteSettingsQuery } from 'gql'

import useFirstSigninSource from 'hooks/useFirstSigninSource'

import PageLoadContext from '../Contexts/PageLoadContext'
import CookieBar from '../CookieBar'
import MarketingHeadSEO from '../MarketingHeadSEO'
import { GenericPageType } from '../MarketingHeadSEO/MarketingHeadSEO'
import MarketingHeader from '../MarketingHeader'

interface MarketingLayoutProps {
  page?: GenericPageType
  pageTitle?: string
  children: ReactNode
  contentClassName?: string
  isAuthenticated?: boolean
  showFooter?: boolean
  scrollToTop?: boolean | ((location: History.Location) => boolean)
  transparentNav?: boolean
}

const MarketingLayout = ({
  page,
  pageTitle,
  children,
  contentClassName,
  showFooter = true,
  scrollToTop = true,
  transparentNav = false
}: MarketingLayoutProps) => {
  const { triggerPageLoadEvent, setManualPageLoadTrigger } = useContext(PageLoadContext)
  // set window height var (w/ safari/iOS hack)
  const { height: windowHeight } = useWindowSize()
  const [lockHeight, setLockHeight] = useState(false)
  const hasChin = isMobileSafari()
  const { data: siteData } = useMarketingSiteSettingsQuery()
  const site = (siteData?.marketingSiteSettings || {}) as SiteSettings
  const hasData = !!(siteData && page && Object.keys(page).length > 0)
  const history = useHistory()
  useFirstSigninSource() // sets the reforge_signin_source cookie on every page with the pathname

  useEffect(() => {
    function scrollTop(location: History.Location) {
      if (typeof scrollToTop === 'function' && !scrollToTop(location)) return
      if (!scrollToTop) return

      window.scrollTo(0, 0)
    }

    const unlisten = history.listen(scrollTop)

    return () => {
      // reset the manual page load trigger when the component unmounts -> when moving to non-marketing-site pages
      setManualPageLoadTrigger(false)
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!lockHeight || !hasChin) {
      document.body.style.setProperty('--vh', `${windowHeight * 0.01}px`)
      setLockHeight(hasChin)
    }
  }, [windowHeight, hasChin, lockHeight])

  useEffect(() => {
    setManualPageLoadTrigger(true)

    if (hasData) {
      triggerPageLoadEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData])

  return (
    <>
      <div className={twMerge('marketing-site flex min-h-[100vh] flex-col font-sans')}>
        {hasData && <MarketingHeadSEO site={site} page={page} pageTitle={pageTitle} />}
        {site && <CookieBar data={(site.cookieConsent || {}) as CookieConsent} />}
        <MarketingHeader transparentNav={transparentNav} />
        <main
          className={twMerge(
            'flex-grow',
            !transparentNav && 'pt-14 lg:pt-[76px]',
            contentClassName
          )}
          id="content" // used in MarketingHeader on the Skip To Content button
        >
          {children}
        </main>

        {showFooter && <Footer />}
      </div>

      <OptInMonsterGlobalCampaign />
      {/* TODO: Figure out if we still need this script. Is it/will it be defined in spa_root? */}
      {/* <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/20491267.js"
      ></script> */}
    </>
  )
}

export default MarketingLayout
