import { Redirect, useParams } from 'react-router-dom'

import LoggedOutCourseDetailsPage from 'pages/CourseDetailsPage/LoggedOutCourseDetailsPage'
import OldLoggedOutCourseDetails from 'pages/CoursesPage/OldLoggedOutCourseDetails'

import { Loading } from 'components'

import { useLoggedOutCourseDetailsPageBridgeQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const LoggedOutCourseDetailsPageBridge = () => {
  const { isLoggedIn } = useCurrentUser()
  const { courseSlug } = useParams<{ courseSlug: string }>()

  const { data, loading } = useLoggedOutCourseDetailsPageBridgeQuery({
    variables: {
      slug: courseSlug
    }
  })

  if (loading) {
    return <Loading />
  }

  const course = data?.marketingCourseDetails?.course

  if (isLoggedIn && data?.cclCourse) {
    return <Redirect to={`/courses/${courseSlug}/details`} />
  }

  if (course?.courseType === 'legacy') {
    return <OldLoggedOutCourseDetails />
  }

  return <LoggedOutCourseDetailsPage courseDetailsForSeo={data?.marketingCourseDetails} />
}

export default LoggedOutCourseDetailsPageBridge
