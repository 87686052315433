import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import ArtifactPage from 'pages/Artifacts/ArtifactPage'
import CourseEventPage from 'pages/CourseEventPage'
import CourseDashboard from 'pages/CoursesPage/CourseDashboard'
import CourseWelcome from 'pages/CoursesPage/CourseWelcome'
import { UnitLandingPage } from 'pages/UnitLandingPage'

import { Loading } from 'components'

import { useCoursePageAccessQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { CourseIdentifiersType } from 'utils/courseUtils'

export const COURSE_DASHBOARD_HEADER_ID = 'course-dashboard-header'

export type CoursePageParams = {
  courseSlug: string
  courseSessionId: string
  guideSlug?: string
  artifactSlug?: string
  sanityEventId?: string
}

export const CoursePage = () => {
  const { courseSlug, courseSessionId, guideSlug, artifactSlug, sanityEventId } =
    useParams<CoursePageParams>()

  const { showCourseContentNow, userIdentified } = useFeatureFlags()

  const { loading, data } = useCoursePageAccessQuery({
    variables: { slug: courseSlug, courseSessionId }
  })

  if (!courseSessionId || !courseSlug) {
    return <Redirect to="/courses" />
  }

  if (!userIdentified || loading) {
    return <Loading />
  }

  if (!data?.course) {
    return <Redirect to="/courses" />
  }

  const showContent =
    data?.courseSession?.isContentOpen ||
    data?.courseSession?.isHostOrCreator ||
    showCourseContentNow

  const courseIdentifiers: CourseIdentifiersType = {
    course: data?.course,
    courseSession: data?.courseSession
  }

  return (
    <div className="relative flex flex-col" id="course-page">
      {showContent ? (
        <Switch>
          <Route
            exact
            path={[`/courses/${courseSlug}/sessions/${courseSessionId}`]}
            render={() => (
              <CourseDashboard slug={courseSlug} courseSessionId={courseSessionId} />
            )}
          />
          <Route
            exact
            path={[
              `/courses/${courseSlug}/sessions/${courseSessionId}/events/${sanityEventId}`
            ]}
            render={() => (
              <CourseEventPage
                course={data?.course}
                courseSessionId={data?.courseSession?.id}
                sanityEventId={sanityEventId}
              />
            )}
          />
          <Route
            exact
            path={[
              `/courses/${courseSlug}/sessions/${courseSessionId}/guides/${guideSlug}`
            ]}
            render={() => (
              <UnitLandingPage
                unitSlug={guideSlug}
                course={data?.course}
                courseSession={data?.courseSession}
                backToCourseLink={`/courses/${courseSlug}/sessions/${courseSessionId}`}
                backToCourseLabel="Course Home"
                showCourseBreadcrumb
              />
            )}
          />
          <Route
            exact
            path={`/courses/${courseSlug}/sessions/${courseSessionId}/artifacts/${artifactSlug}`}
            render={() => (
              <ArtifactPage
                artifactSlug={artifactSlug}
                courseIdentifiers={courseIdentifiers}
              />
            )}
          />
        </Switch>
      ) : (
        <CourseWelcome
          course={data?.course}
          courseSession={data?.courseSession}
          enrollmentSnapshot={data?.userCourse?.courseEnrollmentSnapshot}
        />
      )}
    </div>
  )
}
