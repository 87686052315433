import { Combobox } from '@headlessui/react'
import React, { useState } from 'react'

import { useControlledFocus } from 'components/forms/InputAutocomplete'
import { ValidationIcon } from 'components/forms/enhancers/withValidationIcon'

import { ClearbitCompany } from 'gql'

export interface CompanyComboboxProps {
  name: string
  disabled?: boolean
  defaultValue: string
  value?: string | null
  isValid?: boolean // only neccessary for validation icons
  hasError?: boolean // only necessary for validation icons
  placeholder: string
  register: any
  onChange?: (value: string) => void
  companies: ClearbitCompany[]
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onInputChange?: (e: React.FormEvent<HTMLInputElement>) => void
}

export const CompanyCombobox = ({
  name,
  disabled,
  defaultValue = '',
  isValid,
  hasError,
  register,
  value,
  placeholder,
  onChange,
  companies,
  onBlur,
  onInputChange
}: CompanyComboboxProps) => {
  const [query, setQuery] = useState('')
  const focusRef = useControlledFocus({ name, defaultValue })

  const filteredCompanies =
    query === ''
      ? null
      : companies
          .filter((company) => {
            return company.name.toLowerCase().includes(query?.toLowerCase())
          })
          .slice(0, 5)

  // checks for EXACT match
  const noMatch =
    companies.filter((company) => {
      return company.name === query
    }).length === 0

  const shouldShowMenu = query.trim() !== ''
  const shouldShowCreateOption = noMatch && shouldShowMenu

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    // set our state for safe-keeping
    setQuery((e.target as HTMLInputElement).value)
    // then do whatever the parent component wants us to do
    onInputChange && onInputChange(e)
  }

  const inputClassName = `h-9 w-full px-3 py-2 text-rb-gray-400 shadow-none outline-none inline-block rounded-sm border border-${
    hasError ? 'rb-destructive-100' : 'rb-gray-100'
  }`

  return (
    <div ref={focusRef}>
      <Combobox value={value} onChange={onChange} disabled={disabled}>
        <div className="relative">
          {(isValid || hasError) && (
            <ValidationIcon isValid={isValid} hasError={hasError} />
          )}
          <Combobox.Input
            onChange={handleInputChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            name={name}
            id={name}
            ref={register({ required: true })}
            placeholder={placeholder}
            className={inputClassName}
          />
        </div>
        {shouldShowMenu && (
          <Combobox.Options className="absolute z-10 w-full rounded-sm border border-rb-gray bg-white px-2 py-3">
            {filteredCompanies &&
              filteredCompanies.length > 0 &&
              filteredCompanies.map((company) => (
                <Combobox.Option
                  key={company.domain}
                  value={company.name}
                  className="flex cursor-pointer list-none items-center bg-white p-2 focus:bg-rb-gray-50 ui-active:bg-rb-gray-50"
                >
                  <img className="h-5 w-5" src={company.logo} alt={company.name} />
                  <span className="m-2">{company.name}</span>
                  <span className="ml-auto">{company.domain}</span>
                </Combobox.Option>
              ))}
            {shouldShowCreateOption && (
              <Combobox.Option
                value={query}
                className="cursor-pointer list-none bg-white p-2 focus:bg-rb-gray-50 ui-active:bg-rb-gray-50"
              >
                <span className="text-rb-gray-300">Create</span> {query}
              </Combobox.Option>
            )}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  )
}

export default CompanyCombobox
