import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import FocusAreas from 'domains/Onboarding/ArtifactsOnboarding/FocusAreas'
import { OnboardingModalContainer } from 'domains/Onboarding/OnboardingModalContainer'

import { BillingAddressCollectionModal } from 'components/BillingAddressCollectionModal'
import FreeTrialBanner from 'components/FreeTrial/FreeTrialBanner'
import { useGlobalModal } from 'components/GlobalModal'
import Loading from 'components/Loading'
import OnboardingUpsellModal from 'components/OnboardingUpsellModal'
import { OnboardingUpsellModalClassNames } from 'components/OnboardingUpsellModal/OnboardingUpsellModal'
import { PageHeaderH1 } from 'components/PageHeader/PageHeader'
import { usePage } from 'components/PageHeader/usePage'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import AnnouncementWrapper from 'components/dopt/AnnouncementWrapper'

import {
  HomepageRecentlyViewedItem,
  useAiPersonalizationQuery,
  useBookmarkFoldersQuery,
  useDedupedTopicsAndFunctionsQuery,
  useHomepageRecentlyViewedContentQuery,
  useRecommendedCollectionsQuery,
  useRecommendedContentQuery,
  useSavedArtifactsAndGuidesForUserQuery,
  useUserTeamQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { getTimeOfDay } from 'utils/date'

import TeamSection from '../TeamHome/TeamSection'
import CoursesInProgress from './CoursesInProgress'
import { Swimlanes } from './Feed'
import { PersonalizeModal } from './PersonalizeModal'
import RecentlyViewedContent from './RecentlyViewedContent'

const PER_PAGE = 5
const AI_PER_PAGE = 30

const LoggedInHomepage = () => {
  const { currentUser } = useCurrentUser()
  const { is: userIs } = currentUser || {}
  const { aiContentRecommendations, showFreeTrial } = useFeatureFlags()
  const { setPageTitle } = usePage()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocusUpdate, setIsFocusUpdate] = useState(false)
  const { networkOnboarded, networkFocus } = currentUser || {}
  const [isFocusAreaVisible, setIsFocusAreaVisible] = useState(!networkOnboarded || false)
  const history = useHistory()
  const TRACKING_LOCATION = userIs?.freeUser ? 'free_user_home' : 'premium_user_home'
  const location = useLocation()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const hasFocusAreas = networkFocus && networkFocus?.length > 0

  const { data: recentlyViewedData, loading: recentlyViewedDataLoading } =
    useHomepageRecentlyViewedContentQuery({
      fetchPolicy: 'cache-and-network'
    })

  const { data: recommendedCollections, loading: recommendedCollectionsLoading } =
    useRecommendedCollectionsQuery()

  const { data: userTeamData } = useUserTeamQuery({
    fetchPolicy: 'cache-and-network',
    skip: !currentUser?.hasTeam
  })
  const userTeam = userTeamData?.currentUser?.currentTeam
  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const { data: feedContentData, loading: feedContentLoading } =
    useRecommendedContentQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        limit: aiContentRecommendations ? AI_PER_PAGE : PER_PAGE,
        cursor: null
      }
    })
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })
  const { data: personalizationData, loading: personalizationLoading } =
    useAiPersonalizationQuery({
      skip: !currentUser
    })
  const { data: dedupedTopicsAndFunctions, loading: dedupedTopicsAndFunctionsLoading } =
    useDedupedTopicsAndFunctionsQuery({
      skip: !currentUser
    })

  const { data: savedData } = useSavedArtifactsAndGuidesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    }
  })

  const recentlyViewedContent = (recentlyViewedData?.homepageRecentlyViewedContent ||
    []) as HomepageRecentlyViewedItem[]

  const shouldRenderFocusAreas = !networkOnboarded || isFocusAreaVisible
  const isLoading =
    (dedupedTopicsAndFunctionsLoading && isInitialLoad) ||
    (feedContentLoading && isInitialLoad) ||
    (recommendedCollectionsLoading && isInitialLoad) ||
    isRefreshing
  // Remove padding from page content
  useEffect(() => {
    const pageContent = document.getElementById('page-content')

    if (pageContent) {
      pageContent.style.paddingBottom = '0'
    }

    return () => {
      if (pageContent) {
        pageContent.removeAttribute('style')
      }
    }
  }, [isLoading, isFocusAreaVisible])

  useEffect(() => {
    if (feedContentData?.recommendedContent && isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [feedContentData, isInitialLoad])

  useEffect(() => {
    const title = currentUser?.firstName
      ? `Hi there, ${currentUser.firstName}`
      : `Good ${getTimeOfDay()}`

    const showTeam = currentUser?.hasTeam && userTeam
    const pageTitle = (
      <div className="flex gap-x-12 w-full">
        <div className="w-full flex flex-col lg:flex-row justify-between">
          <PageHeaderH1 className="whitespace-nowrap">{title}</PageHeaderH1>

          {showTeam && (
            <div className="flex items-center justify-between lg:ml-4 mt-2 lg:mt-0">
              <TeamSection
                isSubOwner={currentUser?.id === userTeam?.userId}
                teamName={userTeam?.teamName}
                teamMembers={userTeam?.teamMembers.users || []}
              />
            </div>
          )}
        </div>
      </div>
    )

    setPageTitle(pageTitle)

    return () => {
      setPageTitle(null)
    }
  }, [currentUser, setPageTitle, userTeam])

  const refreshFeed = useCallback(async () => {
    setIsRefreshing(true)
    setIsRefreshing(false)
  }, [])

  const handlePostFocusAreaNavigation = () => {
    const searchParams = new URLSearchParams(location.search)
    const redirectParam = searchParams.get('redirect')

    const shouldShowFreeTrialScreen =
      showFreeTrial &&
      userIs?.eligibleForTrial &&
      !isFocusUpdate &&
      !redirectParam?.startsWith('/subscribe') &&
      !redirectParam?.startsWith('/events') &&
      !redirectParam?.startsWith('/course-payment')

    if (shouldShowFreeTrialScreen) {
      const referer = redirectParam ? `?referer=${encodeURIComponent(redirectParam)}` : ''
      history.push(`/try-reforge${referer}`)
      return
    }

    if (redirectParam) {
      history.push(redirectParam)
    }

    // can be removed completely after showFreeTrial is turned on in prod
    // TODO: also remove the OnboardingUpsellModal component
    if (
      !showFreeTrial &&
      userIs?.freeUser &&
      !isFocusUpdate &&
      !redirectParam?.startsWith('/subscribe') &&
      !redirectParam?.startsWith('/events') &&
      !redirectParam?.startsWith('/course-payment')
    ) {
      openOnboardingUpsellModal()
    }
  }

  const handleFocusAreasSave = () => {
    handlePostFocusAreaNavigation()
    setIsFocusAreaVisible(false)
    refreshFeed()
  }

  const handleFocusAreasSkip = () => {
    handlePostFocusAreaNavigation()
    setIsFocusAreaVisible(false)
  }

  const { openGlobalModal } = useGlobalModal()
  const openOnboardingUpsellModal = useCallback(() => {
    openGlobalModal(<OnboardingUpsellModal />, {
      className: OnboardingUpsellModalClassNames,
      containerClass: 'z-[1050]',
      animateOnOpen: false
    })
  }, [openGlobalModal])

  const openPersonalizeModal = useCallback(() => {
    openGlobalModal(
      <PersonalizeModal
        dedupedTopicsAndFunctions={
          dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
        }
        personalizationData={personalizationData?.aiPersonalization}
        loading={dedupedTopicsAndFunctionsLoading || personalizationLoading}
      />,
      {
        className: 'overflow-y-scroll pb-0 rounded-xl',
        scrollContent: false,
        fixedHeight: true
      }
    )
  }, [
    openGlobalModal,
    dedupedTopicsAndFunctions,
    dedupedTopicsAndFunctionsLoading,
    personalizationData,
    personalizationLoading
  ])

  if (shouldRenderFocusAreas) {
    return (
      <div className="fixed top-0 left-0 h-dvh w-full z-[2000] flex bg-rb-white overflow-y-auto">
        <FocusAreas
          updateFocus={isFocusUpdate}
          onFocusAreasSkip={handleFocusAreasSkip}
          onFocusAreasSave={handleFocusAreasSave}
          dedupedTopicsAndFunctions={
            dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
          }
          dedupedTopicsAndFunctionsLoading={dedupedTopicsAndFunctionsLoading}
        />
      </div>
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="mt-4 flex flex-col w-full" ref={containerRef}>
        {userIs?.member && <AnnouncementWrapper />}
        {showFreeTrial && userIs?.eligibleForTrial && <FreeTrialBanner />}

        {(currentUser?.shouldSeeHomepageCoursesSection ||
          recentlyViewedContent.length > 0) && (
          <div className="flex flex-col gap-10 pb-20">
            {currentUser?.shouldSeeHomepageCoursesSection && <CoursesInProgress />}

            <RecentlyViewedContent
              items={recentlyViewedContent}
              loading={recentlyViewedDataLoading}
              pageLocation={TRACKING_LOCATION}
              savedData={savedData}
              openAddToBookmarkFolderModal={
                userIs?.freeUser ? undefined : openAddToBookmarkFolderModal
              }
            />
          </div>
        )}

        <Swimlanes
          openPersonalizeModal={openPersonalizeModal}
          setIsFocusUpdate={setIsFocusUpdate}
          recommendedCollections={recommendedCollections?.recommendedCollections}
          hasFocusAreas={!!hasFocusAreas}
          savedData={savedData}
          openAddToBookmarkFolderModal={
            userIs?.freeUser ? undefined : openAddToBookmarkFolderModal
          }
          pageLocation={TRACKING_LOCATION}
        />
      </div>

      {!userIs?.freeUser && (
        <>
          <ShareableSnapshotModal page="Home" />
          <BillingAddressCollectionModal />
          <OnboardingModalContainer isOpen={!currentUser?.onboarded} showLoader={false} />
          <AddBookmarkToFolderModal
            isOpen={isAddToBookmarkFolderModalOpen}
            handleClose={closeAddToBookmarkFolderModal}
            bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
            openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdown}
            showCollectionsOnboardingInfo={
              !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
            }
          />
          <CreateBookmarkFolderModal
            isModalOpen={isCreateBookmarkFolderModalOpen}
            handleClose={closeCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
            trackingTriggerAction={'bookmark'}
          />
        </>
      )}
    </>
  )
}

export default LoggedInHomepage
