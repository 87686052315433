import { ButtonBack, ButtonNext, Slide, Slider, WithStore } from 'pure-react-carousel'
import {
  Children,
  Fragment,
  PropsWithChildren,
  ReactNode,
  RefObject,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useRef
} from 'react'

import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import Button from 'components/Button'

import { cn } from 'utils/tailwind'
import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as ChevronLeftIcon } from 'images/icon--thin-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icon--thin-chevron-right.svg'

import SeoPageSectionSubtitle from '../Section/SeoPageSectionSubtitle'
import SeoPageSectionTitle from '../Section/SeoPageSectionTitle'

export interface SeoPageCarouselProps {
  title?: string | ReactNode
  subtitle?: string | ReactNode
  isSmallGap: boolean
  slideWidth: number
  visibleSlides: number
  bottomText?: string
  ctaLocation?: string
  navigationLocation?: string
  ctaText?: string
  ctaHref?: string
  tracking?: SEOTrackingRelatedIdentifiers
}

interface CarouselContainerCarouselState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

const BUTTON_STYLINGS =
  'rounded-full w-8 h-8 text-rb-black flex items-center justify-center border border-rb-gray-250 hover:border-rb-teal-200'

const SeoPageCarousel = forwardRef(
  (
    {
      title,
      subtitle,
      totalSlides,
      isSmallGap,
      visibleSlides,
      slideWidth,
      children,
      currentSlide,
      bottomText,
      ctaLocation,
      navigationLocation,
      ctaText,
      ctaHref,
      tracking
    }: PropsWithChildren<SeoPageCarouselProps> & CarouselContainerCarouselState,
    ref: RefObject<HTMLDivElement>
  ) => {
    const sliderContainerRef = useRef<HTMLDivElement>(null)
    const gapWidth = isSmallGap ? 16 : 32
    const totalSlidesWidth = slideWidth * visibleSlides
    const totalGapWidth = gapWidth * visibleSlides
    const containerWidth = sliderContainerRef.current?.offsetWidth || 0
    const sliderWidth = totalSlidesWidth + totalGapWidth
    const emptyElementWidth =
      slideWidth - Math.abs(containerWidth - sliderWidth) + 2 * gapWidth

    const isLastSlideVisible = totalSlides - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0
    const slides = Children.toArray(children)

    useEffect(() => {
      document.body.classList.add('overscroll-x-none')

      return () => {
        document.body.classList.remove('overscroll-x-none')
      }
    }, [])

    const onCtaClick = () => {
      trackCtaClicked({
        cta_type: 'button',
        destination: ctaHref,
        cta_location: ctaLocation ?? '',
        text: ctaText?.toLowerCase() || '',
        related_identifiers: {
          sanity_type: tracking?.sanityType,
          sanity_id: tracking?.sanityId,
          sanity_name: tracking?.sanityName
        }
      })
    }

    const onNavigationClick = (direction: '<' | '>') => {
      trackNavigationClicked({
        type: 'carousel',
        text: direction,
        location: navigationLocation
      })
    }

    return (
      <div ref={ref} className="flex flex-col">
        <div className="flex justify-between">
          <div>
            {title && <SeoPageSectionTitle>{title}</SeoPageSectionTitle>}
            {subtitle && (
              <SeoPageSectionSubtitle className="mb-2">{subtitle}</SeoPageSectionSubtitle>
            )}
          </div>

          <div className="ml-2 mb-2 hidden items-end gap-2 md:flex">
            <ButtonBack
              className={cn(BUTTON_STYLINGS, {
                'hover:border-rb-gray-10 cursor-default border-rb-gray-100':
                  isFirstSlideVisible
              })}
              onClick={() => onNavigationClick('<')}
              disabled={isFirstSlideVisible}
            >
              <ChevronLeftIcon
                className={cn('h-4 w-4 text-rb-black', {
                  'text-rb-gray-100': isFirstSlideVisible
                })}
                fill="currentColor"
              />
            </ButtonBack>
            <ButtonNext
              className={cn(BUTTON_STYLINGS, {
                'hover:border-rb-gray-10 cursor-default border-rb-gray-100':
                  isLastSlideVisible
              })}
              onClick={() => onNavigationClick('>')}
              disabled={isLastSlideVisible}
            >
              <ChevronRightIcon
                className={cn('h-4 w-4 text-rb-black', {
                  'text-rb-gray-100': isLastSlideVisible
                })}
                fill="currentColor"
              />
            </ButtonNext>
          </div>
        </div>

        <div
          ref={sliderContainerRef}
          className="relative mb-4 w-full overflow-x-hidden py-4"
        >
          <Slider
            style={{
              width: `${totalSlidesWidth + totalGapWidth}px`
            }}
            classNameAnimation="transition-transform"
            trayProps={{
              draggable: true
            }}
          >
            {slides.map((slide, i) => {
              return (
                isValidElement(slide) && (
                  <Fragment key={i}>
                    {/* Hack - we render the below div with changing width to mimic behavior of homepage UiKit carousel: */}
                    {/* - sticking the last Slide to the right edge of the container when it becomes the active slide */}
                    {i === slides.length - visibleSlides && (
                      <div
                        className="h-[1px] transition-width"
                        style={{
                          width: currentSlide >= i + 1 ? `${emptyElementWidth}px` : 0
                        }}
                      />
                    )}
                    <Slide index={i} innerClassName="flex h-full">
                      {cloneElement(slide, slide.props, slide?.props?.children)}
                    </Slide>
                  </Fragment>
                )
              )
            })}
          </Slider>

          <div
            className={cn(
              'pointer-events-none absolute top-0 right-0 h-full w-12 bg-gradient-to-r from-transparent to-white',
              {
                'left-0 right-auto bg-gradient-to-l': isLastSlideVisible,
                'hidden': isFirstSlideVisible && isLastSlideVisible
              }
            )}
          />
        </div>

        {bottomText && (
          <p className="mb-4 text-sm leading-[1.5] text-rb-gray-400 md:text-base md:leading-[1.6]">
            {bottomText}
          </p>
        )}

        {ctaHref && ctaText && (
          <Button
            color="teal"
            shape="rounded"
            size="small"
            href={ctaHref}
            onClick={onCtaClick}
          >
            {ctaText}
          </Button>
        )}
      </div>
    )
  }
)
SeoPageCarousel.displayName = 'SeoPageCarousel'

export const CarouselWithStore = WithStore<
  SeoPageCarouselProps,
  CarouselContainerCarouselState
>(SeoPageCarousel, (state) => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
  visibleSlides: state.visibleSlides
}))
