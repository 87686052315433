import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { CourseDetailPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackNavigationClicked } from 'utils/tracking/generated/events'

interface CourseDetailOnDemandProps {
  courseDetails: CourseDetailPartsFragment
}

const CourseDetailOnDemand = ({ courseDetails }: CourseDetailOnDemandProps) => {
  const { showFreeTrial } = useFeatureFlags()

  const history = useHistory()

  const { currentUser, isLoggedIn } = useCurrentUser()

  if (!courseDetails.onDemand) {
    return null
  }

  const onStartCourseClick = () => {
    const destination = `/courses/${courseDetails.slug}/on-demand`

    trackNavigationClicked({
      destination: destination,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: courseDetails?.id,
      type: 'button',
      text: 'Go to course',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_title: courseDetails?.title,
        course_sanity_id: courseDetails?.sourceId,
        content_mode: 'on-demand'
      }
    })

    history.push(destination)
  }

  const onBecomeAMemberClick = () => {
    const destination =
      showFreeTrial && currentUser?.is.eligibleForTrial
        ? `/try-reforge?referer=${encodeURIComponent(`course-redirect-${courseDetails.slug}`)}`
        : `/subscribe/choose-plan?referer=${encodeURIComponent(`course-redirect-${courseDetails.slug}`)}`

    trackNavigationClicked({
      destination: destination,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: courseDetails?.id,
      type: 'button',
      text:
        showFreeTrial && currentUser?.is.eligibleForTrial
          ? 'Start 14 day free trial'
          : 'Sign up'
    })

    // Can't use history.push because they may be signed out and need to be redirected to /login
    window.location.assign(destination)
  }

  return (
    <div className="flex flex-col gap-5 ">
      <div className={twMerge('max-w-[360px] rounded-2xl p-6 w-full bg-rb-orange-25')}>
        <div>
          <div className="flex flex-row">
            <RfParagraphLarge>
              <span className="text-[20px] font-semibold">
                {currentUser?.is.member ? 'Access on demand' : 'Go at your own pace'}
              </span>
            </RfParagraphLarge>
          </div>
        </div>

        <div data-test="on_demand_expandable_section_content" className="pt-6">
          <RfParagraphMedium className="mb-6">
            Take this course on demand with a Reforge membership, anytime.
          </RfParagraphMedium>

          {currentUser?.is.member ? (
            <Button
              variant="fill"
              fullWidth
              size="medium"
              className="normal-case"
              onClick={onStartCourseClick}
            >
              Go to course →
            </Button>
          ) : showFreeTrial && currentUser?.is.eligibleForTrial ? (
            <Button
              variant="fill"
              fullWidth
              size="medium"
              className="normal-case"
              onClick={onBecomeAMemberClick}
            >
              Start 14 day free trial →
            </Button>
          ) : (
            <Button
              variant="fill"
              fullWidth
              size="medium"
              className="normal-case"
              onClick={onBecomeAMemberClick}
            >
              {' '}
              {(!isLoggedIn || currentUser?.is.eligibleForTrial) && showFreeTrial
                ? 'Start 14-day free trial'
                : 'Sign up →'}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CourseDetailOnDemand
