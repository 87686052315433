import { SimilarityContentFragment } from 'gql'

import ArtifactItem from './ArtifactItem'
import CourseItem from './CourseItem'
import GuideItem from './GuideItem'
import LessonItem from './LessonItem'

export interface SimilaritySearchResultItemProps {
  searchDocument?: SimilarityContentFragment
  searchQuery: string
  searchPosition: number
  userCan: {
    linkToSearchResults?: boolean | null
  }
  handleResultClick: (
    urL: string,
    path: string,
    searchPosition: number,
    resultComponent?: string,
    contentType?: string,
    subType?: string | null,
    doc?: object | null
  ) => void
}

export interface ContentResultItemProps {
  searchDocument: SimilarityContentFragment
  searchQuery: string
  userCan: {
    linkToSearchResults?: boolean | null
  }
  onResultClick: () => void
}

const ContentResultItems = {
  Course: CourseItem,
  Guide: GuideItem,
  Artifact: ArtifactItem,
  Lesson: LessonItem
}

const SimilaritySearchResultItem = ({
  searchDocument,
  searchQuery,
  userCan,
  handleResultClick,
  searchPosition
}: SimilaritySearchResultItemProps) => {
  if (!searchDocument) return null

  const ContentResultItem = ContentResultItems[searchDocument.contentType] || LessonItem
  const onResultClick = (searchDocument: any) => {
    const url = new URL(searchDocument.url, window.location.href)

    handleResultClick(
      url.href,
      url.pathname,
      searchPosition,
      'SimilaritySearchResultItem',
      searchDocument.contentType,
      searchDocument.subType,
      searchDocument
    )
  }

  return (
    <li className="list-none">
      <ContentResultItem
        searchDocument={searchDocument}
        searchQuery={searchQuery}
        userCan={userCan}
        onResultClick={() => onResultClick(searchDocument)}
      />
    </li>
  )
}

export default SimilaritySearchResultItem
