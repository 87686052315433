import { CarouselProvider } from 'pure-react-carousel'

import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { Unit } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import SeoPageGuideCard from '../SeoPageGuideCard'

export interface RelatedGuidesCarouselSectionProps {
  guides: Unit[]
  title?: string
  subtitle?: string
  ctaText?: string
  ctaHref?: string
  navigationLocation?: string
  visibleSlides?: number
}

export const RelatedGuidesCarouselSection = ({
  guides,
  title,
  subtitle,
  ctaText,
  ctaHref,
  navigationLocation,
  visibleSlides
}: RelatedGuidesCarouselSectionProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const slideWidth = isMobileView ? 288 : 352
  const visibleSlideCount = useSeoPageSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={guides.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="mb-[104px] w-full"
    >
      <CarouselWithStore
        isSmallGap={isMobileView}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides || visibleSlideCount}
        title={title}
        subtitle={subtitle}
        ctaText={ctaText}
        ctaHref={ctaHref}
        navigationLocation={navigationLocation}
      >
        {guides.map((guide) => (
          <SeoPageGuideCard guide={guide} location={navigationLocation} key={guide.id} />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default RelatedGuidesCarouselSection
