import Lottie from 'lottie-react'

import Button from 'components/Button'

import animationDocumentStack from './animation-document-stack.json'

interface ModalProps {
  onLinkClick: () => void
}
const LaunchModal = ({ onLinkClick }: ModalProps) => {
  return (
    <div className="-mt-3 px-12 pb-12 flex flex-col items-center justify-center relative">
      <div className="mb-12 min-h-[423px]">
        <Lottie
          animationData={animationDocumentStack}
          loop={true}
          className="chromatic-ignore"
        />
      </div>
      <h1 className="text-[#0C0F0D] leading-[120%] font-semibold mb-4 text-base">
        Introducing
      </h1>
      <h2 className="text-[#0C0F0D] leading-[130%] text-[48px] font-polysans font-normal mb-4">
        An expert by your side
      </h2>
      <p className="text-[#0C0F0D] leading-[150%] text-lg text-center mb-12 max-w-[460px]">
        Instant feedback from those who have done it before in the places you work, with
        the Reforge extension.
      </p>

      <Button
        href="/extension"
        className="px-4 py-[11px]"
        onClick={onLinkClick}
        target="_blank"
      >
        Learn more
      </Button>
    </div>
  )
}

export default LaunchModal
