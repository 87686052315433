import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  WithStore
} from 'pure-react-carousel'
import { Fragment, ReactNode, RefObject, forwardRef, useEffect, useState } from 'react'

import GuideCard from 'components/cards/Content/GuideCard'

import {
  MAX_WIDTH_TAILWIND_XS,
  MIN_WIDTH_TAILWIND_2XL,
  MIN_WIDTH_TAILWIND_XL
} from 'constants/breakpoints'

import { TagGroup, Unit } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeftIcon } from 'images/icon--thin-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icon--thin-chevron-right.svg'

interface GuideCardCarouselProps {
  title: ReactNode
  guides: Unit[]
  swimlaneSlug: string
  openAddToBookmarkFolderModal?: (bookmark: any) => void
  tagGroup?: TagGroup
  category?: string
  isSwimlaneAll: boolean
}

interface CarouselContainerCarouselState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

export const GuideCardCarousel = ({
  title,
  guides,
  swimlaneSlug,
  openAddToBookmarkFolderModal,
  tagGroup,
  category,
  isSwimlaneAll
}: GuideCardCarouselProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const visibleSlides = useGudiesSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={400}
      naturalSlideHeight={400}
      totalSlides={isMobileView ? guides.length + 1 : guides.length} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="w-full"
    >
      <CarouselWithStore
        title={title}
        swimlaneSlug={swimlaneSlug}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        guides={guides}
        visibleSlides={visibleSlides}
        tagGroup={tagGroup}
        category={category}
        isSwimlaneAll={isSwimlaneAll}
      />
    </CarouselProvider>
  )
}

interface CarouselProps {
  title: ReactNode
  guides: Unit[]
  swimlaneSlug: string
  openAddToBookmarkFolderModal?: (bookmark: any) => void
  visibleSlides: number
  tagGroup?: TagGroup
  category?: string
  isSwimlaneAll: boolean
}

const BUTTON_STYLINGS =
  'rounded-full w-8 h-8 text-rb-black flex items-center justify-center border border-rb-gray-250 hover:border-rb-teal-200'

const Carousel = forwardRef(
  (
    {
      guides,
      title,
      swimlaneSlug,
      openAddToBookmarkFolderModal,
      visibleSlides,
      currentSlide,
      tagGroup,
      category,
      isSwimlaneAll
    }: CarouselProps & CarouselContainerCarouselState,
    // Required otherwise there will be an error in console.
    ref: RefObject<HTMLDivElement> // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const [slideWidth, setSlideWidth] = useState(0)
    const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
    const gapWidth = 24
    const minCardWidth = 272
    const containerWidth = document.body.offsetWidth - 16 * 2 // remove the left/right content padding - only used on mobile view
    const totalSlidesWidth = slideWidth * visibleSlides
    const totalGapWidth = gapWidth * visibleSlides
    const sliderWidth = totalSlidesWidth + totalGapWidth
    const emptyElementWidth = isMobileView
      ? slideWidth - Math.abs(containerWidth - sliderWidth) + 2 * gapWidth
      : 0
    const isLastSlideVisible = guides.length - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0

    useEffect(() => {
      document.body.classList.add('overscroll-x-none')

      return () => {
        document.body.classList.remove('overscroll-x-none')
      }
    }, [])

    useEffect(() => {
      const onResize = () => {
        if (isMobileView) {
          const containerWidth = document.body.offsetWidth - 16 * 2
          setSlideWidth(
            Math.max(containerWidth * 0.7 + gapWidth, minCardWidth + gapWidth)
          )
        }
      }
      window.addEventListener('resize', onResize)

      onResize()

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [isMobileView])

    return (
      <div className="relative w-full overflow-x-hidden pb-4">
        {/* At some page widths, the hidden overflow doesn't completely cover the off screen item, due to the relative percentage widths of the cards. This div covers the bleeding content  */}
        <div className="absolute right-0 top-0 z-1 h-full w-2 bg-rb-white" />
        <div className="mb-6 flex items-center justify-between">
          {title}
          <div
            className={cn('hidden justify-end gap-2 pr-6 md:flex', {
              'md:hidden': guides.length === visibleSlides
            })}
          >
            <ButtonBack
              className={cn(BUTTON_STYLINGS, {
                'hover:border-rb-gray-10 cursor-default border-rb-gray-100':
                  isFirstSlideVisible
              })}
              disabled={isFirstSlideVisible}
            >
              <ChevronLeftIcon
                className={cn('h-4 w-4 text-rb-black', {
                  'text-rb-gray-100': isFirstSlideVisible
                })}
                fill="currentColor"
              />
            </ButtonBack>
            <ButtonNext
              className={cn(BUTTON_STYLINGS, {
                'hover:border-rb-gray-10 cursor-default border-rb-gray-100':
                  isLastSlideVisible
              })}
              disabled={isLastSlideVisible}
            >
              <ChevronRightIcon
                className={cn('h-4 w-4 text-rb-black', {
                  'text-rb-gray-100': isLastSlideVisible
                })}
                fill="currentColor"
              />
            </ButtonNext>
          </div>
        </div>

        <Slider
          style={{
            width: isMobileView ? `${totalSlidesWidth}px` : null
          }}
          classNameAnimation="transition-transform"
          trayProps={{
            draggable: true
          }}
        >
          {guides.map((guide, i) => {
            return (
              <Fragment key={i}>
                {/* Hack - we render the below div with changing width to mimic behavior of homepage UiKit carousel: */}
                {/* - sticking the last Slide to the right edge of the container when it becomes the active slide */}
                {i === guides.length - visibleSlides && (
                  <div
                    className="h-[1px] transition-width"
                    style={{
                      width:
                        isMobileView && currentSlide >= i + 1
                          ? `${emptyElementWidth}px`
                          : 0
                    }}
                  />
                )}

                <Slide
                  index={i}
                  key={i}
                  innerClassName="flex h-full"
                  style={
                    isMobileView
                      ? { paddingRight: gapWidth, width: slideWidth }
                      : { paddingRight: gapWidth }
                  }
                >
                  <GuideCard
                    guide={guide}
                    swimlaneSlug={swimlaneSlug}
                    additionalRelatedIdentifiers={{
                      referrer_swimlane: isSwimlaneAll ? 'all' : tagGroup?.title,
                      referrer_category: category
                    }}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                    // Needed because the slider manages the container overflow and will clip the dropdown menu
                    bookmarkDropdownPosition="top"
                  />
                </Slide>
              </Fragment>
            )
          })}
        </Slider>
      </div>
    )
  }
)

Carousel.displayName = 'GuideCardCarousel'

const CarouselWithStore = WithStore<CarouselProps, CarouselContainerCarouselState>(
  Carousel,
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides
  })
)

const useGudiesSlidesCount = () => {
  const thirdSlide = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XL})`)
  const fourthSlide = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_2XL})`)

  return [fourthSlide, thirdSlide, true, true].filter((slide) => slide).length
}

export default GuideCardCarousel
