import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import Button from 'components/Button'
import LottieInView from 'components/LottieInView'

import { Cta, HomepageContent } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import coursesAnimation from './courses_animation.json'
import coursesStaticImage from './courses_fallback.png'
import eventsAnimation from './events_animation.json'
import eventsStaticImage from './events_fallback.png'

const MembershipSection = ({ content }: { content: HomepageContent }) => {
  const { showFreeTrial } = useFeatureFlags()

  const onContentCtaClick = (cta: Cta) => () => {
    trackNavigationClicked({
      location: 'marketing_homepage__membership_section',
      type: 'hyperlink text',
      destination: cta.ctaLink,
      text: cta.ctaText.toLowerCase()
    })
  }

  return (
    <section className="relative text-center flex flex-col pt-[100px] pb-[50px] gap-[50px] sm:pt-[125px] sm:pb-[75px] sm:gap-[75px] lg:pt-[150px] lg:pb-[100px] lg:gap-[100px]">
      <div className="absolute top-[50px] sm:top-[75px] w-full text-center uppercase text-sm lg:text-lg !leading-[1.35] tracking-[1.4px] lg:tracking-[1.8px]">
        Membership benefits
      </div>

      <div className="mx-auto max-w-[1280px]">
        <MarketingH2 className="mb-2.5 sm:max-w-[360px] md:max-w-none lg:max-w-[650px] xl:max-w-none">
          {content.membershipTitle}
        </MarketingH2>
        <MarketingH3 className="sm:max-w-[400px] lg:max-w-[640px] xl:max-w-none mx-auto">
          {content.membershipSubtitle}
        </MarketingH3>
      </div>

      <div className="flex flex-col lg:flex-row lg:items-center justify-between">
        <LottieInView
          animationData={coursesAnimation}
          className="w-full shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[700px] mb-[25px] lg:mb-0 hidden lg:block"
          loop={false}
        />

        <Image
          src={coursesStaticImage}
          alt=""
          className="w-full mx-auto shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[700px] mb-[25px] lg:mb-0 lg:hidden"
        />
        <div className="flex flex-col items-center">
          {content.membershipBenefits1.map((benefit, i) => (
            <p
              className="text-lg lg:text-2xl !leading-[1.35] mb-3 w-full sm:max-w-[400px] lg:max-w-[350px] xl:max-w-[470px]"
              key={i}
            >
              {benefit}
            </p>
          ))}

          <Button
            href={content.membershipBenefits1Cta.ctaLink}
            className="p-0 text-sm lg:text-lg font-normal !leading-[1.35] underline hover:bg-transparent hover:underline"
            variant="text-only"
            onClick={onContentCtaClick(content.membershipBenefits1Cta)}
          >
            {content.membershipBenefits1Cta.ctaText}
          </Button>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row-reverse lg:items-center justify-between">
        <LottieInView
          animationData={eventsAnimation}
          className="w-full shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[700px] mb-[25px] lg:mb-0 hidden lg:block"
          loop={false}
        />

        <Image
          src={eventsStaticImage}
          alt=""
          className="w-full mx-auto shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[700px] mb-[25px] lg:mb-0 lg:hidden"
        />
        <div className="flex flex-col items-center">
          {content.membershipBenefits2.map((benefit, i) => (
            <p
              className="text-lg lg:text-2xl !leading-[1.35] mb-3 w-full sm:max-w-[400px] lg:max-w-[350px] xl:max-w-[470px]"
              key={i}
            >
              {benefit}
            </p>
          ))}

          {content.membershipBenefits2Cta && (
            <Button
              href={content.membershipBenefits2Cta.ctaLink}
              className="p-0 text-sm lg:text-lg font-normal !leading-[1.35] underline hover:bg-transparent hover:underline"
              variant="text-only"
              onClick={onContentCtaClick(content.membershipBenefits2Cta)}
            >
              {content.membershipBenefits2Cta.ctaText}
            </Button>
          )}
        </div>
      </div>

      {/* main cta here */}
      <ButtonMarketingCTA
        href={content.mainCta.ctaLink}
        location="marketing_homepage__courses_section"
        className="self-center"
      >
        {showFreeTrial ? 'Start a 14-day free trial' : content.mainCta.ctaText}
      </ButtonMarketingCTA>
    </section>
  )
}
export default MembershipSection
