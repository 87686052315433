import { useBlock } from '@dopt/react'
import React from 'react'
import ScrollToBottom from 'react-scroll-to-bottom'

import { ChatMessageMarkdown, useMessageCard } from 'domains/Ai/Chat/MessageCard'
import ScrollToBottomButton from 'domains/Ai/Chat/ScrollToBottomButton'
import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import Button from 'components/Button'
import DoptCallout from 'components/dopt/DoptCallout'

import { Message } from 'hooks/ai/types'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as MagicWandIcon } from 'images/icon--magic-wand.svg'
import { ReactComponent as RewardIcon } from 'images/icon--reward-stars.svg'

import { useResultHighlights } from './ResultsHighlightsProvider'

export const ResultHighlights = () => {
  const { messages, isLoading } = useResultHighlights()
  const { openChatWithMessages } = useGlobalChat()
  const blockArray = useBlock<['complete']>(
    'ai-chat-product-tour-p3.callout.search-ask-question'
  )

  const ctaText = 'Start a chat'
  const resultMessage = messages[1]

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'search_result_AI_chat_sidekick',
      cta_type: 'button',
      text: 'ask_question'
    })
    openChatWithMessages(messages, ctaText)
    if (blockArray) {
      const [, transition] = blockArray
      transition('complete')
    }
  }

  if (!resultMessage) {
    return (
      <Container>
        <ResultHighlightsLoading />
      </Container>
    )
  }

  return (
    <Container>
      <ScrollToBottom
        className="pb-6"
        followButtonClassName="hidden"
        scrollViewClassName="hide-scrollbar max-h-[335px]"
      >
        <ScrollToBottomButton extraClasses="right-4" />
        <ResultsHighlightCard message={resultMessage} />
      </ScrollToBottom>

      <div className="relative flex w-full max-w-md items-center self-center">
        <Button
          size="medium"
          data-dd-action-name="Open AI sidekick modal"
          onClick={handleClick}
          onKeyDown={onEnterKeyPress(handleClick)}
          disabled={isLoading}
          className="text-md flex h-10 w-full max-w-md cursor-pointer items-center gap-x-2 self-center border-0 bg-rb-teal-50 p-0 text-rb-black hover:bg-rb-teal-60 hover:text-rb-black active:bg-rb-teal-60"
        >
          <span>{ctaText}</span>
          <RewardIcon width={16} />
        </Button>
        <DoptCallout position="left" blockArray={blockArray} />
      </div>
    </Container>
  )
}

export const ResultHighlightsLoading = () => (
  <div className="flex h-full flex-col place-items-center justify-center self-center text-sm">
    <MagicWandIcon width={56} className="chromatic-ignore mb-4 animate-bounce" />
    <div className="mb-4 font-semibold">Summarizing magic in progress!</div>
    <div>Your summary is coming soon...</div>
  </div>
)

const Container = ({
  children
}: {
  children: React.ReactNode | Array<React.ReactNode>
}) => (
  <div className="flex w-full flex-col rounded-md border border-rb-gray-100 p-6">
    <h3 className="m-0 pb-6">Result Highlights</h3>
    {children}
  </div>
)

function ResultsHighlightCard({ message }: { message: Message }) {
  const { content, components } = useMessageCard({
    message
  })

  return (
    <div className={cn('bg-rb-white text-sm text-rb-black/85')}>
      <ChatMessageMarkdown key={message.id} components={components}>
        {content}
      </ChatMessageMarkdown>
    </div>
  )
}
