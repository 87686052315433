import { useEffect, useState } from 'react'

import { EventCardHeader } from 'pages/EventsUpcoming/components/EventCardHeader'

import BaseCard, { CardVariant, CardVariants } from 'components/cards/Content/BaseCard'
import BaseCardPlayIcon from 'components/icons/BaseCardPlayIcon'

import {
  BookmarkFolderPartsFragment,
  EventBookmarkPartsFragment,
  EventPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  FULL_DATE_ABBREV_MONTH_FORMAT,
  formatInTimezone,
  getCurrentTimezone
} from 'utils/date'
import { cn } from 'utils/tailwind'
import { trackContentClicked } from 'utils/tracking/analytics'

import { calculateDuration } from './helper'
import ReplayBlue from './img/replay-blue.png'
import ReplayGreen from './img/replay-green.png'
import ReplayPink from './img/replay-pink.png'
import ReplayTeal from './img/replay-teal.png'
import ReplayYellow from './img/replay-yellow.png'

type EventPastRecordingProps = {
  inNewTab?: boolean
  event: EventPartsFragment
  imgVariants: any
  variant?: CardVariant
  pageLocation?: string
  bookmark?: EventBookmarkPartsFragment | undefined
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
}

export function EventRecordingCard({
  inNewTab,
  event,
  imgVariants,
  variant = CardVariants.Vertical,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  pageLocation = 'event_index',
  handleRemoveFromFolder
}: EventPastRecordingProps) {
  const { currentUser, isLoggedIn } = useCurrentUser()
  const [duration, setDuration] = useState(0)
  const userTimezone = getCurrentTimezone(currentUser?.timezone)
  const { name, startsAtUtc, hosts, featuredImageUrl, summaryWistiaCodeOptional } = event

  const monthDate = formatInTimezone(
    startsAtUtc,
    userTimezone,
    FULL_DATE_ABBREV_MONTH_FORMAT
  )

  const variants: any = {
    blue: ReplayBlue,
    pink: ReplayPink,
    green: ReplayGreen,
    yellow: ReplayYellow,
    teal: ReplayTeal
  }
  const handleTracking = () => {
    trackContentClicked({
      content_id: event.id,
      content_title: event.name,
      logged_in: isLoggedIn,
      location: pageLocation,
      content_type: 'event',
      related_identifiers: {
        event_type: event.kind,
        content_mode: isLoggedIn ? 'async' : 'preview'
      }
    })
  }

  useEffect(() => {
    const controller = new AbortController() // Create an instance of AbortController

    if (summaryWistiaCodeOptional && process.env.NODE_ENV === 'production') {
      fetch(
        `https://fast.wistia.com/embed/medias/${summaryWistiaCodeOptional}.json`,
        { signal: controller.signal } // Pass the abort signal to fetch
      )
        .then(async (response) => {
          if (response.ok) {
            const obj = await response.json()
            setDuration(obj?.media?.duration)
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('Fetch failed:', error)
          }
        })
    }

    return () => {
      controller.abort() // Abort the fetch request on component unmount
    }
  }, [summaryWistiaCodeOptional])

  const Thumbnail = (
    <div
      className={cn(
        'flex h-full w-full items-center justify-center rounded-t-xl bg-cover bg-center bg-no-repeat',
        variant === 'mini' ? 'rounded-xl' : 'rounded-t-xl '
      )}
      style={{
        backgroundImage: `url(${featuredImageUrl || variants[imgVariants]})` // Set background image using inline styles
      }}
    >
      <BaseCardPlayIcon />
    </div>
  )

  return (
    <BaseCard
      trackCardClick={handleTracking}
      key={`event-${event.id}`}
      variant={variant}
      eventId={Number(event.id)}
      contentType="Event"
      destination={`/events/${event.id}-${event.slug}/summary`}
      hideBookmarkButton={!isLoggedIn}
      header={<EventCardHeader hosts={hosts} />}
      body
      bookmark={bookmark}
      title={name}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      inNewTab={inNewTab}
      horizontalThumbnail={Thumbnail}
      verticalThumbnail={Thumbnail}
      thumbnail={Thumbnail}
      footer={
        <div className="flex items-end justify-between">
          <div className="flex font-sans text-xs font-normal">
            <span className="text-rb-gray-300">{`Recorded ${monthDate}`}</span>
            {!!duration && (
              <div className="flex">
                <div className="mx-2 flex items-center justify-center">
                  <div className="h-[2px] w-[2px] rounded-full bg-rb-gray-300"></div>
                </div>
                <span className="text-rb-gray-300">{calculateDuration(duration)}</span>
              </div>
            )}
          </div>
        </div>
      }
    />
  )
}
