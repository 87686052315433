import { History } from 'history'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { EventPastRecordings } from 'pages/EventRecordings/EventPastRecordings'

import { ErrorMessage, Loading } from 'components'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import Tabs from 'components/Tabs'

import {
  EventFilters,
  EventPartsFragment,
  UserEventsQuery,
  usePublicEventsQuery,
  useUserEventsQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'

import { UpcomingEventContainer } from '../EventsUpcoming/components/UpcomingEventContainer'
import { MyEvents } from './components/MyEvents'

// eslint-disable-next-line camelcase
export type QueryParamEventFilters = EventFilters & { event_type?: string[] }

const getIterableEvents = (events: any) => events || []

const EventsContainer = () => {
  const history = useHistory()
  const { currentUser, isLoggedIn } = useCurrentUser()

  const { loading, error, data, refetch } = useUserEventsQuery({
    variables: { userId: currentUser?.id as any },
    skip: !isLoggedIn,
    fetchPolicy: 'network-only'
  })

  const {
    loading: publicQueryLoading,
    error: publicQueryError,
    data: publicQueryData
  } = usePublicEventsQuery({
    skip: isLoggedIn
  })

  if (loading || publicQueryLoading) {
    return <Loading />
  }
  if (error || publicQueryError) {
    return <ErrorMessage error={error || publicQueryError} />
  }

  const user = data?.user
  const upcomingEvents = isLoggedIn
    ? data?.user?.upcomingEligibleEvents?.slice(0, 2) || []
    : publicQueryData?.publicEvents?.slice(0, 2) || []

  // Combine various event types into single array for simple display..
  const events = [
    ...getIterableEvents(user?.eventsNotAttending || []),
    ...getIterableEvents(user?.eventsRecommended || [])
  ]

  return (
    <>
      <Events
        history={history}
        user={user || undefined}
        upcomingEvents={upcomingEvents}
        events={events}
        refetch={refetch}
      />
      {isLoggedIn && <ShareableSnapshotModal page="Events Index" />}
    </>
  )
}

export interface EventsProps {
  history: History
  user: NonNullable<UserEventsQuery['user']> | undefined
  upcomingEvents: EventPartsFragment[]
  events: EventPartsFragment[]
  refetch: () => void
}

const Events = ({ upcomingEvents }: EventsProps) => {
  const router = useHistory()

  const { isLoggedIn } = useCurrentUser()

  const TopicFilters = useMemo(() => {
    return {
      Discover: 'Discover',
      MyEvents: 'My Events'
    }
  }, [])

  const [topicFilter, setTopicFilter] = useState<string>(TopicFilters.Discover)

  const handleEventIndexTabsClick = (filter: string) => {
    if (filter === topicFilter) return
    setTopicFilter(filter)
    trackNavigationClicked({
      location: 'event_index',
      text: filter.toLowerCase(),
      type: 'button'
    })

    router.push('/events/my-events')
  }

  const handleTrackingViewAllUpcoming = () => {
    trackNavigationClicked({
      location: 'event_index',
      text: 'view all upcoming events',
      type: 'hyperlink text'
    })
  }

  const handleTrackingViewAllReplays = () => {
    trackNavigationClicked({
      location: 'event_index',
      text: 'view all event replays',
      type: 'hyperlink text'
    })
  }

  return (
    <>
      {isLoggedIn && (
        <div className="mb-4">
          <Tabs
            tabs={Object.values(TopicFilters).map((filter) => ({
              label: filter,
              onClick: () => handleEventIndexTabsClick(filter),
              isActive: filter === TopicFilters.Discover
            }))}
          />
        </div>
      )}
      <div className="flex">
        <div
          className={cn(
            'h-full overflow-auto pb-2.5 xs:pb-4',
            topicFilter !== TopicFilters.Discover && 'w-3/4',
            TopicFilters.MyEvents && 'w-full',
            !isLoggedIn && 'px-4 md:px-[4vw] lg:pl-[4vw]'
          )}
        >
          {topicFilter === TopicFilters.Discover && (
            <>
              {upcomingEvents.length > 0 && (
                <div className="mt-[44px] max-w-[1000px]">
                  <div className="flex items-end pb-4">
                    <div className="mr-6 font-sans text-xl font-semibold text-rb-gray-400">
                      Upcoming events
                    </div>

                    <Link
                      onClick={handleTrackingViewAllUpcoming}
                      to="/events/upcoming"
                      className="mb-[2.5px] flex items-center font-sans text-sm font-medium text-rb-gray-300 no-underline hover:no-underline"
                    >
                      view all
                      <ChevronRight width={8} className="ml-2 fill-current" />
                    </Link>
                  </div>

                  <UpcomingEventContainer events={upcomingEvents} />
                </div>
              )}

              <div className="mt-[60px] max-w-[1000px]">
                <div className="flex items-end pb-4">
                  <div className="mr-6 flex items-end font-sans text-xl font-semibold text-rb-gray-400">
                    Event replays
                  </div>

                  <Link
                    onClick={handleTrackingViewAllReplays}
                    to="/events/recordings"
                    className="mb mb-[2px] flex items-center font-sans text-sm font-medium text-rb-gray-300 no-underline hover:no-underline"
                  >
                    view all
                    <ChevronRight width={8} className="ml-2 fill-current" />
                  </Link>
                </div>

                <EventPastRecordings />
              </div>
            </>
          )}

          {topicFilter === TopicFilters.MyEvents && <MyEvents />}
        </div>
      </div>
    </>
  )
}

Events.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object,
  attendingEvents: PropTypes.array,
  notAttendingEvents: PropTypes.array,
  pastEvents: PropTypes.array,
  events: PropTypes.array,
  attendingTitle: PropTypes.string,
  notAttendingTitle: PropTypes.string,
  recommendedTitle: PropTypes.string,
  pastTitle: PropTypes.string
}

Events.defaultProps = {
  attendingEvents: [],
  notAttendingEvents: [],
  pastEvents: [],
  events: [],
  attendingTitle: "Events you're attending", // eslint-disable-line quotes
  notAttendingTitle: "Can't make it", // eslint-disable-line quotes
  recommendedTitle: 'Recommended for you',
  pastTitle: 'Past events'
}

export default EventsContainer
