import RichText from '@dopt/react-rich-text'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import SlackBanner from 'components/dopt/SlackBanner'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

export interface AnnouncementBannerProps {
  activeDoptComponents: any
}

const DoptAnnouncementBanner = ({
  doptBlockArray,
  identifier,
  isVisible
}: {
  doptBlockArray: any
  identifier: string
  isVisible: boolean
}) => {
  const history = useHistory()
  if (!doptBlockArray || doptBlockArray.length === 0 || !isVisible) return null

  if (identifier === 'slack-announcement-banner.banner') {
    return <SlackBanner blockArray={doptBlockArray} />
  }

  const [block, transition] = doptBlockArray

  const onClick = () => {
    transition('complete')
    history.push(block.field('cta-url'))
  }

  const image = null // block.field('image') - removed due to image causing layout issues on the banner on the new feed, to be redesigned in the future - check REF-15226

  return (
    <div
      className={cn(
        'flex w-full flex-col justify-center gap-8 self-stretch rounded-xl h-auto md:h-auto p-6 md:px-12 lg:py-0 md:flex-row md:items-center',
        image && 'lg:pl-0'
      )}
      style={{ backgroundColor: block.field('color') }}
    >
      {image && (
        <div
          className={cn(
            'hidden items-center lg:flex max-w-[184px] h-full [&_img]:object-cover [&_*]:h-full'
          )}
        >
          <RichText>{image}</RichText>
        </div>
      )}
      <div className="flex w-full flex-col gap-8 md:flex-1 md:flex-row">
        <div className="lg:py-12">
          <div className="text-[28px] font-light leading-[1.25] tracking-[-0.56px] mb-2">
            {block.field('header')}
          </div>
          <div className="leading-[1.6]">{block.field('subheader')}</div>
        </div>
      </div>
      <div>
        <Button
          onClick={onClick}
          variant="outline"
          size="small"
          className="font-semibold"
        >
          {block.field('cta-text')}
        </Button>
      </div>
    </div>
  )
}

const CarouselDot = ({ active, onClick }: { active: boolean; onClick: () => void }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(onClick)}
      onClick={onClick}
      className={`h-3 w-3 rounded-full ${
        active ? 'bg-rb-green-100' : 'bg-rb-gray-100 hover:bg-rb-gray-200'
      }`}
    ></div>
  )
}

const AnnouncementBanner = ({ activeDoptComponents }: AnnouncementBannerProps) => {
  const [indexVisible, setIndexVisible] = useState(0)

  if (!activeDoptComponents || !Object.keys(activeDoptComponents).length) return null

  const doptIds = Object.keys(activeDoptComponents)

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-4 mb-12 self-stretch text-rb-gray-400'
      )}
    >
      <div className="flex w-full font-polysans">
        {doptIds.map((componentId, i) => (
          <DoptAnnouncementBanner
            key={componentId}
            doptBlockArray={activeDoptComponents[componentId]}
            identifier={componentId}
            isVisible={indexVisible === i}
          />
        ))}
      </div>
      {doptIds.length > 1 && (
        <div className="flex w-full justify-center gap-2">
          {doptIds.map((_, i) => (
            <CarouselDot
              key={i}
              active={indexVisible === i}
              onClick={() => setIndexVisible(i)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AnnouncementBanner
