import { ReactComponent as MinusIcon } from 'icon--minus-sign.svg'
import { ReactComponent as PlusIcon } from 'icon--plus-sign.svg'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import CourseDetailCourseBlockArtifact from 'domains/CourseDetail/CourseDetailCourseBlockArtifact'
import CourseDetailCourseBlockEvent from 'domains/CourseDetail/CourseDetailCourseBlockEvent'
import CourseDetailCourseBlockGuide from 'domains/CourseDetail/CourseDetailCourseBlockGuide'

import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { MIN_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import { CourseDetailCourseBlockPartsFragment, CourseDetailPartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { formatTimeAsHoursMinutes, getDurationInHours } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackExpandClick } from 'utils/tracking/generated/events'

interface CourseDetailContentProps {
  position: number
  courseBlock: CourseDetailCourseBlockPartsFragment
  courseDetail: CourseDetailPartsFragment
}

const CourseDetailContent = ({
  position,
  courseBlock,
  courseDetail
}: CourseDetailContentProps) => {
  const [expanded, setExpanded] = useState(false)
  const isSmallScreen = useMediaQuery(`(max-width: ${MIN_WIDTH_TAILWIND_TL})`)

  const onExpandCollapseClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    if (!expanded) {
      trackExpandClick({
        expandable_section_title: courseBlock.title || '',
        expandable_section_type: 'module_block',
        location: 'course_details_page',
        related_identifiers: {
          ccl_course_id: courseDetail?.id,
          course_title: courseDetail?.title,
          course_sanity_id: courseDetail?.sourceId,
          content_block_id: courseBlock.id || '',
          content_block_title: courseBlock.title || '',
          position: position,
          content_mode: 'preview'
        }
      })
    }

    setExpanded((prevState) => !prevState)
  }

  const totalReadTime =
    courseBlock?.guides?.reduce((acc, unit) => {
      return acc + (unit?.estimatedMinutes || 0)
    }, 0) || 0

  const otherEvents =
    courseBlock?.events?.filter((event) => {
      return !courseBlock?.eventPreviews?.some(
        (preview) =>
          preview.sourceId?.toLowerCase()?.trim() ===
          event.sourceId?.toLowerCase()?.trim()
      )
    }) || []

  const totalHoursForEvents = courseBlock?.events?.reduce((acc, event) => {
    return acc + getDurationInHours(event.startsAt, event.endsAt)
  }, 0)

  const showOtherEvents =
    (!courseBlock?.eventPreviews || courseBlock?.eventPreviews?.length === 0) &&
    otherEvents &&
    otherEvents.length > 0

  const guides = [...(courseBlock?.guides || [])]?.sort(
    (a, b) => a.displayOrder - b.displayOrder
  )

  return (
    <div
      className={twMerge(
        'flex flex-col rounded-2xl border border-gray-100 transition-all duration-500 ease-in-out hover:shadow-md'
      )}
    >
      <div
        className="flex items-start justify-between p-6 sm:items-center sm:p-8"
        role="button"
        tabIndex={0}
        onKeyUp={onEnterKeyPress(onExpandCollapseClick)}
        onClick={onExpandCollapseClick}
      >
        <div className="space-y-4">
          <div className="flex flex-col items-start gap-1 sm:flex-row sm:items-center sm:gap-4">
            <RfParagraphSmall>
              <span className="font-semibold">Module {position}</span>
            </RfParagraphSmall>

            {((expanded && isSmallScreen) || !isSmallScreen) && (
              <div className="flex items-center gap-2">
                {totalReadTime > 0 && (
                  <RfParagraphSmall>
                    <span className="text-rb-gray-300">
                      {formatTimeAsHoursMinutes(totalReadTime)} read
                    </span>
                  </RfParagraphSmall>
                )}
                {totalHoursForEvents > 0 && totalReadTime > 0 && (
                  <span className="text-base text-rb-gray-300">·</span>
                )}
                {totalHoursForEvents > 0 && (
                  <RfParagraphSmall>
                    <span className="text-rb-gray-300">
                      {totalHoursForEvents} hr live event
                    </span>
                  </RfParagraphSmall>
                )}
              </div>
            )}
          </div>
          <RfParagraphLarge>
            <span className="text-[20px] font-medium">{courseBlock.title}</span>
          </RfParagraphLarge>
        </div>

        {expanded ? (
          <button
            data-test="course_content_block_chevron"
            onClick={onExpandCollapseClick}
            className="flex items-center justify-center"
          >
            <MinusIcon className="text-rb-gray-600 h-[16px] w-[16px] sm:h-[24px] sm:w-[24px]" />
          </button>
        ) : (
          <button
            data-test="course_content_block_chevron"
            onClick={onExpandCollapseClick}
            className="flex items-center justify-center"
          >
            <PlusIcon className="text-rb-gray-600 h-[16px] w-[16px] sm:h-[24px] sm:w-[24px]" />
          </button>
        )}
      </div>

      {expanded && (
        <div className="space-y-8 p-6 !pt-0 sm:p-8">
          {/* Guides */}
          {guides && guides.length > 0 && (
            <div className="space-y-4">
              <RfParagraphSmall>
                <span className="font-medium">Step-by-step guide</span>
              </RfParagraphSmall>

              <div className="flex flex-col gap-12">
                {guides?.map((guide, index) => (
                  <CourseDetailCourseBlockGuide
                    guide={guide}
                    key={guide.id}
                    guideIndex={index}
                    courseDetail={courseDetail}
                    courseBlock={courseBlock}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Artifacts */}
          {courseBlock?.artifacts && courseBlock.artifacts.length > 0 && (
            <div className="space-y-4">
              <RfParagraphSmall>
                <span className="font-medium">Real world examples</span>
              </RfParagraphSmall>

              <div className="flex flex-col gap-6">
                {courseBlock?.artifacts?.map((artifact) => (
                  <CourseDetailCourseBlockArtifact
                    artifact={artifact}
                    key={artifact.id}
                    courseDetail={courseDetail}
                    courseBlock={courseBlock}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Events */}
          {((courseBlock?.eventPreviews && courseBlock.eventPreviews.length > 0) ||
            (otherEvents && otherEvents.length > 0)) && (
            <div className="space-y-4">
              <div className="flex items-center gap-4">
                <RfParagraphSmall>
                  <span className="font-medium">Live events</span>
                </RfParagraphSmall>

                <div className="rounded-full bg-rb-gray-50 px-3 py-1">
                  <span className="text-xs">Only in live course</span>
                </div>
              </div>

              {courseBlock?.eventPreviews && courseBlock.eventPreviews.length > 0 && (
                <div className="flex flex-col gap-6">
                  {courseBlock?.eventPreviews?.map((event) => (
                    <CourseDetailCourseBlockEvent
                      key={event.id}
                      event={event}
                      courseDetail={courseDetail}
                      courseBlock={courseBlock}
                      showPreview
                    />
                  ))}
                </div>
              )}

              {showOtherEvents && (
                <div className="flex flex-col gap-6">
                  {otherEvents.map((event) => (
                    <CourseDetailCourseBlockEvent
                      event={event}
                      key={event.id}
                      courseDetail={courseDetail}
                      courseBlock={courseBlock}
                      showPreview={false}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CourseDetailContent
