import { ReactNode, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import CourseSeoFunctionLinkSection from 'domains/CoursesSeo/CourseSeoFunctionLinkSection'
import CoursesSeoCarouselSection from 'domains/CoursesSeo/CoursesSeoCarouselSection'
import ExpertsCarouselSection from 'domains/CoursesSeo/ExpertsCarouselSection'
import LogoSection from 'domains/CoursesSeo/LogoSection'
import PopularCoursesSection from 'domains/CoursesSeo/PopularCoursesSection/PopularCoursesSection'
import TestimonialsCarouselSection from 'domains/CoursesSeo/TestimonialsCarouselSection'
import ValuePropSection from 'domains/CoursesSeo/ValuePropSection'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import RelatedBlogPostsCarouselSection from 'domains/Seo/RelatedBlogPostsCarouselSection'
import { SeoTopicSection } from 'domains/Seo/Section/SeoTopicSection'
import SeoPageCtaSection from 'domains/Seo/SeoPageCtaSection'
import SeoPageHero from 'domains/Seo/SeoPageHero'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'

import {
  ArtifactAuthor,
  BlogPost,
  CourseConcepts,
  CourseForTopicAndFunction,
  PageSeo,
  PageTypeEnum,
  SanityTestimonial,
  useBlogPostsForTopicQuery,
  useCourseConceptsQuery,
  useCourseFunctionQuery,
  useCourseTopicQuery,
  useCoursesForTopicAndFunctionQuery,
  useTotalCourseCountQuery
} from 'gql'

import { titleize } from 'utils/stringUtils'

import ShareGraphic from 'images/ReforgeHomeSocialShare.jpg'
import BackgroundImageMobile from 'images/course-topic-banner-mobile.png'
import BackgroundImage from 'images/course-topic-banner.png'

export type Concept = {
  id: string
  title: string
  type: 'topic' | 'functionFilter'
}

const CourseSeoLandingPage = () => {
  const { query } = useParams<{ query: string }>()

  const { data: topicData, loading: topicDataLoading } = useCourseTopicQuery({
    variables: {
      slug: query
    }
  })

  const { data: functionData, loading: functionDataLoading } = useCourseFunctionQuery({
    variables: {
      slug: query
    }
  })

  const { data: coursesData, loading: coursesDataLoading } =
    useCoursesForTopicAndFunctionQuery({
      variables: {
        slug: query
      }
    })

  const { data: courseConcepts, loading: courseConceptsLoading } = useCourseConceptsQuery(
    {
      variables: {
        slug: query
      }
    }
  )

  const { data: totalCourseCount } = useTotalCourseCountQuery()

  const { data: blogPostsData } = useBlogPostsForTopicQuery({
    variables: {
      slug: query
    }
  })

  const topic = topicData?.topic
  const functionFilter = functionData?.function

  const handleRender = (content: ReactNode) => {
    if (!topic && !functionFilter) {
      return <Content404 />
    }

    if (topic && topic.isDeprecated && !functionFilter) {
      return <Redirect to={topic.redirectTo!} />
    }

    if (functionFilter && functionFilter.isDeprecated && !topic) {
      return <Redirect to={functionFilter.redirectTo!} />
    }

    return content
  }

  const concept = useMemo(() => {
    if (topic) {
      return {
        id: topic.id,
        title: topic.title,
        type: 'topic'
      } as Concept
    }

    if (functionFilter) {
      return {
        id: functionFilter.id,
        title: functionFilter.title,
        type: 'functionFilter'
      } as Concept
    }

    return null
  }, [topic, functionFilter])

  const page: GenericPageType = useMemo(() => {
    if (concept) {
      const title = `${titleize(concept.title)} Courses Online - Reforge`
      const description = `The most effective online ${concept.title.toLowerCase()} courses. Reforge’s ${concept.title.toLowerCase()} courses allow you to unlock step-change growth in your career in less than six weeks.`

      return {
        title,
        seo: {
          metaTitle: title,
          metaDesc: description,
          shareTitle: title,
          shareDesc: description,
          shareGraphic: { asset: ShareGraphic },
          shareGraphicAlt: title,
          type: 'website' as PageTypeEnum
        } as PageSeo
      }
    }

    return {}
  }, [concept])

  return (
    <MarketingLayout page={page}>
      {topicDataLoading || functionDataLoading ? (
        <Loading />
      ) : (
        handleRender(
          <CourseSeoLandingPageContent
            concept={concept!}
            courses={coursesData?.coursesForTopicAndFunction}
            totalCourseCount={totalCourseCount?.totalCourseCount}
            courseConcepts={courseConcepts?.courseConcepts}
            courseConceptsLoading={courseConceptsLoading}
            coursesLoading={coursesDataLoading}
            slug={query}
            blogPosts={blogPostsData?.blogPostsForTopic}
          />
        )
      )}
    </MarketingLayout>
  )
}

export interface CourseSeoLandingPageContentProps {
  concept: Concept
  courses?: CourseForTopicAndFunction[] | null
  totalCourseCount?: number
  courseConcepts?: CourseConcepts | null
  courseConceptsLoading: boolean
  coursesLoading: boolean
  slug: string
  blogPosts?: BlogPost[] | null
}

export const CourseSeoLandingPageContent = ({
  concept,
  courses,
  totalCourseCount,
  courseConcepts,
  courseConceptsLoading,
  coursesLoading,
  slug,
  blogPosts
}: CourseSeoLandingPageContentProps) => {
  const title = `Online ${concept.title.toLowerCase()} courses taught by tech experts`
  const subtitle = `Reforge’s ${concept.title.toLowerCase()} courses allow you to unlock step-change growth in your career in less than six weeks.`
  const ctaText = 'Explore all courses'
  const ctaHref = '/courses'
  const tracking: SEOTrackingRelatedIdentifiers = {
    sanityType: concept.type,
    sanityId: concept.id || '',
    sanityName: concept.title.toLowerCase()
  }
  const coursesToDisplayCount = courses?.length || 0
  const MIN_LARGE_PAGE_COUNT = 8
  const MIN_COURSES_FOR_FEATURED = coursesToDisplayCount >= MIN_LARGE_PAGE_COUNT ? 5 : 3
  const MIN_COURSES_FOR_CAROUSEL = coursesToDisplayCount >= MIN_LARGE_PAGE_COUNT ? 8 : 6

  const popularCourses =
    coursesToDisplayCount < MIN_COURSES_FOR_CAROUSEL
      ? courses || []
      : courses?.slice(0, MIN_COURSES_FOR_FEATURED) || []
  const carouselCourses =
    coursesToDisplayCount >= MIN_COURSES_FOR_CAROUSEL
      ? courses?.slice(MIN_COURSES_FOR_FEATURED) || []
      : null

  const experts = useMemo(() => {
    if (courses) {
      return [
        ...new Set(
          courses
            .map((course) => course.creators)
            .flat()
            .filter(Boolean)
        )
      ] as ArtifactAuthor[]
    }
    return []
  }, [courses])

  const testimonials = useMemo(() => {
    if (courses) {
      return [
        ...new Set(
          courses
            .map((course) => [
              ...(course.testimonials?.map((t) => ({
                ...t,
                course: {
                  __typename: course.__typename,
                  id: course.id,
                  title: course.title
                }
              })) || [])
            ])
            .flat()
            .filter(Boolean)
        )
      ] as (SanityTestimonial & {
        course: { __typename: string; id: string; title: string }
      })[]
    }
    return []
  }, [courses])

  interface CourseFunction {
    id: string
    title: string
    slug: string
  }

  interface CourseTopic {
    id: string
    title: string
    slug: string
  }

  const courseFunctions =
    courseConcepts?.functions?.map(({ id, title, slug }) => ({ id: id!, title, slug })) ||
    []
  const courseTopics =
    courseConcepts?.topics?.map(({ id, title, slug }) => ({ id: id!, title, slug })) || []
  const coursesFunctionAndTopics: (CourseTopic | CourseFunction)[] = [
    ...courseFunctions,
    ...courseTopics
  ]

  return (
    <>
      <BaseBreadcrumbs
        className="mr-5 pb-14 pl-4 md:pl-[4vw] md:pb-8"
        breadcrumbPages={[
          {
            title: 'Courses',
            path: '/courses'
          },
          {
            title: concept?.title ?? '',
            path: `/course-categories/${slug}`
          }
        ]}
      />
      <SeoPageHero
        title={title}
        subtitle={subtitle}
        ctaText={ctaText}
        ctaHref={ctaHref}
        bgImage={BackgroundImage}
        bgImageMobile={BackgroundImageMobile}
        tracking={tracking}
      />

      <div className="px-4 md:px-[4vw]">
        <div className="mx-auto max-w-[1024px]">
          <PopularCoursesSection
            concept={concept}
            totalCourseCount={totalCourseCount}
            courses={popularCourses}
            coursesLoading={coursesLoading}
          />
        </div>
      </div>

      <LogoSection />

      <div className="px-4 md:px-[4vw]">
        <div className="mx-auto max-w-[1024px]">
          {carouselCourses && (
            <CoursesSeoCarouselSection
              concept={concept}
              courses={carouselCourses}
              totalCourseCount={totalCourseCount}
              tracking={tracking}
            />
          )}
        </div>
      </div>

      <ValuePropSection tracking={tracking} />

      <div className="px-4 md:px-[4vw]">
        <div className="mx-auto max-w-[1024px]">
          {experts && experts.length > 0 && (
            <ExpertsCarouselSection
              experts={experts}
              tracking={tracking}
              concept={concept}
            />
          )}

          <CourseSeoFunctionLinkSection
            functions={courseConcepts?.functions || []}
            title="The right course, no matter your role"
            subtitle="Check out popular courses by function to find the right course for you"
            tracking={tracking}
            loading={courseConceptsLoading}
          />

          <SeoPageCtaSection
            tracking={tracking}
            ctaText="Explore membership"
            ctaHref="/membership"
            ctaLocation="SEO courses page"
          />

          {testimonials && testimonials.length > 0 && (
            <TestimonialsCarouselSection testimonials={testimonials} />
          )}

          {blogPosts && blogPosts.length > 0 && (
            <RelatedBlogPostsCarouselSection blogPosts={blogPosts} />
          )}

          {!courseConceptsLoading && (
            <SeoTopicSection
              type="courseTopics"
              courseTopicsAndFunctions={coursesFunctionAndTopics}
              title="Other course categories"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CourseSeoLandingPage
