import React, {
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { PopoverPosition } from 'react-tiny-popover'
import { twMerge } from 'tailwind-merge'

import ContentBookmark, { ContentBookmarkID } from 'domains/Cms/ContentBookmark'

import PremiumPill from 'components/PremiumPill'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  BookmarkFolderPartsFragment,
  CmsSectionContentType,
  CourseBookmarkPartsFragment,
  EventBookmarkPartsFragment,
  Maybe,
  ProgramBookmarkPartsFragment,
  useTrackServerEventMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'
import { getAnonymousId } from 'utils/tracking/segment'

export enum CardVariants {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Mini = 'mini',
  BiggerMini = 'BiggerMini',
  List = 'list'
}
export type CardVariantKey = keyof typeof CardVariants // 'Horizontal'
export type CardVariant = (typeof CardVariants)[CardVariantKey] // 'horizontal'

export interface BaseCardProps {
  id?: string
  variant?: CardVariant
  header?: ReactNode
  title: ReactNode
  byline?: ReactNode
  body: ReactNode
  footer?: ReactNode
  thumbnail?: ReactNode
  horizontalThumbnail?: ReactNode
  verticalThumbnail?: ReactNode
  // Path to route to when the card is clicked.
  destination: string
  // Whether to open the route in a new tab
  inNewTab?: boolean
  // Optional state to pass to history.push
  historyState?: {}
  // Invoked when the card is clicked, before navigation handling is performed.
  trackCardClick?: (e: MouseEvent | KeyboardEvent) => void
  // Invoked before trackCardClick and navigation handling. This can replace navigation behavior if `destination`
  // is set to an empty string.
  onClick?: (e: MouseEvent | KeyboardEvent) => void
  // Bookmark-related props
  // Invoked when the bookmark save button is clicked (saving or removing)
  onSaveClick?: (isSaved: boolean) => void
  hideBookmarkButton?: boolean
  contentType:
    | CmsSectionContentType
    | 'Program'
    | 'Artifact'
    | 'Course'
    | 'Guide'
    | 'Event'
    | 'BlogPost'
  cmsContentId?: Maybe<string | number>
  cmsModuleId?: Maybe<string | number>
  cmsProgramId?: Maybe<string | number>
  sanityId?: Maybe<string>
  eventId?: number
  courseSlug?: Maybe<string>
  bookmarkId?: ContentBookmarkID
  bookmark?:
    | ProgramBookmarkPartsFragment
    | CourseBookmarkPartsFragment
    | EventBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (
    bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment
  ) => void
  restoreBookmark?: (
    bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment
  ) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  // This is needed because some cards are rendered in a container that manages overflow (e.g., uk-slider) that
  // will clip the bookmark dropdown menu now that the button is at the bottom of the card.
  bookmarkDropdownPosition?: PopoverPosition
  customHoverMiniCard?: boolean
  className?: string
  // Used for impression tracking of cards. If not included no impression tracking occurs
  // If impression tracking is desired, but no extra properties are needed, send an empty object
  impressionTrackingProperties?: { [key: string]: any }
  showPremiumIcon?: Boolean
}

interface OrientedCardProps {
  id?: string
  header?: ReactNode
  title: ReactNode
  byline?: ReactNode
  body: ReactNode
  footer?: ReactNode
  thumbnail?: ReactNode
  horizontalThumbnail?: ReactNode
  verticalThumbnail?: ReactNode
  destination: string
  onClick: (e: MouseEvent | KeyboardEvent) => void
  bookmarkControl: ReactNode | null
  inNewTab?: boolean
  trackCardClick?: (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => void
  className?: string
  impressionRef: React.RefObject<HTMLDivElement>
  showPremiumIcon?: Boolean
}

interface MiniCardProps {
  header?: ReactNode
  title: ReactNode
  byline?: ReactNode
  footer?: ReactNode
  thumbnail?: ReactNode
  destination: string
  onClick: (e: MouseEvent | KeyboardEvent) => void
  inNewTab?: boolean
  trackCardClick?: (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => void
  customHoverMiniCard?: boolean
  className?: string
  largerVariant?: boolean
  impressionRef: React.RefObject<HTMLDivElement>
}

const HorizontalCard = ({
  id,
  title,
  header,
  byline,
  body,
  footer,
  thumbnail,
  destination,
  onClick,
  bookmarkControl,
  inNewTab,
  trackCardClick,
  impressionRef,
  showPremiumIcon
}: OrientedCardProps) => {
  return (
    <div
      ref={impressionRef}
      className="rf-rb-card-interactive flex h-56 gap-x-6 rounded-xl border p-4 w-full relative"
      onClick={onClick}
      onKeyUp={() => onEnterKeyPress(onClick)}
      role="link"
      tabIndex={0}
      id={id}
    >
      <div className="h-full w-60 flex-none chromatic-ignore ">{thumbnail}</div>
      <div className="flex grow flex-col gap-y-2 py-2 overflow-hidden">
        {header && <div className="text-sm text-rb-gray-400">{header}</div>}
        <div className="flex flex-col gap-y-2 pt-2">
          <h3
            className={twMerge(
              'mb-0 text-rb-gray-500 text-ellipsis line-clamp-2 text-[18px] font-medium leading-[24px]'
            )}
          >
            <Link
              to={destination}
              className="text-rb-gray-500 hover:text-rb-gray-500 hover:no-underline"
              onClick={onLinkClick(trackCardClick)}
              target={inNewTab ? '_blank' : undefined}
            >
              {title}
            </Link>
          </h3>
          {byline && <div className="text-sm text-rb-gray-400">{byline}</div>}
          <RfParagraphSmall
            color={ColorOptions.gray}
            className="text-ellipsis line-clamp-2"
          >
            {body}
          </RfParagraphSmall>
        </div>
        <div className="flex grow items-end">
          <div className="grow text-xs text-rb-gray-300">{footer}</div>
          {bookmarkControl}
        </div>
      </div>
      {showPremiumIcon && (
        <div className="hidden md:flex absolute top-[21px] right-[17px]">
          <PremiumPill />
        </div>
      )}
    </div>
  )
}

const VerticalCard = ({
  id,
  title,
  header,
  byline,
  footer,
  thumbnail,
  destination,
  onClick,
  bookmarkControl,
  inNewTab,
  trackCardClick,
  className,
  impressionRef,
  showPremiumIcon = false
}: OrientedCardProps) => {
  return (
    <div
      ref={impressionRef}
      className={cn(
        'rf-rb-card-interactive flex w-full min-w-[272px] max-w-[600px] flex-col justify-between rounded-xl border',
        className
      )}
      onClick={onClick}
      onKeyUp={() => onEnterKeyPress(onClick)}
      role="link"
      tabIndex={0}
      id={id}
    >
      <div className="relative w-full flex-none rounded-t-xl pb-[56%]">
        <div className="absolute h-full w-full chromatic-ignore">{thumbnail}</div>
        {showPremiumIcon && (
          <div className="absolute top-0 right-0 mt-3 mr-3">
            <PremiumPill />
          </div>
        )}
      </div>
      <div className="flex h-40 flex-col gap-2 px-4 py-6">
        {header && <div className="text-sm text-rb-gray-400">{header}</div>}
        <h3
          className={twMerge(
            'mb-0 text-rb-gray-500 text-ellipsis line-clamp-2 text-[18px] font-medium leading-[24px]'
          )}
        >
          <Link
            to={destination}
            className="text-rb-gray-500 hover:text-rb-gray-500 hover:no-underline"
            onClick={onLinkClick(trackCardClick)}
            target={inNewTab ? '_blank' : undefined}
          >
            {title}
          </Link>
        </h3>
        {byline && (
          <div className="text-ellipsis text-sm text-rb-gray-400 line-clamp-1">
            {byline}
          </div>
        )}
        <div className="flex grow items-end">
          <div className="grow text-xs text-rb-gray-300">{footer}</div>
          {bookmarkControl}
        </div>
      </div>
    </div>
  )
}

const MiniCard = ({
  title,
  footer,
  thumbnail,
  destination,
  onClick,
  inNewTab,
  customHoverMiniCard,
  trackCardClick,
  impressionRef,
  largerVariant = false
}: MiniCardProps) => {
  return (
    <div
      ref={impressionRef}
      className={`group flex cursor-pointer items-center gap-x-4 ${customHoverMiniCard ? 'rf-rb-card-interactive-mini' : ''}`}
      onClick={onClick}
      onKeyUp={() => onEnterKeyPress(onClick)}
      role="link"
      tabIndex={0}
    >
      <div
        className={`relative flex-none ${largerVariant ? 'h-[120px] w-[120px]' : 'h-20 w-20'}`}
      >
        {thumbnail && (
          <div className="absolute h-full w-full chromatic-ignore">{thumbnail}</div>
        )}
      </div>
      <div className="flex flex-col gap-y-2 max-w-[calc(100%-100px)]">
        <h4
          className={`mb-0 ${largerVariant ? 'text-lg font-medium' : 'text-base font-semibold'} leading-[1.6] ${customHoverMiniCard ? 'overflow-hidden text-ellipsis whitespace-nowrap' : 'line-clamp-2 group-hover:underline'}`}
        >
          <Link
            to={destination}
            className={`text-rb-gray-500 hover:text-rb-gray-500 ${customHoverMiniCard ? 'hover:no-underline' : ''}`}
            onClick={onLinkClick(trackCardClick)}
            target={inNewTab ? '_blank' : undefined}
          >
            {title}
          </Link>
        </h4>
        {footer && (
          <div className="text-xs leading-[1.5] text-rb-gray-300 line-clamp-1">
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}

const ListMiniCard = ({
  title,
  footer,
  header,
  byline,
  destination,
  onClick,
  inNewTab,
  trackCardClick,
  impressionRef
}: MiniCardProps) => {
  return (
    <div
      ref={impressionRef}
      className="group flex cursor-pointer flex-col gap-y-2"
      onClick={onClick}
      onKeyUp={() => onEnterKeyPress(onClick)}
      role="link"
      tabIndex={0}
    >
      {header && <div className="text-sm text-rb-gray-400">{header}</div>}
      <h4 className="mb-0 text-ellipsis text-base font-semibold leading-[1.6] line-clamp-2 group-hover:underline">
        <Link
          to={destination}
          className="text-rb-gray-500 hover:text-rb-gray-500"
          onClick={onLinkClick(trackCardClick)}
          target={inNewTab ? '_blank' : undefined}
        >
          {title}
        </Link>
      </h4>
      {byline && (
        <div className="text-ellipsis text-sm text-rb-gray-400 line-clamp-1">
          {byline}
        </div>
      )}
      {footer && (
        <div className="text-xs leading-[1.5] text-rb-gray-300 line-clamp-1">
          {footer}
        </div>
      )}
    </div>
  )
}

const onLinkClick =
  (
    trackCardClick?: (
      e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
    ) => void
  ) =>
  (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    trackCardClick?.(e)
  }

const BaseCard = (props: BaseCardProps) => {
  const {
    variant = CardVariants.Vertical,
    bookmark,
    currentFolder,
    bookmarkFolders,
    openAddToBookmarkFolderModal,
    restoreBookmark,
    handleRemoveFromFolder,
    cmsContentId,
    cmsModuleId,
    cmsProgramId,
    sanityId,
    eventId,
    courseSlug,
    contentType,
    onSaveClick,
    destination,
    inNewTab,
    historyState,
    onClick,
    trackCardClick,
    hideBookmarkButton = false,
    bookmarkDropdownPosition,
    customHoverMiniCard,
    impressionTrackingProperties,
    title
  } = props
  const history = useHistory()
  const initialBookmarkId = bookmark?.id || props.bookmarkId
  const [isInSavedItems, setIsInSavedItems] = useState(!!initialBookmarkId)
  const [bookmarkId, setBookmarkId] = useState(initialBookmarkId)
  const isSmall = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const setIsInSavedItemsWrapped = (isSaved: boolean) => {
    setIsInSavedItems(isSaved)
    onSaveClick?.(isSaved)
  }
  const [hasEnteredViewport, setHasEnteredViewport] = useState(false)
  const impressionRef = useRef<HTMLDivElement>(null)
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    if (!impressionTrackingProperties) return

    const impressionElement = impressionRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasEnteredViewport) {
          setHasEnteredViewport(true)
          trackServerEvent({
            variables: {
              input: {
                anonymousId: getAnonymousId(),
                event: 'impressionViewed',
                properties: {
                  content_type: contentType,
                  location: impressionTrackingProperties.location,
                  sanity_id: sanityId,
                  cms_lesson_id: cmsContentId,
                  section: impressionTrackingProperties.section,
                  section_index: impressionTrackingProperties.sectionIndex,
                  title: title,
                  section_impression_index:
                    impressionTrackingProperties.sectionImpressionIndex
                }
              }
            }
          })
        }
      },
      {
        threshold: 0.75 // trigger at 75% of card shown
      }
    )

    if (impressionElement) {
      observer.observe(impressionElement)
    }

    // Cleanup the observer on component unmount
    return () => {
      if (impressionElement) {
        observer.unobserve(impressionElement)
      }
    }
  }, [
    title,
    impressionTrackingProperties,
    hasEnteredViewport,
    cmsContentId,
    contentType,
    sanityId,
    trackServerEvent
  ])

  useEffect(() => {
    if (bookmark?.id && bookmark.id !== bookmarkId) {
      setIsInSavedItems(true)
      setBookmarkId(bookmark.id)
    }
  }, [bookmark, bookmarkId])

  const bookmarkControl =
    hideBookmarkButton ||
    [CardVariants.Mini, CardVariants.List].includes(variant) ? null : (
      <div className="flex-none">
        <ContentBookmark
          contentType={contentType}
          cmsContentId={cmsContentId}
          cmsModuleId={cmsModuleId}
          cmsProgramId={cmsProgramId}
          courseSlug={courseSlug}
          sanityId={sanityId}
          eventId={eventId as any}
          bookmarkId={bookmarkId}
          isInSavedItems={isInSavedItems}
          setIsInSavedItems={setIsInSavedItemsWrapped}
          bookmarkFolders={bookmarkFolders}
          bookmark={bookmark}
          restoreBookmark={restoreBookmark}
          currentFolder={currentFolder}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          handleRemoveFromFolder={handleRemoveFromFolder}
          dropdownPosition={bookmarkDropdownPosition}
        />
      </div>
    )

  const handleClick = (e: MouseEvent | KeyboardEvent) => {
    onClick?.(e)
    trackCardClick?.(e)

    if (destination === '') return

    if (e.metaKey || e.ctrlKey || inNewTab) {
      window.open(destination)
    } else {
      history.push(destination, historyState)
    }
  }

  if (variant === CardVariants.Mini) {
    return (
      <MiniCard
        {...props}
        customHoverMiniCard={customHoverMiniCard}
        onClick={handleClick}
        impressionRef={impressionRef}
      />
    )
  }

  if (variant === CardVariants.BiggerMini) {
    return (
      <MiniCard
        {...props}
        customHoverMiniCard={customHoverMiniCard}
        onClick={handleClick}
        largerVariant={true}
        impressionRef={impressionRef}
      />
    )
  }

  if (variant === CardVariants.List) {
    return <ListMiniCard {...props} onClick={handleClick} impressionRef={impressionRef} />
  }

  // On sm displays and below, we always use vertical cards
  if (isSmall || variant === CardVariants.Vertical) {
    const thumbnail = props.verticalThumbnail || props.thumbnail
    return (
      <VerticalCard
        {...props}
        onClick={handleClick}
        thumbnail={thumbnail}
        bookmarkControl={bookmarkControl}
        impressionRef={impressionRef}
      />
    )
  }

  const thumbnail = props.horizontalThumbnail || props.thumbnail
  return (
    <HorizontalCard
      {...props}
      onClick={handleClick}
      thumbnail={thumbnail}
      bookmarkControl={bookmarkControl}
      impressionRef={impressionRef}
    />
  )
}

export default BaseCard
