import ArtifactCard from 'components/cards/Content/ArtifactCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
} from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'

import {
  ProgramBookmarkPartsFragment,
  RecommendedItem,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

interface FeedItemProps {
  index: number
  savedData?: SavedArtifactsAndGuidesForUserQuery
  feedItem: RecommendedItem
  sectionIndex: number
  section: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  pageLocation: string
  userIsPremember?: Boolean
}

const FeedItem = ({
  feedItem,
  index,
  savedData,
  sectionIndex,
  section,
  openAddToBookmarkFolderModal,
  pageLocation
}: FeedItemProps) => {
  const impressionTrackingProperties = {
    location: pageLocation,
    sectionImpressionIndex: index,
    sectionIndex: sectionIndex,
    section: section
  }
  return (
    <>
      {(feedItem.kind === 'artifact' || feedItem.kind === 'draft') && (
        <ArtifactCard
          key={feedItem.localId}
          artifact={feedItem}
          viewCount={feedItem.viewerCount || 0}
          commentCount={feedItem.commentCount || 0}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          pageLocation={pageLocation}
          additionalRelatedIdentifiers={{
            section: 'home_feed_recommendation',
            result_index: index
          }}
          inNewTab
          impressionTrackingProperties={impressionTrackingProperties}
          bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
            (bookmark) => bookmark.sanityId === feedItem.sanityId
          )}
          bookmarkDropdownPosition="left"
        />
      )}
      {feedItem.kind === 'course' && (
        <CourseCard
          key={feedItem.localId}
          course={feedItem}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          pageLocation={pageLocation}
          additionalRelatedIdentifiers={{
            section: 'home_feed_recommendation',
            result_index: index
          }}
          inNewTab
          destinationType={COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD}
          impressionTrackingProperties={impressionTrackingProperties}
          bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
            (bookmark) => bookmark.sanityId === feedItem.sanityId
          )}
        />
      )}
      {feedItem.kind === 'guide' && (
        <GuideCard
          key={feedItem.localId}
          guide={feedItem}
          overrideImage={feedItem.thumbnailUrl}
          inNewTab
          pageLocation={pageLocation}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
            (bookmark) => bookmark.sanityId === feedItem.sanityId
          )}
          bookmarkDropdownPosition="left"
          impressionTrackingProperties={impressionTrackingProperties}
          additionalRelatedIdentifiers={{
            section: 'home_feed_recommendation',
            result_index: index
          }}
        />
      )}
    </>
  )
}
export default FeedItem
