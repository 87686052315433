import { useHistory } from 'react-router-dom'

import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { CourseDetailPartsFragment, RelatedCoursePartsFragment } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackNavigationClicked } from 'utils/tracking/generated/events'

interface CourseDetailRelatedCourseProps {
  relatedCourse: RelatedCoursePartsFragment
  courseDetails: CourseDetailPartsFragment
  loggedOut?: boolean
}

const CourseDetailRelatedCourse = ({
  relatedCourse,
  courseDetails,
  loggedOut
}: CourseDetailRelatedCourseProps) => {
  const history = useHistory()

  const handleOnClick = () => {
    let destinationUrl = `/courses/${relatedCourse?.slug}`
    if (!loggedOut) {
      destinationUrl = `/courses/${relatedCourse?.slug}/details`
    }

    trackNavigationClicked({
      destination: destinationUrl,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: relatedCourse?.id,
      text: relatedCourse?.title,
      type: 'horizontal_card',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_title: courseDetails?.title,
        course_sanity_id: courseDetails?.sourceId
      }
    })

    history.push(destinationUrl)
  }

  // join creator names with comma and the last one with '&'
  const creatorNames = relatedCourse?.creators
    ?.map((creator) => creator?.name)
    .join(', ')
    .replace(/, ([^,]*)$/, ' & $1')

  return (
    <div
      role="link"
      tabIndex={0}
      onClick={handleOnClick}
      onKeyUp={() => onEnterKeyPress(handleOnClick)}
      className="mr-6 flex h-[389px] w-full max-w-[350px] cursor-pointer flex-col gap-2.5 rounded-xl border border-rb-gray-100"
    >
      <div className="flex items-center gap-2 p-4 pb-0">
        {relatedCourse?.creators?.map((creator, index) => {
          if (!creator || !creator?.avatarPhoto?.imageUrl) return null
          let imageWidth = 155
          if (relatedCourse?.creators?.length && relatedCourse?.creators?.length > 2) {
            imageWidth = 95
          }

          return (
            <div className={`h-[180px] w-[${imageWidth}px]`} key={index}>
              <img
                src={`${creator?.avatarPhoto?.imageUrl}?auto=format&w=200`}
                alt={creator?.name || ''}
                className="h-full rounded-xl object-cover"
              />
            </div>
          )
        })}
      </div>
      <div className="p-4">
        <RfHeader3SemiBold>{relatedCourse?.title}</RfHeader3SemiBold>

        {creatorNames && <RfParagraphSmall>created by {creatorNames}</RfParagraphSmall>}
      </div>
    </div>
  )
}

export default CourseDetailRelatedCourse
