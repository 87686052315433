import Tooltip from 'components/Tooltip/Tooltip'

import { ReactComponent as DiamondIcon } from 'images/icon--diamond.svg'

const PremiumPill = () => {
  return (
    <div className="flex h-6 w-12 items-center justify-center rounded-full bg-premium-gradient shrink-0">
      <Tooltip tooltipBody="Included with membership">
        <DiamondIcon fill="white" className="h-4" />
      </Tooltip>
    </div>
  )
}

export default PremiumPill
