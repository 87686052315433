import { useHistory } from 'react-router-dom'

import { buildSrc } from 'domains/Sanity/lib/helpers'

import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { onEnterKeyPress } from 'utils/keyboard'
import { stripMarkdown } from 'utils/stripMarkdown'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const GuideItem = ({
  searchDocument,
  searchQuery,
  userCan,
  onResultClick
}: ContentResultItemProps) => {
  const history = useHistory()
  const { title, highlight, thumbnail, tags, url } = searchDocument

  const thumbnailSrc = thumbnail && buildSrc(thumbnail, { width: 176 })

  const handleClick = () => {
    if (userCan.linkToSearchResults) {
      onResultClick()
      history.push(url)
    }
  }

  const buttonProps = userCan.linkToSearchResults
    ? {}
    : { 'uk-toggle': 'target: #guest-modal-search' }

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      onClick={handleClick}
      onKeyUp={onEnterKeyPress(handleClick)}
      {...buttonProps}
    >
      <div className="relative flex-1">
        <RfParagraphLarge className="mb-0 font-medium">
          <span dangerouslySetInnerHTML={{ __html: formatTitle(searchQuery, title) }} />
        </RfParagraphLarge>
        <div className="mt-1">
          <span
            dangerouslySetInnerHTML={{
              __html: formatTitle(searchQuery, stripMarkdown(highlight), 190)
            }}
            className="overflow-wrap break-words line-clamp-4"
          />
        </div>
        <div className="mt-4 flex items-center justify-center space-x-2 sm:items-start sm:justify-start">
          {tags.map((tag, idx) => {
            return (
              <div
                key={`tag-${idx}`}
                className="text-ellipsis rounded-full bg-rb-gray-100 py-1 px-3 text-sm line-clamp-1 sm:text-xs"
              >
                {tag}
              </div>
            )
          })}
        </div>
      </div>
      <div className="relative hidden pl-6 tl:block lgr:hidden xl:block">
        {thumbnailSrc && (
          <img src={thumbnailSrc} className="h-fit max-h-28 object-right" alt="" />
        )}
      </div>
    </div>
  )
}

export default GuideItem
