import { ReactComponent as BellIcon } from 'icon--bell.svg'
import { Link } from 'react-router-dom'

import AccountLayout from 'domains/Account/AccountLayout'
import StripeBillingPortalButton from 'domains/Subscription/StripeBillingPortalButton'
import PlanManagementSubscriptionCard from 'domains/Subscription/SubscriptionCard/PlanManagementSubscriptionCard'
import BillingHistory from 'domains/User/BillingHistory'
import PaymentMethods from 'domains/User/PaymentMethods'

import { Loading } from 'components'

import { useUserSubscriptionWithTeamQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { track, trackCtaClicked } from 'utils/tracking/analytics'

const AccountBillingPage = () => {
  const { id } = useAssertCurrentUser()

  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData
  } = useUserSubscriptionWithTeamQuery({ variables: { id } })

  const isOnLegacyPlan =
    subscriptionData?.user?.subscriptions?.active?.planName === 'unlimited'
  const upcomingPlanName =
    subscriptionData?.user?.subscriptions?.active?.stripeUpcomingInvoice?.planName
  const hasSelectedNewPlan = upcomingPlanName && upcomingPlanName !== 'unlimited'
  const isSubscriptionOwner = subscriptionData?.user?.subscriptions?.active?.userIsOwner

  const handleLinkClick = () => {
    trackCtaClicked({
      cta_location: 'billing_page',
      cta_type: 'hyperlink text',
      text: 'Explore the new plans'
    })
  }

  if (subscriptionLoading) return <Loading />

  return (
    <>
      <AccountLayout>
        {isOnLegacyPlan && !hasSelectedNewPlan && (
          <>
            {track('Banner Displayed', {
              banner_name: 'billing page new plan',
              category: 'app'
            })}
            <div className="mt-4 mb-7 flex bg-rb-blue-50 py-6 px-11 text-sm">
              <BellIcon width={50} className="mr-8 fill-rb-black" />
              <span>
                New plans and pricing are here! We&apos;re excited to roll out more
                flexible offerings to support you and your development.{' '}
                <Link to="/manage-plan" onClick={handleLinkClick}>
                  Explore the new plans
                </Link>{' '}
                and find the one that&apos;s right for you.
              </span>
            </div>
          </>
        )}
        <PlanManagementSubscriptionCard
          subscriptionData={subscriptionData}
          subscriptionLoading={subscriptionLoading}
          subscriptionError={subscriptionError}
        />
        <PaymentMethods />
        {isSubscriptionOwner && (
          <div data-test="billing-information" className="rf-rb-card mb-5 bg-white p-10">
            <div className="mb-4 text-xl font-medium">Billing Information</div>
            <StripeBillingPortalButton />
          </div>
        )}
        <BillingHistory />
      </AccountLayout>
    </>
  )
}

export default AccountBillingPage
