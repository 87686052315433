import Button from 'components/Button'
import { Modal, ModalContent } from 'components/Modal'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

export interface AssignSeatModalProps {
  isOpen: boolean
  handleClose: () => void
  onAssignSeat: () => void
  joiningUserName?: string | null | undefined
}

export const AssignSeatModal = ({
  isOpen,
  handleClose,
  onAssignSeat,
  joiningUserName
}: AssignSeatModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-4xl"
      scrollContent={false}
    >
      <ModalContent className="text-center" scrollContent={false}>
        <RfParagraphMedium>
          You have an empty seat in your plan. Do you want to assign this <br />
          seat to {joiningUserName || 'the member'}?
        </RfParagraphMedium>
      </ModalContent>
      <section className="flex justify-center px-8 py-3 md:py-8 md:px-11">
        <Button variant="text-only" onClick={handleClose} size="medium">
          CANCEL
        </Button>

        <Button onClick={onAssignSeat} dataTest="confirm-seat-assignment" size="medium">
          YES, ASSIGN THE SEAT
        </Button>
      </section>
    </Modal>
  )
}
