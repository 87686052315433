import { useBlock } from '@dopt/react'
import { useCallback } from 'react'

import Button from 'components/Button'
import DoptCallout from 'components/dopt/DoptCallout'

import {
  ProductTourKey,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery
} from 'gql'

import { cn } from 'utils/tailwind'

import { ReactComponent as RewardIcon } from 'images/icon--reward-stars.svg'

import { useGlobalChat } from '../GlobalChatProvider'

export const ReforgeAiButton = () => {
  const blockArray = useBlock<['complete']>('ai-chat-product-tour.callout.top-ai-button')
  const { toggle, isLoading, activeSession } = useGlobalChat()
  const { highlightGlobalAi, registerUseGlobalAi } = useHighlightGlobalAi()

  const ctaText = 'Reforge AI'

  const toggleChat = useCallback(() => {
    if (blockArray && blockArray[0]?.state.active) {
      blockArray[1]('complete') // transition to complete
    }
    if (highlightGlobalAi) {
      registerUseGlobalAi()
    }

    toggle({ ctaText })
  }, [toggle, highlightGlobalAi, registerUseGlobalAi, blockArray])

  return (
    <div className="relative">
      <div className="relative">
        <Button
          size="medium"
          dataTest="reforge-ai-button"
          className="text-md flex h-10 w-10 cursor-pointer items-center gap-x-2 border-0 bg-rb-teal-50 p-0 text-rb-black transition duration-200 ease-in-out hover:bg-rb-teal-60 hover:text-rb-black active:bg-rb-teal-60"
          onClick={toggleChat}
        >
          <RewardIcon
            className={cn('h-4 w-4', {
              'animate-pulse': isLoading
            })}
          />
        </Button>
        <DoptCallout
          position="bottomLeft"
          blockArray={blockArray}
          onComplete={() => toggle({ ctaText })}
        />
      </div>
      {(activeSession || highlightGlobalAi) && (
        <NotificationBadge animate={highlightGlobalAi} />
      )}
    </div>
  )
}

const NotificationBadge = ({ animate }: { animate: boolean }) => {
  return (
    <div
      className={cn(
        'absolute -right-[6px] -top-[6px] box-content h-3 w-3 rounded-full border-[2px] border-white bg-rb-green-100',
        {
          'animate-pulse': animate
        }
      )}
    ></div>
  )
}

function useHighlightGlobalAi() {
  const { data, loading } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()

  const productTour = data?.currentUser?.completedProductTours
  const highlightGlobalAi =
    loading || !data ? false : !productTour?.usedReforgeAiGlobalEntryPoint

  const registerUseGlobalAi = useCallback(() => {
    if (!highlightGlobalAi) return

    completeProductTour({
      variables: {
        input: {
          productTourKey: ProductTourKey.USED_REFORGE_AI_GLOBAL_ENTRY_POINT
        }
      }
    })
  }, [highlightGlobalAi, completeProductTour])

  return { highlightGlobalAi, registerUseGlobalAi }
}
