import { useLocation } from 'react-router-dom'

import MultiselectDropdown, {
  MultiSelectDropdownProps
} from './MultiselectDropDown/MultiselectDropdown'

export default function FilterMenuContainer({ data }: any) {
  const {
    topicList,
    functionList,
    artifactsQueryMeta,
    filteredArtifactsLoading,
    handleFilterSelection,
    showMobileTopicFilterMenu,
    filterCount,
    setNewFilterLoading,
    setShowMobileTopicFilterMenu
  } = data

  const location = useLocation() // This hook gives you access to the location object
  const searchParams = new URLSearchParams(location.search)
  function getInitialVals(val: string) {
    return searchParams.get(val)?.split(',') || []
  }

  if (!topicList?.length || !functionList?.length) {
    return null
  }

  const sharedClasses = {
    className: 'h-[40px] min-w-[220px] w-full',
    dropdownClassName: 'min-w-[220px] w-full',
    containerClassName: 'w-full'
  }

  const props: MultiSelectDropdownProps = {
    clearAll: () => {
      setNewFilterLoading(true)
      handleFilterSelection('reset')
    },
    handleFilterSelection,
    showMobileTopicFilterMenu,
    setShowMobileTopicFilterMenu,
    filterCount,
    artifactsQueryMeta,
    filteredArtifactsLoading,
    data: [
      {
        ...sharedClasses,
        displayText: 'Topics',
        selectedItems: getInitialVals('topics'),
        onSelection: (selection: string[]) => handleFilterSelection('topics', selection),
        data: topicList?.map((topic: { title: string; slug: string; id: string }) => {
          return {
            label: topic.title,
            value: topic.slug
          }
        })
      },
      {
        ...sharedClasses,
        displayText: 'Functions',
        selectedItems: getInitialVals('functions'),
        onSelection: (selection: string[]) =>
          handleFilterSelection('functions', selection),
        data: functionList?.map((topic: { title: string; slug: string }) => {
          return {
            label: topic.title,
            value: topic.slug
          }
        })
      },
      {
        ...sharedClasses,
        displayText: 'Company size',
        selectedItems: getInitialVals('company-size'),
        onSelection: (selection: string[]) =>
          handleFilterSelection('company-size', selection),
        data: [
          { label: '1-10', value: '1-10' },
          { label: '11-50', value: '11-50' },
          { label: '51-100', value: '51-100' },
          { label: '101-250', value: '101-250' },
          { label: '251-500', value: '251-500' },
          { label: '501-1000', value: '501-1000' },
          { label: '1001-5000', value: '1001-5000' },
          { label: '5001-10000', value: '5001-10000' },
          { label: '10001+', value: '10001+' }
        ]
      },
      {
        ...sharedClasses,
        displayText: 'Company stage',
        selectedItems: getInitialVals('company-stage'),
        onSelection: (selection: string[]) =>
          handleFilterSelection('company-stage', selection),
        data: [
          { label: 'Pre-seed', value: 'Pre-seed' },
          { label: 'Seed', value: 'Seed' },
          { label: 'Series A', value: 'Series A' },
          { label: 'Series B', value: 'Series B' },
          { label: 'Series C', value: 'Series C' },
          { label: 'Series D', value: 'Series D' },
          { label: 'Series E', value: 'Series E' },
          { label: 'Series F', value: 'Series F' },
          { label: 'Series G+', value: 'Series G+' },
          { label: 'Public', value: 'Public' }
        ]
      },
      {
        ...sharedClasses,
        displayText: 'Business model',
        selectedItems: getInitialVals('business-model'),
        onSelection: (selection: string[]) =>
          handleFilterSelection('business-model', selection),
        data: [
          { label: 'B2B', value: 'b2b' },
          { label: 'B2C', value: 'b2c' },
          { label: 'B2B2C', value: 'b2b2c' },
          { label: 'Marketplace', value: 'marketplace' },
          { label: 'Platform', value: 'platform' }
        ]
      }
    ]
  }

  return <MultiselectDropdown {...props} />
}
