import { ApolloError } from '@apollo/client'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { EventRecordingCard } from 'pages/EventRecordings/components/EventRecordingCard'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import DashboardBookmark from 'domains/Bookmarks/Bookmark'
import {
  LOCATION_GLOBAL_SAVED_ITEMS,
  getContentInformationFromBookmark
} from 'domains/Bookmarks/utils'
import CollectionLinkCard from 'domains/Collections/CollectionLinkCard'
import CollectionPresentationItem from 'domains/Collections/CollectionPresentationItem'
import CollectionTextBlockInsert from 'domains/Collections/CollectionTextBlockInsert'
import CollectionTextBlockSection from 'domains/Collections/CollectionTextBlockSection'
import NoneElement from 'domains/Collections/NoneElement'
import SortableCollectionItem from 'domains/Collections/SortableCollectionItem'
import useHandleRemoveFromFolder from 'domains/Collections/hooks/useHandleRemoveFromFolder'

import { ErrorMessage } from 'components'
import Loading from 'components/Loading'
import Paginator from 'components/Paginator'
import { useProductTour } from 'components/ProductTour'
import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'
import LegacyCourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE
} from 'components/cards/Content/LegacyCourseCard'
import LegacyProgramCard from 'components/cards/Content/LegacyProgramCard'

import {
  BookmarkFolderPartsFragment,
  BookmarkType,
  CourseBookmarkPartsFragment,
  CreateBookmarkInput,
  ProductTourKey,
  ProgramBookmarkPartsFragment,
  Unit,
  useCreateBookmarkMutation,
  useReorderFiledBookmarkMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import notifyError from 'utils/errorNotifier'
import { trackCollectionAction, trackReorderObject } from 'utils/tracking/analytics'
import { trackSavedItemClicked } from 'utils/tracking/generated/events/savedItemClicked'

import useOpenAddToBookmarkFolderModal from './hooks/useOpenAddToBookmarkFolderModal'

const BOOKMARKS_PER_PAGE = 30

interface CollectionsListProps {
  bookmarks?: ProgramBookmarkPartsFragment[]
  bookmarksCount: number
  loading: boolean
  error?: ApolloError
  filters?: any // fix this
  openCreateBookmarkFolderModal?: (
    bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment | null
  ) => void
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  reforgeCollection?: boolean
  handlePagination: (page: number) => void
  currentPage: number
}

const CollectionsList = ({
  bookmarks,
  bookmarksCount,
  loading,
  error,
  filters,
  openCreateBookmarkFolderModal,
  currentFolder,
  bookmarkFolders,
  reforgeCollection = false,
  handlePagination,
  currentPage
}: CollectionsListProps) => {
  const currentUser = useAssertCurrentUser()
  const [tourDisabled, setTourDisabled] = useState(true)
  const [activeBookmark, setActiveBookmark] =
    useState<ProgramBookmarkPartsFragment | null>(null)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const { pathname } = useLocation()

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal?.(currentBookmarkForDropdown)
  }

  const totalPages = Math.ceil(bookmarksCount / BOOKMARKS_PER_PAGE)

  const { RemoveBookmarkFromFolder } = useHandleRemoveFromFolder({})
  const handleRemoveFromFolder = async (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    const { errors, data } = await RemoveBookmarkFromFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          bookmarkFolderId: bookmarkFolder.id
        }
      }
    })

    if (errors) {
      notifyError(errors)
      return null
    } else {
      return data?.deleteFiledBookmark?.filedBookmark?.bookmarkFolderId
    }
  }

  const [reorderFiledBookmark] = useReorderFiledBookmarkMutation()

  const [createBookmark] = useCreateBookmarkMutation()

  const restoreBookmark = async (bookmark: ProgramBookmarkPartsFragment) => {
    const {
      basedOn,
      cmsProgram,
      cmsModule,
      cmsSection,
      noteBody,
      seconds,
      referenceImageUrl,
      type,
      title,
      externalUrl
    } = bookmark
    const bookmarkInput: CreateBookmarkInput = {
      basedOn: basedOn,
      cmsProgramId: cmsProgram?.id,
      cmsModuleId: cmsModule?.id,
      cmsSectionId: cmsSection?.id,
      noteBody: noteBody,
      seconds: seconds,
      referenceImageUrl: referenceImageUrl,
      type: type,
      title: title,
      externalUrl: externalUrl
    }

    try {
      await createBookmark({
        variables: {
          input: bookmarkInput
        }
      })
    } catch (error: unknown) {
      notifyError(error)
    }
  }

  const indexOfLastBookmark = currentPage * BOOKMARKS_PER_PAGE
  const indexOfFirstBookmark = indexOfLastBookmark - BOOKMARKS_PER_PAGE
  const [currentBookmarks, setCurrentBookmarks] = useState<
    ProgramBookmarkPartsFragment[]
  >([])
  useEffect(() => {
    if (bookmarks && bookmarks.length > 0) {
      setCurrentBookmarks(
        bookmarks.slice(indexOfFirstBookmark, indexOfLastBookmark).filter(Boolean)
      )
      setTourDisabled(false)
    } else {
      setTourDisabled(true)
    }
  }, [bookmarks, indexOfFirstBookmark, indexOfLastBookmark])

  const canShareCollection =
    currentFolder && currentFolder.user.id === currentUser?.id && currentUser?.hasTeam
  const { portal, completeTour } = useProductTour({
    // we are looking for the first content card
    productTourKey: ProductTourKey.COLLECTION_VIEW_CONTENT,
    nodeRefOrSelector: '[id^="ContentCard_"]',
    disabled: tourDisabled,
    title: '📖  Dive into the content',
    description:
      'Click into this resource to start learning key frameworks you can apply to your work.',
    wait: 2500,
    // If the current user cannot share this collection, we want to show this
    // tour even if the "Share a Collection" tour has not been completed.
    checkKeyOnly: !canShareCollection
  })

  if ((!bookmarks || bookmarks.length === 0) && loading) {
    return <Loading />
  } else if (error) {
    return <ErrorMessage error={error} />
  } else if (!bookmarks || bookmarks.length === 0) {
    return <NoneElement />
  }

  const allowCustomization = !!currentFolder && !reforgeCollection

  const handleBookmarkClickTracking = (
    bookmark: ProgramBookmarkPartsFragment,
    component?: string
  ) => {
    trackSavedItemClicked({
      location: LOCATION_GLOBAL_SAVED_ITEMS,
      path: pathname,
      cms_program_name: bookmark?.cmsProgram?.name,
      cms_program_id: bookmark?.cmsProgram?.id,
      cms_module_name: bookmark.cmsModule?.name,
      cms_module_id: bookmark.cmsModule?.id,
      cms_section_name: bookmark.cmsSection?.name,
      cms_section_id: bookmark.cmsSection?.id,
      component,
      program_filter: filters?.program,
      module_filter: filters?.module,
      type_filter: filters?.type,
      sort_by: currentPage.toString(),
      bookmark_type: bookmark.type,
      collection_id: currentFolder?.id,
      collection_name: currentFolder?.name,
      is_reforge_collection: currentFolder?.reforgeCollection,
      is_shared_collection: currentFolder?.sharedFolders[0]?.status === 'shared'
    })
  }

  const shouldDisplayWideContentCard = (bookmark: ProgramBookmarkPartsFragment) => {
    if (
      bookmark.type === BookmarkType.CONCEPTBOOKMARK ||
      bookmark.type === BookmarkType.PROJECTBOOKMARK ||
      bookmark.type === BookmarkType.LESSONBOOKMARK ||
      bookmark.type === BookmarkType.SECTIONBOOKMARK ||
      bookmark.type === BookmarkType.UNITBOOKMARK ||
      bookmark.type === BookmarkType.RESOURCEBOOKMARK
    ) {
      return true
    }
    return false
  }

  const handleDragStart = (event: DragStartEvent) => {
    const bookmark = currentBookmarks.find((b) => b.id === event.active.id)
    if (!bookmark) return

    setActiveBookmark(bookmark)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    if (!currentFolder) return

    const { active, over } = event
    const reorderedBookmark = activeBookmark as ProgramBookmarkPartsFragment
    setActiveBookmark(null)
    if (active.id !== over?.id) {
      const oldIndex = currentBookmarks.findIndex((bookmark) => bookmark.id === active.id)
      const newIndex = currentBookmarks.findIndex((bookmark) => bookmark.id === over?.id)
      const newPosition = (currentPage - 1) * BOOKMARKS_PER_PAGE + newIndex

      setCurrentBookmarks((currentBookmarks) => {
        return arrayMove(currentBookmarks, oldIndex, newIndex)
      })
      handleTrackReorder(reorderedBookmark, oldIndex, newIndex)
      reorderFiledBookmark({
        variables: {
          input: {
            bookmarkId: active.id as string,
            folderId: currentFolder.id,
            position: newPosition
          }
        }
      })
    }
  }

  const handleTrackReorder = (
    bookmark: ProgramBookmarkPartsFragment,
    startPosition: number,
    endPosition: number
  ) => {
    trackReorderObject({
      reorder_location: 'collections',
      path: pathname,
      object_type: bookmark.type,
      reorder_position: endPosition,
      start_position: startPosition,
      related_indentifiers: {
        bookmark_id: bookmark.id,
        content_name: getContentInformationFromBookmark(bookmark)?.name,
        content_id: getContentInformationFromBookmark(bookmark)?.id,
        collection_id: currentFolder?.id
      }
    })
  }

  const handleTextBlockTracking = (
    action: 'add_text_to_collection' | 'edit_text_block'
  ) => {
    trackCollectionAction({
      action: action,
      collection_id: currentFolder?.id,
      collection_name: currentFolder?.name,
      is_reforge_collection:
        currentFolder?.reforgeCollection || !!currentFolder?.forkedFromId,
      is_shared_collection: currentFolder?.sharedFolders[0]?.status === 'shared',
      location: window.location.pathname
    })
  }

  const additionalRelatedIdentifiers = {
    collection_id: currentFolder?.id,
    collection_name: currentFolder?.name
  }

  const isPaidMember =
    currentUser && (currentUser.is.paidMember || currentUser.is.planManager)

  return (
    <div data-test="bookmarks">
      {portal}
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!currentUser?.hasSeenCollectionsPrompt}
      />
      <div className={`flex list-none flex-col gap-y-${currentFolder ? '2' : '5'}`}>
        {allowCustomization && (
          <CollectionTextBlockInsert
            position={(currentPage - 1) * BOOKMARKS_PER_PAGE}
            currentFolder={currentFolder}
          />
        )}
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={currentBookmarks.map((b) => b.id)}
            strategy={verticalListSortingStrategy}
          >
            {currentBookmarks.map(
              (bookmark: ProgramBookmarkPartsFragment, index: number) => (
                <Fragment key={`${index}_${bookmark.id}`}>
                  <SortableCollectionItem
                    id={bookmark.id}
                    isSortable={allowCustomization}
                  >
                    {bookmark.content && shouldDisplayWideContentCard(bookmark) && (
                      <LegacyContentCard
                        variant={CardVariants.Horizontal}
                        content={bookmark.content}
                        inNewTab
                        bookmarkFolders={bookmarkFolders}
                        restoreBookmark={restoreBookmark}
                        currentFolder={currentFolder}
                        handleRemoveFromFolder={handleRemoveFromFolder}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        bookmark={bookmark}
                        onClick={() => {
                          completeTour()
                        }}
                      />
                    )}
                    {bookmark.type !== BookmarkType.PROGRAMBOOKMARK &&
                      bookmark.type !== BookmarkType.TEXTBLOCKBOOKMARK &&
                      bookmark.type !== BookmarkType.EXTERNALURLBOOKMARK &&
                      bookmark.type !== BookmarkType.ARTIFACTBOOKMARK &&
                      bookmark.type !== BookmarkType.GUIDEBOOKMARK &&
                      bookmark.type !== BookmarkType.COURSEBOOKMARK &&
                      bookmark.type !== BookmarkType.EVENTBOOKMARK &&
                      !(bookmark.content && shouldDisplayWideContentCard(bookmark)) && (
                        <DashboardBookmark
                          onMouseDown={handleBookmarkClickTracking}
                          bookmark={bookmark}
                          inNewTab
                          onEdit={() => {}}
                          restoreBookmark={restoreBookmark}
                          showProgramInBreadcrumb={!false} // update this
                          bookmarkFolders={bookmarkFolders}
                          currentFolder={currentFolder}
                          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                          handleRemoveFromFolder={handleRemoveFromFolder}
                          reforgeCollection={reforgeCollection}
                          hideUpdatedAgo={
                            reforgeCollection || Boolean(currentFolder?.forkedFromId)
                          }
                        />
                      )}
                    {bookmark.type === BookmarkType.PROGRAMBOOKMARK &&
                      !(bookmark.content && shouldDisplayWideContentCard(bookmark)) &&
                      bookmark.cmsProgram && (
                        <LegacyProgramCard
                          program={bookmark.cmsProgram}
                          variant={CardVariants.Horizontal}
                          currentUser={currentUser}
                          inNewTab
                          bookmark={bookmark}
                          restoreBookmark={restoreBookmark}
                          bookmarkFolders={bookmarkFolders}
                          currentFolder={currentFolder}
                          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                          handleRemoveFromFolder={handleRemoveFromFolder}
                        />
                      )}
                    {bookmark.type === BookmarkType.ARTIFACTBOOKMARK && (
                      <ArtifactCard
                        artifact={bookmark.artifact}
                        variant={CardVariants.Horizontal}
                        pageLocation="saved"
                        additionalRelatedIdentifiers={additionalRelatedIdentifiers}
                        bookmark={bookmark}
                        restoreBookmark={restoreBookmark}
                        bookmarkFolders={bookmarkFolders}
                        currentFolder={currentFolder}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        handleRemoveFromFolder={handleRemoveFromFolder}
                        inNewTab
                      />
                    )}
                    {bookmark.type === BookmarkType.EVENTBOOKMARK && bookmark.event && (
                      <EventRecordingCard
                        event={bookmark.event}
                        key={`event-${bookmark.event.id}`}
                        variant={CardVariants.Horizontal}
                        imgVariants={'teal'}
                        pageLocation="saved"
                        bookmark={bookmark}
                        restoreBookmark={restoreBookmark}
                        bookmarkFolders={bookmarkFolders}
                        currentFolder={currentFolder}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        handleRemoveFromFolder={handleRemoveFromFolder}
                        inNewTab
                      />
                    )}
                    {bookmark.type === BookmarkType.COURSEBOOKMARK &&
                      (bookmark.cclCourse ? (
                        <CourseCard
                          variant={CardVariants.Horizontal}
                          additionalRelatedIdentifiers={additionalRelatedIdentifiers}
                          pageLocation={`saved (${isPaidMember ? '/saved' : 'free'})`}
                          course={bookmark.cclCourse}
                          key={bookmark.id}
                          destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS}
                          bookmark={bookmark}
                          restoreBookmark={restoreBookmark}
                          bookmarkFolders={bookmarkFolders}
                          currentFolder={currentFolder}
                          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                          handleRemoveFromFolder={handleRemoveFromFolder}
                          inNewTab
                        />
                      ) : (
                        bookmark.course && (
                          <LegacyCourseCard
                            variant={CardVariants.Horizontal}
                            pageLocation={`saved (${isPaidMember ? '/saved' : 'free'})`}
                            additionalRelatedIdentifiers={additionalRelatedIdentifiers}
                            cardType="on-demand"
                            course={bookmark.course}
                            key={bookmark.id}
                            user={currentUser}
                            showStartDate={false}
                            destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE}
                            bookmark={bookmark}
                            restoreBookmark={restoreBookmark}
                            bookmarkFolders={bookmarkFolders}
                            currentFolder={currentFolder}
                            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                            handleRemoveFromFolder={handleRemoveFromFolder}
                            inNewTab
                          />
                        )
                      ))}
                    {bookmark.type === BookmarkType.GUIDEBOOKMARK && !!bookmark.guide && (
                      <GuideCard
                        guide={bookmark.guide as Unit}
                        variant={CardVariants.Horizontal}
                        pageLocation="saved"
                        additionalRelatedIdentifiers={additionalRelatedIdentifiers}
                        bookmarkFolders={bookmarkFolders}
                        restoreBookmark={restoreBookmark}
                        currentFolder={currentFolder}
                        handleRemoveFromFolder={handleRemoveFromFolder}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        bookmark={bookmark}
                        inNewTab
                      />
                    )}
                    {allowCustomization &&
                      bookmark.type === BookmarkType.TEXTBLOCKBOOKMARK && (
                        <CollectionTextBlockSection
                          bookmark={bookmark}
                          handleTextBlockTracking={() =>
                            handleTextBlockTracking('edit_text_block')
                          }
                        />
                      )}
                    {allowCustomization &&
                      bookmark.type === BookmarkType.EXTERNALURLBOOKMARK && (
                        <CollectionLinkCard
                          bookmark={bookmark}
                          currentFolder={currentFolder}
                          restoreBookmark={restoreBookmark}
                        />
                      )}
                  </SortableCollectionItem>
                  {allowCustomization && (
                    <CollectionTextBlockInsert
                      position={(currentPage - 1) * BOOKMARKS_PER_PAGE + index + 1}
                      currentFolder={currentFolder}
                      handleTextBlockTracking={() =>
                        handleTextBlockTracking('add_text_to_collection')
                      }
                    />
                  )}
                </Fragment>
              )
            )}
          </SortableContext>
          <DragOverlay>
            {activeBookmark ? (
              <CollectionPresentationItem bookmark={activeBookmark} />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
      {totalPages > 1 && (
        <div className="uk-text-center mb-12">
          <Paginator
            meta={{
              currentPage,
              totalPages
            }}
            handlePagination={handlePagination}
          />
        </div>
      )}
    </div>
  )
}

export default CollectionsList
