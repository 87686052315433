import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ArtifactFilters } from 'gql'

import { useQueryParams } from 'hooks/useQueryParams'

export function useTrackChangeAndReact<T>(
  valueToTrack: T,
  callback: (prevVal: T, currVal: T) => void
) {
  const prevValueToTrack = useRef(valueToTrack)
  useEffect(() => {
    if (prevValueToTrack.current !== valueToTrack) {
      callback(prevValueToTrack.current, valueToTrack)
    }
    prevValueToTrack.current = valueToTrack
  }, [valueToTrack, callback])
}

export function useArtifactIndexFilter() {
  const history = useHistory()

  const { queryParams } = useQueryParams()
  const [filterCount, setFilterCount] = useState(0)
  const [lastChangedFilter, setLastChangedFilter] = useState('')

  const [filters, setFilters] = useState<ArtifactFilters>({})
  const [lastId, setLastId] = useState<string | undefined>(undefined)

  const FILTER_TYPE_MAPPING = {
    'functionIds': 'functions',
    'subtopicIds': 'subtopics',
    'topicIds': 'topics',
    'business-model': 'business-model',
    'company-stage': 'company-stage',
    'company-size': 'company-size',
    'sortBy': 'sortBy'
  }

  const REVERSE_FILTER_TYPE_MAPPING = Object.fromEntries(
    Object.entries(FILTER_TYPE_MAPPING).map(([key, value]) => [value, key])
  )

  function handleFilterSelection(type: string, data: string[] | string = []) {
    const searchParams = new URLSearchParams()

    // *** enable when we are mixing filters **
    const filterTypes = Object.keys(FILTER_TYPE_MAPPING)
      .map((t) => FILTER_TYPE_MAPPING[t as keyof typeof FILTER_TYPE_MAPPING])
      .filter((t) => t !== type)

    setLastChangedFilter(type)

    const isPagination = ['last-id', 'sortBy'].includes(type) && !Array.isArray(data)

    if (type === 'reset') {
      const sortByParam = queryParams.get('sortBy')
      if (sortByParam) searchParams.set('sortBy', sortByParam)
    } else if (isPagination) {
      for (const currentType of filterTypes) {
        const queryParam = !isPagination
          ? queryParams.get(REVERSE_FILTER_TYPE_MAPPING[currentType])
          : queryParams.get(currentType)
        if (queryParam) searchParams.set(currentType, queryParam)
      }
      searchParams.set(type, data)
    } else if (Array.isArray(data)) {
      searchParams.delete('last-id')

      for (const currentType of filterTypes) {
        const queryParam = queryParams.get(currentType)
        if (queryParam) searchParams.set(currentType, queryParam)
      }

      const query = data.join(',')
      if (query.length) searchParams.set(type, query)
    }

    const otherFilterTypes = [
      ...filterTypes.filter((filterType) => filterType !== 'subtopics'),
      type
    ]

    const subtopicParam = searchParams.get('subtopics')

    if (
      subtopicParam &&
      otherFilterTypes.some((filterType) => searchParams.has(filterType))
    ) {
      searchParams.delete('subtopics')
    }

    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString()
    })
  }

  useEffect(() => {
    function getFilterArray(param: string) {
      return queryParams.get(param)?.split(',') ?? []
    }

    let count = 0
    const newFilters: ArtifactFilters = {
      forYou: queryParams.has('for-you'),
      functionIds: getFilterArray('functions'),
      recentlyViewed: queryParams.has('recently-viewed'),
      subtopicIds: getFilterArray('subtopics'),
      topicIds: getFilterArray('topics'),
      companySize: getFilterArray('company-size'),
      companyStage: getFilterArray('company-stage'),
      businessModel: getFilterArray('business-model'),
      sortBy: queryParams.get('sortBy') === 'most-popular' ? 'most-popular' : null
    }

    for (const [key, val] of Object.entries(newFilters)) {
      if (key === 'sortBy') continue
      if (Array.isArray(val)) {
        count += val.length
      } else if (val) {
        count += 1
      }
    }

    setFilterCount(count)
    setLastId(queryParams.get('last-id') || undefined)
    setFilters(newFilters)
  }, [queryParams])

  return { filters, filterCount, lastChangedFilter, lastId, handleFilterSelection }
}
